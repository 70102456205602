import VisibilityIcon from '@mui/icons-material/Visibility';
import { confirmPasswordReset, getAuth, verifyPasswordResetCode } from 'firebase/auth';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ECButton } from '../../../components/ECButton';
import { ECInput } from '../../../components/ECInput';
import { ECTypography } from '../../../components/ECTypography';
import { PasswordRequirements } from '../../../components/PasswordRequirements';

import { Logo } from '../../../components';
import { PASSWORD_PATTERN } from '../../../constants/regex';
import { login } from '../../../store/reducers/authSlice';
import { errorToast } from '../../../utils/errorToast';

export const SetNewPassword = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const oobCode = new URLSearchParams(search).get('oobCode');
  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const onChange = (e) => {
    const { value, name } = e.target;

    setPasswords((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isPasswordMatchReq = (value) => {
    return !value.match(PASSWORD_PATTERN);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    const { newPassword, confirmPassword } = passwords;

    if (newPassword === confirmPassword && !isPasswordMatchReq(newPassword)) {
      setLoading(true);

      verifyPasswordResetCode(auth, oobCode)
        .then((email) => {
          confirmPasswordReset(auth, oobCode, newPassword)
            .then(() => {
              dispatch(
                login({
                  email,
                  password: newPassword,
                })
              );
            })
            .catch((error) => {
              setLoading(false);
              errorToast(`Password reset confirmation failed: ${error.message}`);
            })
            .finally(() => setLoading(false));
        })
        .catch((error) => {
          errorToast(error.message);

          setLoading(false);
        });
    } else {
      setIsError(true);
    }
  };

  const { newPassword, confirmPassword } = passwords;

  return (
    <div className="wrapper">
      <div className="formWrapper">
        <Logo />
        <div>
          <ECTypography variant="headline2" text="Reset Password" />
        </div>
        <form className="signinForm" onSubmit={onSubmit}>
          <ECInput
            onChange={onChange}
            placeholder="New password"
            value={newPassword}
            required
            name="newPassword"
            icon={<VisibilityIcon />}
            iconPosition="end"
            type={showPassword.newPassword ? 'text' : 'password'}
            onIconClick={() => setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword })}
            isError={isError && isPasswordMatchReq(newPassword)}
          />

          <div>
            <ECInput
              onChange={onChange}
              placeholder="Confirm password"
              value={confirmPassword}
              required
              name="confirmPassword"
              icon={<VisibilityIcon />}
              iconPosition="end"
              type={showPassword.confirmPassword ? 'text' : 'password'}
              onIconClick={() =>
                setShowPassword({
                  ...showPassword,
                  confirmPassword: !showPassword.confirmPassword,
                })
              }
              isError={(isError && isPasswordMatchReq(confirmPassword)) || (isError && confirmPassword !== newPassword)}
            />

            <PasswordRequirements isError={isError && confirmPassword !== newPassword} />
          </div>

          <ECButton
            fullWidth
            variant="Primary"
            disabled={!(newPassword && confirmPassword)}
            loading={isLoading}
            type="submit"
          >
            Confirm
          </ECButton>
        </form>
      </div>
    </div>
  );
};
