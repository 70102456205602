import { initializeApp } from 'firebase/app';
import { collection, doc, getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAP4PtOm3S0plrVsEZPrmKh4FxOZ8J7hbU',
  authDomain: 'copilot-for-parents.firebaseapp.com',
  projectId: 'copilot-for-parents',
  storageBucket: 'copilot-for-parents.appspot.com',
  messagingSenderId: '890321609406',
  appId: '1:890321609406:web:abe39955952c1cc94574ef',
};

// Initialize Firebase
initializeApp(firebaseConfig);

const app = initializeApp(firebaseConfig);

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);

export const db = getFirestore();

export const unitsCollection = collection(db, 'units');
export const unitDoc = doc(db, 'units', 'VFrRmfZwzLVh6F91Xln1');
export const functions = getFunctions(app, 'us-central1');
