import VisibilityIcon from '@mui/icons-material/Visibility';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ECButton } from '../../../components/ECButton';
import { ECInput } from '../../../components/ECInput';
import { ECLink } from '../../../components/ECLink';
import { ECTypography } from '../../../components/ECTypography';
import OAuth from '../../../components/OAuth';
import { PasswordRequirements } from '../../../components/PasswordRequirements';

import { Logo } from '../../../components';
import { PASSWORD_PATTERN } from '../../../constants/regex';
import { db } from '../../../firebase.config';
import { errorToast } from '../../../utils/errorToast';
import './SignUp.css';

export const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const { email, password } = formData;
  const navigate = useNavigate();

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === 'password' && isSubmit) {
      setIsError(!value.match(PASSWORD_PATTERN));
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (!(email && password) || !password.match(PASSWORD_PATTERN)) {
      setIsError(true);
    } else {
      setIsLoading(true);

      try {
        const auth = getAuth();

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);

        const user = userCredential.user;

        const formDataCopy = { ...formData };
        delete formDataCopy.password;
        formDataCopy.timestamp = serverTimestamp();

        await setDoc(doc(db, 'users', user.uid), formDataCopy);

        localStorage.setItem('isLoggedIn', 'true');
        navigate('/personal-info');
      } catch (error) {
        setIsLoading(false);

        errorToast('Something went wrong with registration');
      }
    }
  };

  return (
    <div className="wrapper">
      <div className="formWrapper">
        <Logo />
        <div>
          <ECTypography variant="headline2" text="Sign up" />
        </div>
        <form className="signUpForm" onSubmit={onSubmit}>
          <ECInput onChange={onChange} placeholder="Email" value={email} name="email" type="email" />

          <div>
            <ECInput
              onChange={onChange}
              placeholder="Password"
              value={password}
              required
              name="password"
              icon={<VisibilityIcon />}
              iconPosition="end"
              type={showPassword ? 'text' : 'password'}
              onIconClick={() => setShowPassword(!showPassword)}
              isError={isError}
            />

            <PasswordRequirements password={password} isError={isError} />
          </div>

          <ECButton fullWidth variant="Primary" disabled={!(email && password)} loading={isLoading} type="submit">
            Sign up
          </ECButton>
          <div className="seperatorText">
            <span>or</span>
          </div>
          <OAuth />

          <ECTypography variant="bodyM" textPosition="center" className="link-text">
            Already have an account? <ECLink link="/sign-in" text="Sign in here" />
          </ECTypography>
        </form>
      </div>
    </div>
  );
};
