import { Avatar as AvatarMui } from '@mui/material';
import styled from 'styled-components';

export const InputFile = styled.input`
  opacity: 0;
  position: absolute;
  padding: 0;
  width: 100%;
  height: 40px;
`;

export const Avatar = styled(AvatarMui)`
  &.MuiAvatar-root {
    width: 96px;
    height: 96px;

    img {
      width: 100%;
    }
  }

  .MuiSvgIcon-root {
    color: var(--grey-400);
    font-size: 80px;
  }

  background: #fff;
`;
