import 'core-js';
import 'normalize.css';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { AppProvider } from './context/appContext';
import './index.css';
import store from './store/store';

ReactDOM.render(
  <Provider store={store}>
    <AppProvider>
      <App />
    </AppProvider>
  </Provider>,
  document.getElementById('root')
);
