import { getAuth } from '@firebase/auth';
import CloseIcon from '@mui/icons-material/Close';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';

import { ECButton } from '../../../../components/ECButton';
import { ECButtonIcon } from '../../../../components/ECButtonIcon';
import { ECTypography } from '../../../../components/ECTypography';

import { ECSwitcher } from '../ECSwitcher';
import { DownloadPdf } from './DownloadPDF';
import { DownloadWord } from './DownloadWord';
import { HeaderDiv, Wrapper } from './styles';

export const ShareContent = ({ onClick, name, historyLink, inputValues, completion }) => {
  const [isPublic, setIsPublic] = useState(true);

  const handleChangePrivacyLink = () => {
    setIsPublic((prev) => !prev);
  };

  const saveFileToDB = async (textResult, toolName, docType) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const storage = getStorage();
    const userId = user.uid;

    const storageRef = ref(
      storage,
      `users/${userId}/${historyLink}/${inputValues.subject} ${toolName} ${Date.now()}.${docType}`
    );

    await uploadBytes(storageRef, textResult);

    const url = await getDownloadURL(storageRef);
    const name = storageRef.name;

    return { url, name };
  };

  const copyLink = () => {
    const { origin, pathname } = window.location;

    navigator.clipboard.writeText(`${origin}${pathname}?id=${completion.id}&type=${Number(isPublic)}`);
  };

  return (
    <>
      <HeaderDiv>
        <ECTypography variant="headlineL" text="Share this document" />
        <ECButtonIcon icon={<CloseIcon />} variant="NoBorder" onClick={onClick} size={24} />
      </HeaderDiv>

      <Wrapper>
        <ECTypography variant="headlineS" text="Link sharing" />
        <ECButton fullWidth variant="Secondary" className="link-button" onClick={handleChangePrivacyLink}>
          Public link
          <ECSwitcher onChange={handleChangePrivacyLink} checked={isPublic} />
        </ECButton>
        <ECButton variant="Primary" onClick={copyLink} disabled={!completion.id}>
          Copy link
        </ECButton>
      </Wrapper>
      <Wrapper>
        <ECTypography variant="headlineS" text="Export as" />
        <DownloadPdf saveFileToDB={saveFileToDB} name={name} subject={inputValues.subject} />
        <DownloadWord saveFileToDB={saveFileToDB} name={name} subject={inputValues.subject} />
      </Wrapper>
    </>
  );
};
