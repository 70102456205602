import styled from 'styled-components';

export const Wrapper = styled.section`
  .dashboard {
    display: flex;
    justify-content: space-between;
    height: 100vh;
  }

  .dashboard-page {
    width: 100%;
    height: 100%;
    max-width: ${({ $isTool }) => ($isTool ? '100vw' : 'calc(100vw - 240px)')};
  }

  @media (max-width: 749px) {
    .dashboard {
      flex-direction: column;
    }

    .dashboard-page {
      max-width: 100vw;
      margin-top: 68px;

      &:has(.text-editor) {
        overflow: hidden;
      }
    }
  }
`;
