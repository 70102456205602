import { getAuth } from '@firebase/auth';
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import React, { useState } from 'react';
import { IoArrowDown, IoArrowForward, IoTimerOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FormRow from '../../../components/FormRow';

import { db } from '../../../firebase.config';
import '../../dashboard/UnitPlanner/multiform.css';

// Unit Planner Component

const MultiStepForm = () => {
  const [quizName, setQuizName] = useState('');
  const [subject] = useState('');
  const [unitDetails] = useState('');

  // const notify = () =>
  //   toast("🔮 Opening Pandora's Box...", {
  //     position: 'top-right',
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: false,
  //     pauseOnHover: false,
  //     draggable: true,
  //     progress: undefined,
  //     pauseOnFocusLoss: false,
  //     theme: 'light',
  //   });

  // create a handleSubmit function that will submit our form data to firebase quizzes collection and then navigate to the quiz builder page

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;
    const uid = user.uid;
    const quizRef = collection(db, 'quizzes');
    const q = query(quizRef, where('uid', '==', uid));
    const querySnapshot = await getDocs(q);
    const quizNameExists = querySnapshot.docs.find((doc) => doc.data().quizName === quizName);

    if (quizNameExists) {
      toast.error('Quiz name already exists. Please choose another name.');
    } else {
      const quizDoc = await addDoc(quizRef, {
        quizName: quizName,
        userId: uid,
        subject: subject,
        unitDetails: unitDetails,
        questions: [],
        createdAt: new Date(),
      });
      const quizID = quizDoc.id;
      const docRef = doc(db, 'quizzes', quizID);
      updateDoc(docRef, {
        quizID: quizID,
      }).then(() => {
        toast.dismiss();
        navigate(`/tool/custom/quiz-builder/${quizID}`);
      });
    }
  };

  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="headertext">Welcome to Copilot's Quiz Builder ⚡</div>
      <div className="header-subtext">To get started, give your quiz a name.</div>
      <IoArrowDown className="arrow" />
      <div className="form">
        <form onSubmit={handleSubmit}>
          <FormRow
            type="text"
            labelText="Quiz Name:"
            name="quizname"
            value={quizName}
            handleChange={(e) => setQuizName(e.target.value)}
            placeHolder={'Ancient Egypt Unit'}
            style={{ marginBottom: '.33rem' }}
          />
          <button
            className="btn btn-block"
            type="button"
            onClick={handleSubmit}
            style={{
              height: '2.8rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <div>Let's get Started</div>
              <IoArrowForward className="button-icon" />
            </div>
          </button>
        </form>
      </div>
      <div
        className="dividing-line"
        style={{
          width: '70%',
          height: '2px',
          backgroundColor: '#E5E5E5',
          margin: '0 0 2rem 0',
        }}
      ></div>

      <div>
        <button
          className="btn btn-block history-btn"
          type="button"
          onClick={() => navigate('/quiz-history')}
          style={{
            height: '2.5rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: 'none',
            border: '2px solid #E5E5E5',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <IoTimerOutline className="button-icon" />
            <div className="history-text">Quiz History</div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default MultiStepForm;
