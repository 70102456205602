import React from 'react';

import { ECTypography } from '../../../../components/ECTypography';

import { TextDiv } from './styles';

export const ToolDescription = ({ descriptionTitle, description }) => {
  return (
    <TextDiv>
      <ECTypography text={descriptionTitle} variant="bodyS" color="#7D5FF5" />

      {description && (
        <ul>
          {description.map((description, i) => (
            <li key={i}>
              <ECTypography text={description} variant="bodyS" color="#7D5FF5" />
            </li>
          ))}
        </ul>
      )}
    </TextDiv>
  );
};
