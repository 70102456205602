import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { jsPDF } from 'jspdf';
import React, { useState } from 'react';

import { ECButton } from '../../../../../components/ECButton';

export const DownloadPdf = ({ subject, name, saveFileToDB }) => {
  const [loadingPDF, setLoadingPDF] = useState(false);

  const downloadPdf = () => {
    setLoadingPDF(true);
    const target = '.ck-content';

    let content = document.querySelector(target).cloneNode(true);
    content.style = 'width: 580px; font-size: 14px;';

    let pdf = new jsPDF('p', 'pt', 'a4', true);
    const filename = `${subject} ${name}${Date.now()}.pdf`;

    content.querySelectorAll('a').forEach((item) => {
      item.style = 'display: inline-block;';
    });

    pdf.html(content.outerHTML, {
      callback: () => {
        pdf.save(filename);
        setLoadingPDF(false);
      },
      autoPaging: 'text',
      margin: 10,
      filename,
    });

    saveFileToDB(pdf.output('blob'), name, 'pdf');
  };

  return (
    <ECButton fullWidth variant="Secondary" onClick={downloadPdf} loading={loadingPDF}>
      <PictureAsPdfIcon />
      PDF
    </ECButton>
  );
};
