import styled from 'styled-components';

export const Wrapper = styled.section`
  display: grid;
  padding: 36px;
  gap: 24px;
  grid-template-columns: 1fr;

  @media (min-width: 1441px) {
    grid-template-columns: repeat(4, minmax(auto, 375px));
    gap: 36px;
  }

  @media screen and (min-width: 1001px) and (max-width: 1440px) {
    grid-template-columns: repeat(3, minmax(auto, 358px));
  }

  @media screen and (min-width: 769px) and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
