import { Box as BoxMui, Card as CardMui } from '@mui/material';
import styled from 'styled-components';

export const Card = styled(CardMui)`
  &.MuiPaper-root {
    max-width: 408px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 12px;
    border: 1px solid var(--grey-200);
    background: var(--white);
    height: fit-content;
    text-align: start;
    box-shadow: none;

    @media (max-width: 910px) {
      max-width: 100%;
    }

    .MuiCardContent-root {
      padding: 24px;
    }

    @media (max-width: 749px) {
      border: none;
      border-radius: 0;

      .MuiCardContent-root {
        padding: 0;
      }
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  .example-text {
    margin-top: 12px;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  .MuiFormLabel-root {
    width: 100%;
  }

  @media (max-width: 910px) {
    flex-direction: row;
    flex-wrap: wrap;

    .input {
      width: 47%;

      &.multiline {
        width: 100%;
      }
    }
  }

  @media (max-width: 549px) {
    .input {
      width: 100%;
    }
  }
`;

export const Box = styled(BoxMui)`
  display: flex;
  gap: 12px;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 36px;
  padding: 36px;
  overflow: auto;
  height: calc(100% - 86px);

  ul {
    list-style-type: disc;
    padding-left: 18px;
    margin: 0;

    li::marker {
      font-size: 12px;
    }
  }

  @media (max-width: 910px) {
    flex-direction: column;
  }

  @media (max-width: 749px) {
    padding: 24px;
    height: ${({ $isShowGenerateBlock }) => ($isShowGenerateBlock ? '100%' : '')};
  }

  .editor {
    width: 100%;

    @media (min-width: 500px) and (max-width: 909px) {
      height: ${({ $height }) => `calc(100% - ${$height}px - 40px)`};
    }

    @media (max-width: 499px) {
      height: calc(100% - 125px);
    }
  }

  @media (max-width: 1010px) {
    .ck-button_with-text {
      width: 110px;
    }
  }

  @media (min-width: 910px) and (max-width: 940px) {
    .ck-icon {
      font-size: 8px;
    }
  }

  @media (max-width: 499px) {
    header {
      padding: ${({ $isShowGenerateBlock }) => ($isShowGenerateBlock ? '0px' : '')};
    }
  }

  .ck.ck-editor {
    border-radius: 12px;
    background: var(--white);
    display: flex;
    flex-direction: column;
    height: 100%;

    .ck-editor__main {
      display: flex;
      height: inherit;
      max-height: calc(100% - 40px);
    }

    .ck.ck-toolbar {
      border: 1px solid var(--grey-200);
      border-bottom: none;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;

      .ck-button__label {
        font-size: 14px;
        color: #333;
        font-family: DMSansRegular, sans-serif;
        line-height: 1.5;
      }
    }

    .ck-editor__editable {
      width: 100%;
      border: 1px solid var(--grey-200);
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      padding: 24px;
    }
  }
`;
