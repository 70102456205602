import { STypography } from './styles';

const variantMapping = {
  headline2: 'h2',
  headlineL: 'h2',
  headlineS: 'h2',
  subtitleM: 'h2',
  subtitle2: 'h2',
  bodyM: 'p',
  bodyS: 'p',
};

export const ECTypography = ({ variant, text, textPosition = 'start', color, children, className = '' }) => {
  return (
    <STypography
      variant={variant}
      component={variantMapping[variant]}
      className={`${variant} ${className}`}
      $textPosition={textPosition}
      $color={color}
    >
      {text || children}
    </STypography>
  );
};
