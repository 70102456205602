import CheckIcon from '@mui/icons-material/Check';

import { WrapperDiv } from './styles';

export const ECSwitcher = ({ checked, onChange, disabled = false, size = 24, switchWrapperClassName = '' }) => {
  return (
    <WrapperDiv className="thumbWrapper">
      <label
        className={`switchWrapper ${switchWrapperClassName} ${checked ? 'switchChecked' : ''} 
        ${disabled ? 'disabledSwitch' : ''}`}
      >
        <input type="checkbox" onChange={onChange} checked={checked} disabled={disabled} />
        <span
          style={{
            width: `${+size}px`,
            height: `${+size}px`,
            left: checked ? `calc(100% - ${+size + 2}px)` : '6px',
          }}
          className={`switchThumb ${checked ? '' : 'switchThumbUnchecked'}`}
        >
          {checked && <CheckIcon />}
        </span>
      </label>
    </WrapperDiv>
  );
};
