import { pdf } from '@react-pdf/renderer';

import PDF from '../../custom/ContextPDF';

export const contextBuilderValidator = async (textResult, inputValues) => {
  const pdfData = {};
  const regexDefinition1 = /<definition1>(.*?)<definition1>/s;
  const matchFirst = textResult.match(regexDefinition1);
  pdfData.firstDefinition = matchFirst[1];

  // First Example
  const regexExample1 = /<example1>(.*?)<example1>/s;
  const matchFirstExample = textResult.match(regexExample1);
  pdfData.firstExample = matchFirstExample[1];

  // Second definition
  const regexDefinition2 = /<definition2>(.*?)<definition2>/s;
  const matchSecond = textResult.match(regexDefinition2);
  pdfData.secondDefinition = matchSecond[1];

  // Second Example
  const regexExample2 = /<example2>(.*?)<example2>/s;
  const matchSecondExample = textResult.match(regexExample2);
  pdfData.secondExample = matchSecondExample[1];

  // Third Definition
  const regexDefinition3 = /<definition3>(.*?)<definition3>/s;
  const matchThird = textResult.match(regexDefinition3);
  pdfData.thirdDefinition = matchThird[1];

  // Third Example
  const regexExample3 = /<example3>(.*?)<example3>/s;
  const matchThirdExample = textResult.match(regexExample3);
  pdfData.thirdExample = matchThirdExample[1];

  // Fourth Definition
  const regexDefinition4 = /<definition4>(.*?)<definition4>/s;
  const matchFourth = textResult.match(regexDefinition4);
  pdfData.fourthDefinition = matchFourth[1];

  // Fourth Example
  const regexExample4 = /<example4>(.*?)<example4>/s;
  const matchFourthExample = textResult.match(regexExample4);
  pdfData.fourthExample = matchFourthExample[1];

  // Fifth Definition
  const regexDefinition5 = /<definition5>(.*?)<definition5>/s;
  const matchFifth = textResult.match(regexDefinition5);
  pdfData.fifthDefinition = matchFifth[1];

  // Fifth Example
  const regexExample5 = /<example5>(.*?)<example5>/s;
  const matchFifthExample = textResult.match(regexExample5);
  pdfData.fifthExample = matchFifthExample[1];

  // Sixth Definition
  const regexDefinition6 = /<definition6>(.*?)<definition6>/s;
  const matchSixth = textResult.match(regexDefinition6);
  pdfData.sixthDefinition = matchSixth[1];

  // Sixth Example
  const regexExample6 = /<example6>(.*?)<example6>/s;
  const matchSixthExample = textResult.match(regexExample6);
  pdfData.sixthExample = matchSixthExample[1];

  // Seventh Definition
  const regexDefinition7 = /<definition7>(.*?)<definition7>/s;
  const matchSeventh = textResult.match(regexDefinition7);
  pdfData.seventhDefinition = matchSeventh[1];

  // Seventh Example
  const regexExample7 = /<example7>(.*?)<example7>/s;
  const matchSeventhExample = textResult.match(regexExample7);
  pdfData.seventhExample = matchSeventhExample[1];

  // Eighth Definition
  const regexDefinition8 = /<definition8>(.*?)<definition8>/s;
  const matcheighth = textResult.match(regexDefinition8);
  pdfData.eighthDefinition = matcheighth[1];

  // Eighth Example
  const regexExample8 = /<example8>(.*?)<example8>/s;
  const matchEighthExample = textResult.match(regexExample8);
  pdfData.eighthExample = matchEighthExample[1];

  // Ninth Definition
  const regexDefinition9 = /<definition9>(.*?)<definition9>/s;
  const matchNinth = textResult.match(regexDefinition9);
  pdfData.ninthDefinition = matchNinth[1];

  // Ninth Example
  const regexExample9 = /<example9>(.*?)<example9>/s;
  const matchNinthExample = textResult.match(regexExample9);
  pdfData.ninthExample = matchNinthExample[1];

  // Tenth Definition
  const regexDefinition10 = /<definition10>(.*?)<definition10>/s;
  const matchTenth = textResult.match(regexDefinition10);
  pdfData.tenthDefinition = matchTenth[1];

  // Tenth Example
  const regexExample10 = /<example10>(.*?)<example10>/s;
  const matchTenthExample = textResult.match(regexExample10);
  pdfData.tenthExample = matchTenthExample[1];

  // First Word
  const regexWord1 = /<word1>(.*?)<word1>/s;
  const matchWord = textResult.match(regexWord1);
  pdfData.firstWord = matchWord[1];

  // Second Word
  const regexWord2 = /<word2>(.*?)<word2>/s;
  const matchWord2 = textResult.match(regexWord2);
  pdfData.secondWord = matchWord2[1];

  // Third Word
  const regexWord3 = /<word3>(.*?)<word3>/s;
  const matchWord3 = textResult.match(regexWord3);
  pdfData.thirdWord = matchWord3[1];

  // Fourth Word
  const regexWord4 = /<word4>(.*?)<word4>/s;
  const matchWord4 = textResult.match(regexWord4);
  pdfData.fourthWord = matchWord4[1];

  // Fifth Word
  const regexWord5 = /<word5>(.*?)<word5>/s;
  const matchWord5 = textResult.match(regexWord5);
  pdfData.fifthWord = matchWord5[1];

  // Sixth Word
  const regexWord6 = /<word6>(.*?)<word6>/s;
  const matchWord6 = textResult.match(regexWord6);
  pdfData.sixthWord = matchWord6[1];

  // Seventh Word
  const regexWord7 = /<word7>(.*?)<word7>/s;
  const matchWord7 = textResult.match(regexWord7);
  pdfData.seventhWord = matchWord7[1];

  // Eighth Word
  const regexWord8 = /<word8>(.*?)<word8>/s;
  const matchWord8 = textResult.match(regexWord8);
  pdfData.eighthWord = matchWord8[1];

  // Ninth Word
  const regexWord9 = /<word9>(.*?)<word9>/s;
  const matchWord9 = textResult.match(regexWord9);
  pdfData.ninthWord = matchWord9[1];

  // Tenth Word
  const regexWord10 = /<word10>(.*?)<word10>/s;
  const matchWord10 = textResult.match(regexWord10);
  pdfData.tenthWord = matchWord10[1];

  const blob = await pdf(
    <PDF
      subject={inputValues.subject}
      firstWord={pdfData.firstWord}
      secondWord={pdfData.secondWord}
      thirdWord={pdfData.thirdWord}
      fourthWord={pdfData.fourthWord}
      fifthWord={pdfData.fifthWord}
      sixthWord={pdfData.sixthWord}
      seventhWord={pdfData.seventhWord}
      eighthWord={pdfData.eighthWord}
      ninthWord={pdfData.ninthWord}
      tenthWord={pdfData.tenthWord}
      firstDefinition={pdfData.firstDefinition}
      firstExample={pdfData.firstExample}
      secondDefinition={pdfData.secondDefinition}
      secondExample={pdfData.secondExample}
      thirdDefinition={pdfData.thirdDefinition}
      thirdExample={pdfData.thirdExample}
      fourthDefinition={pdfData.fourthDefinition}
      fourthExample={pdfData.fourthExample}
      fifthDefinition={pdfData.fifthDefinition}
      fifthExample={pdfData.fifthExample}
      sixthDefinition={pdfData.sixthDefinition}
      sixthExample={pdfData.sixthExample}
      seventhDefinition={pdfData.seventhDefinition}
      seventhExample={pdfData.seventhExample}
      eighthDefinition={pdfData.eighthDefinition}
      eighthExample={pdfData.eighthExample}
      ninthDefinition={pdfData.ninthDefinition}
      ninthExample={pdfData.ninthExample}
      tenthDefinition={pdfData.tenthDefinition}
      tenthExample={pdfData.tenthExample}
    />
  ).toBlob();

  return blob;
};
