import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SLink = styled(Link)`
  &#link {
    color: var(--primary-violet-500);
    font-size: 14px;
    font-family: DMSansMedium, sans-serif;
    text-align: start;
  }
`;
