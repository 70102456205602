import { createSlice } from '@reduxjs/toolkit';

const navigationSlice = createSlice({
  name: 'userDetailsSlice',
  initialState: {
    isShowSidebar: false,
    status: 'idle',
    error: null,
  },
  reducers: {
    changeShowSidebar: (state, { payload }) => {
      state.isShowSidebar = payload;
    },
  },
  extraReducers: {},
});

export const { changeShowSidebar } = navigationSlice.actions;

export default navigationSlice.reducer;
