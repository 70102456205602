import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import { doc, updateDoc } from 'firebase/firestore';
import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { db } from '../../../../firebase.config';

export const DocumentEditor = ({ completion }) => {
  const [searchParams] = useSearchParams();
  const { userInfo } = useSelector((state) => state.userDetailsSlice);
  const isReadOnly = (searchParams.get('type') && !Number(searchParams.get('type')) && !completion.id) || !userInfo; // Public - 1, private - 0

  const handleEditorTextOnChange = async (event, editor) => {
    if (!completion.id) return console.log('No completion selected');

    const data = editor.getData();

    const docRef = doc(db, 'completions', completion.id);
    await updateDoc(docRef, {
      generatedText: data,
    });
  };

  const debouncedTextChangeHandler = useCallback(debounce(handleEditorTextOnChange, 300), [completion]);

  return (
    <div className="editor">
      <CKEditor
        editor={Editor}
        data={completion.generatedText}
        onChange={debouncedTextChangeHandler}
        disabled={isReadOnly}
        config={{
          isReadOnly,
        }}
      ></CKEditor>
    </div>
  );
};
