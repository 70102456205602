import { Drawer, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

import { changeShowSidebar } from '../../store/reducers/navigationSlice';
import { Navbar } from '../Navbar';
import { Sidebar } from '../Sidebar';
import { Wrapper } from './styles';

const oldTools = {
  powerpoints: true,
  'quiz-builder': true,
  'recipe-builder': true,
};

export const SharedLayout = () => {
  const { toolType, toolId } = useParams();
  const isMobile = useMediaQuery('(max-width: 749px)');
  const dispatch = useDispatch();
  const { isShowSidebar } = useSelector((state) => state.navigationSlice);
  const isDrawer = isMobile || (toolType && !oldTools[toolId]);

  useEffect(() => {
    if (isShowSidebar) {
      dispatch(changeShowSidebar(!toolType));
    }
  }, [toolType]);

  const toggleSidebar = () => {
    dispatch(changeShowSidebar(!isShowSidebar));
  };

  return (
    <Wrapper $isTool={toolType}>
      <main className="dashboard">
        {isDrawer ? (
          <Drawer
            anchor="left"
            open={isShowSidebar}
            onClose={toggleSidebar}
            PaperProps={{ sx: { width: isMobile ? '100%' : 240, backgroundColor: 'rgba(36, 45, 53, 0.5)' } }}
          >
            <Sidebar toggleSidebar={toggleSidebar} />
          </Drawer>
        ) : (
          <Sidebar />
        )}

        <Navbar toggleSidebar={toggleSidebar} />
        <div className="dashboard-page">
          <Outlet />
        </div>
      </main>
    </Wrapper>
  );
};
