import { Document, Font, Page, StyleSheet, Text } from '@react-pdf/renderer';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const PDF = (props) => {
  const { questions, answers, keys, quizName } = props;

  const styles = StyleSheet.create({
    wrapper: {
      // borderColor: '#a665ff',
      // borderWidth: 3,
      padding: 5,
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 20,
    },
    headerFont: {
      fontFamily: 'Oswald',
      fontSize: 15,
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      textAlign: 'left',
    },
    body: {
      paddingTop: 10,
      paddingBottom: 65,
      paddingHorizontal: 35,
      borderColor: '#a665ff',
      borderWidth: 2,
    },
    title: {
      fontSize: 24,
      paddingTop: 15,
      textAlign: 'left',
      fontFamily: 'Oswald',
    },
    aim: {
      paddingTop: 15,
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 12,
      textAlign: 'left',
      marginBottom: 20,
    },
    goal: {
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 12,
      textAlign: 'left',
      marginBottom: 20,
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  });

  function renderQuestionsAnswersKeys() {
    return questions.map((question, index) => {
      return (
        <Text style={styles.wrapper} key={index}>
          <Text style={styles.goal}>
            {'\n'}
            {`${index + 1})`} {question}
            {'\n'}
            {'\n'}
            {answers[index]}
            {'\n'}
            {'\n'}
          </Text>
        </Text>
      );
    });
  }

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.wrapper}>
          <Text style={styles.aim}>
            Name: ________________________
            {'\n'}
            {'\n'}
            Date: ________________________
            {'\n'}
            {'\n'}
            <Text style={styles.headerFont}>{quizName}</Text>
          </Text>
        </Text>

        <Text style={styles.wrapper}>
          <Text style={styles.aim}>{renderQuestionsAnswersKeys()}</Text>
        </Text>

        {/* New page with page break that shows answer keys */}

        <Text style={styles.wrapper} break>
          <Text style={styles.aim}>
            {/* map through the keys and shows them here separated by a line break */}
            {keys.map((key, index) => {
              return (
                <Text key={index}>
                  {`${index + 1})`} {questions[index]}
                  {'\n'}
                  {key}
                  {'\n'}
                  {'\n'}
                </Text>
              );
            })}
          </Text>
        </Text>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};
export default PDF;
