import { ECTypography } from '../../../../components/ECTypography';

export const WritingPromptsText = () => {
  return (
    <div className="example-text">
      <ECTypography text="Examples:" variant="bodyS" />
      <ul>
        <li>
          <ECTypography text="Do you believe zoos should exist?" variant="bodyS" />
        </li>
        <li>
          <ECTypography text="Should schools have a dress code?" variant="bodyS" />
        </li>
        <li>
          <ECTypography text="Are small schools more effective than large schools?" variant="bodyS" />
        </li>
        <li>
          <ECTypography text="Should celebrating international holidays be mandatory?" variant="bodyS" />
        </li>
      </ul>
    </div>
  );
};
