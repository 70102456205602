import React from 'react';
import { PropagateLoader } from 'react-spinners';

export const Loader = ({ loading, size, color = '#7D5FF5' }) => {
  return (
    <div style={{ width: '4vh' }}>
      <PropagateLoader color={color} loading={loading} size={size} />
    </div>
  );
};
