import React from 'react';
import styled from 'styled-components';

import './DashboardHome.css';

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  text-align: center;
  padding-top: 10%;

  @media (max-width: 749px) {
    height: auto;
  }
`;

const DashboardHome = () => {
  return (
    <Header>
      <h1>
        Welcome to Mindful 🧠
        <br />
        <br />
        <div className="cta">
          <h3>
            <a target="_blank" href="https://discord.gg/BnhfHfCc6W" rel="noreferrer">
              👾 Click here to join the Mindful Warriors Discord group!
            </a>
          </h3>
        </div>
      </h1>
    </Header>
  );
};

export default DashboardHome;
