import AddIcon from '@mui/icons-material/Add';
import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ECButton } from '../../components/ECButton';
import { ECInput } from '../../components/ECInput';
import { ECLink } from '../../components/ECLink';
import { ECTypography } from '../../components/ECTypography';

import { Logo } from '../../components';
import { PHONE_PATTERN } from '../../constants/regex';
import { db } from '../../firebase.config';
import { updateUserDetails } from '../../store/reducers/userDetailsSlice';
import { errorToast } from '../../utils/errorToast';
import { successToast } from '../../utils/successToast';
import './personalInfo.css';

export const PersonalInfo = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userDetailsSlice);
  const { userUid } = userInfo || {};
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});

  const checkPhoneNumber = (phoneNumber) => {
    return PHONE_PATTERN.test(`+${phoneNumber}`);
  };

  const onChange = (e) => {
    let { name, value } = e.target;

    if (name === 'phoneNumber') {
      if (isSubmit) {
        setIsError(!checkPhoneNumber(value));
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { phoneNumber } = formData;

    if ((phoneNumber && checkPhoneNumber(phoneNumber)) || !phoneNumber) {
      setIsError(false);
      setIsLoading(true);

      try {
        const userRef = doc(db, 'users', userUid);

        if (phoneNumber) {
          formData.phoneNumber = `+${phoneNumber}`;
        }

        await updateDoc(userRef, {
          ...formData,
        });

        dispatch(updateUserDetails(formData));

        successToast('Personal info successfully saved!');

        setIsSubmit(false);
        localStorage.setItem('isLoggedIn', 'true');
        navigate('/');
      } catch (error) {
        errorToast('Could not save profile details');
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsError(true);
    }
  };

  const { displayName, occupation, phoneNumber } = formData;

  return (
    <div className="wrapper">
      <div className="formWrapper">
        <Logo />
        <div>
          <ECTypography variant="headline2" text="Personal info" />
        </div>
        <form className="personalInfoForm" onSubmit={onSubmit}>
          <ECInput onChange={onChange} placeholder="Full name" value={displayName || ''} name="displayName" />

          <ECInput
            onChange={onChange}
            placeholder="Mobile phone"
            value={phoneNumber || ''}
            name="phoneNumber"
            icon={<AddIcon />}
            type="tel"
            isError={isError}
            errorText="Invalid phone number"
          />

          <ECInput onChange={onChange} placeholder="Occupation" value={occupation || ''} name="occupation" />

          <ECButton
            fullWidth
            variant="Primary"
            disabled={!(displayName || occupation || phoneNumber)}
            loading={isLoading}
            type="submit"
          >
            Go to the app
          </ECButton>

          <ECTypography variant="bodyM" textPosition="center">
            <ECLink link="/" text="Skip this step" /> and fill later in the Profile
          </ECTypography>
        </form>
      </div>
    </div>
  );
};
