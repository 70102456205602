import styled from 'styled-components';

import { TabPanel as TabCom } from '../../../components/TabPanel';

export const TabPanel = styled(TabCom)`
  border-radius: 12px;
  border: 1px solid var(--grey-200);
  padding: 24px;
  margin-top: 24px;
  width: ${({ $maxWidth }) => $maxWidth || 'fit-content'};
  max-width: 792px;
  background: var(--white);

  @media screen and (max-width: 825px) {
    max-width: min-content;
  }

  @media screen and (max-width: 750px) {
    border: none;
    padding: 0;
  }
`;
