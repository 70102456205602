import { Box as BoxMui } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--gray-50);
  height: 100vh;
  position: relative;
  width: 100%;

  .main-container,
  footer {
    padding: 36px 36px 28px;
  }

  @media (max-width: 750px) {
    background: var(--white);
    height: 100%;

    .main-container,
    footer {
      padding: 24px;
    }
  }

  .MuiTabs-root {
    min-height: 0;
    min-width: 0;
  }

  .MuiTabs-flexContainer {
    gap: 36px;
  }

  .profile-tab {
    font-family: 'DMSansBold', sans-serif;
    font-size: 18px;
    color: var(--grey-400);
    text-transform: none;
    padding: 5px 0;
    display: flex;
    justify-content: flex-end;

    &.MuiTab-root {
      min-height: 0;
      min-width: 0;
    }

    &.Mui-selected {
      color: var(--grey-700);
      border-bottom-color: var(--grey-700);
    }
  }
`;

export const Box = styled(BoxMui)`
  display: flex;
  gap: ${({ $gapSize }) => $gapSize || 24}px;

  @media screen and (max-width: 825px) {
    flex-wrap: wrap;
  }
`;

export const Form = styled.form`
  gap: 24px;
  display: flex;
  flex-direction: column;

  &.form-wrapper {
    .MuiFormLabel-root,
    .MuiFormGroup-root {
      @media (max-width: 389px) {
        width: calc(100vw - 48px);
      }
    }
`;
