import { Box } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { DOCUMENT_LIMIT } from '../../constants/constants';
import { ECTypography } from '../ECTypography';
import { Card, IconDiv, Wrapper } from './styles';

export const Tool = ({ title, link, description, icon }) => {
  const { userInfo } = useSelector((state) => state.userDetailsSlice);
  const { documentsCount } = useSelector((state) => state.userDocumentsCountSlice);
  const isDisabled = documentsCount >= DOCUMENT_LIMIT && !userInfo.isHaveSub;

  return (
    <Wrapper>
      <Link to={`/${isDisabled ? 'pricing' : link}`}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
              <IconDiv $isDisabled={isDisabled}>{icon}</IconDiv>
              <ECTypography variant="headlineM">{title}</ECTypography>
            </Box>
            <ECTypography variant="bodyS">{description}</ECTypography>
          </CardContent>
        </Card>
      </Link>
    </Wrapper>
  );
};
