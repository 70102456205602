import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ECButton } from '../../../../components/ECButton';
import { ECButtonIcon } from '../../../../components/ECButtonIcon';
import { ECTypography } from '../../../../components/ECTypography';

import { Logo } from '../../../../components';
import { logout } from '../../../../store/reducers/authSlice';
import { changeShowSidebar } from '../../../../store/reducers/navigationSlice';
import { ShareButton } from '../../ShareButton';
import { ButtonsWrapper, SHeader } from './styles';

export const Header = ({ text, handleBack, isShowButton, inputValues, tool, completion }) => {
  const isMobile = useMediaQuery('(max-width: 749px)');
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userDetailsSlice);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    dispatch(changeShowSidebar(true));
  };

  const handleNavigate = () => {
    dispatch(logout());
    navigate('/sign-in');
  };

  return (
    <SHeader>
      <ButtonsWrapper>
        {userInfo && (
          <>
            {!isMobile && (
              <button type="button" className="toggle-btn" onClick={toggleSidebar}>
                <MenuIcon />
              </button>
            )}
            <ECButtonIcon icon={<ArrowBackIcon />} onClick={handleBack} variant="Outline" />
          </>
        )}
        <ECTypography variant="headlineL" text={text} />
      </ButtonsWrapper>
      {isShowButton && userInfo && <ShareButton inputValues={inputValues} tool={tool} completion={completion} />}
      {!isMobile && !userInfo && <Logo />}
      {!userInfo && (
        <ECButton variant="Primary" onClick={handleNavigate}>
          Sign in
        </ECButton>
      )}
    </SHeader>
  );
};
