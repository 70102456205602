import { getAuth } from '@firebase/auth';
import { pdf } from '@react-pdf/renderer';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { decode } from 'html-entities';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { db } from '../../../../firebase.config';
import PDF from '../../../ai-tools/custom/LessonPDF';
import './UnitDay.css';

const LessonPlanButton = ({ overview, dayNumber }) => {
  // PDF File States
  const [fileUrl, setFileUrl] = useState(null);

  // Lesson Plan Section States
  const [aimSection, setAimSection] = useState('');
  const [objectivesSection, setObjectivesSection] = useState('');
  const [materialsSection, setMaterialsSection] = useState('');
  const [anticipatorySection, setAnticipatorySection] = useState('');
  const [modeledSection, setModeledSection] = useState('');
  const [guidedSection, setGuidedSection] = useState('');
  const [independentPractice, setIndependentPractice] = useState('');
  const [struggleSection, setStruggleSection] = useState('');
  const [closureSection, setClosureSection] = useState('');

  // API Request & Response States
  const [subject, setSubject] = useState('');
  const [gradeLevel, setGradeLevel] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [documentHasChanged, setDocumentHasChanged] = useState(false);
  const [fileIsReady, setFileIsReady] = useState(false);

  const { unitID } = useParams();
  let overviewText = overview;

  const notify = () =>
    toast('📝 Lesson Plan coming right up!', {
      position: 'top-left',
      autoClose: 70000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      pauseOnFocusLoss: false,
      theme: 'light',
      toastId: 'lessonplanunit',
    });

  // Async function to save the document to the database

  async function saveCompletionToDB(collectionName, data) {
    const auth = getAuth();
    const user = auth.currentUser;
    data = {
      ...data,
      userId: user.uid,
      timestamp: Date.now(),
    };
    return await addDoc(collection(db, collectionName), data);
  }

  async function checkIfLessonPlan() {
    const docRef = doc(db, 'units', unitID);
    await getDoc(docRef);
  }

  useEffect(() => {
    checkIfLessonPlan(dayNumber);
  }, []);

  async function fetchApi(subject, gradeLevel, overviewText) {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      subject,
      gradeLevel,
      overviewText,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${window.location.origin}/api/v1/completions/lessonPlanCompletionUnit`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let textResult = decode(result.choices[0].text);

        // Aim
        const regexAim = /<aim>(.*?)<\/aim>/s;
        const matchAim = textResult.match(regexAim);
        setAimSection(matchAim[1]);

        // Objectives
        const regexObjectives = /<objectives>(.*?)<\/objectives>/s;
        const matchObjectives = textResult.match(regexObjectives);
        setObjectivesSection(matchObjectives[1]);

        // Materials Needed
        const regexMaterials = /<materials>(.*?)<\/materials>/s;
        const matchMaterials = textResult.match(regexMaterials);
        setMaterialsSection(matchMaterials[1]);

        // Anticipatory Set
        const regexAnticipatory = /<anticipatory>(.*?)<\/anticipatory>/s;
        const matchAnticipatory = textResult.match(regexAnticipatory);
        setAnticipatorySection(matchAnticipatory[1]);

        // Modeled Practice
        const regexModeled = /<modeled>(.*?)<\/modeled>/s;
        const matchModeled = textResult.match(regexModeled);
        setModeledSection(matchModeled[1]);

        // Guided Practice
        const regexGuided = /<guided>(.*?)<\/guided>/s;
        const matchGuided = textResult.match(regexGuided);
        setGuidedSection(matchGuided[1]);

        // Independent Practice
        const regexIndependent = /<independent>(.*?)<\/independent>/s;
        const matchIndependent = textResult.match(regexIndependent);
        setIndependentPractice(matchIndependent[1]);

        // Struggle
        const regexStruggle = /<struggles>(.*?)<\/struggles>/s;
        const matchStruggle = textResult.match(regexStruggle);
        setStruggleSection(matchStruggle[1]);

        // Closure
        const regexClosure = /<closure>(.*?)<\/closure>/s;
        const matchClosure = textResult.match(regexClosure);
        setClosureSection(matchClosure[1]);

        setDocumentHasChanged(true);

        // wait 100 ms before setting the documentHasChanged state to false
        setTimeout(() => {
          setDocumentHasChanged(false);
        }, 100);

        const dataToSave = {
          subject,
          gradeLevel,
          overviewText,
          application: 'Lesson Planner V2',
          generatedText: result.choices[0].text,
        };

        // Save the completion to the database, then set the completion state, then log the ref, then catch any errors
        saveCompletionToDB('completions', dataToSave)
          .then(() => {})
          .catch((err) => console.log('error', err));
      })
      .catch((error) => console.log('error', error));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!overviewText) {
      console.log('Please enter a subject');
      return;
    }

    setAimSection('');
    setObjectivesSection('');
    setMaterialsSection('');
    setAnticipatorySection('');
    setModeledSection('');
    setGuidedSection('');
    setIndependentPractice('');
    setStruggleSection('');
    setClosureSection('');

    notify();
    fetchApi(subject, gradeLevel, overviewText);
  };

  // Download PDF Functions

  const createPDF = async (dayNumber) => {
    // Create a blob from the PDF component
    const blob = await pdf(
      <PDF
        subject={subject}
        gradeLevel={gradeLevel}
        aimSection={aimSection}
        objectivesSection={objectivesSection}
        materialsSection={materialsSection}
        anticipatorySection={anticipatorySection}
        modeledSection={modeledSection}
        guidedSection={guidedSection}
        independentPractice={independentPractice}
        struggleSection={struggleSection}
        closureSection={closureSection}
      />
    ).toBlob();

    toast.dismiss('lessonplanunit');

    // Get the current user and the storage service and the user id
    const auth = getAuth();
    const user = auth.currentUser;
    const storage = getStorage();
    const userId = user.uid;

    // Creates a reference to the file we want to upload and where we want to upload it
    const storageRef = ref(storage, `users/${userId}/lesson-plans/${subject} Lesson Plan${Date.now()}.pdf`);

    // Uploads the file to the storage reference
    await uploadBytes(storageRef, blob).then(() => {
      console.log('PDF saved to Firebase');
    });

    // Gets the download URL for the file
    const fileUrl = await getDownloadURL(storageRef);
    setFileUrl(fileUrl);

    async function updateDay(dayNumber) {
      const docRef = doc(db, 'units', unitID);
      const docSnap = await getDoc(docRef);
      const day = docSnap.get(dayNumber);
      const updatedDay = { ...day, lessonplan: `${fileUrl}` };
      await updateDoc(docRef, { [dayNumber]: updatedDay });
    }

    await updateDay(dayNumber);
    setFileIsReady(true);
    setIsLoading(false);
    setSubject('');
    setGradeLevel('');
  };

  const handleDownload = async () => {
    const unitRef = doc(db, 'units', unitID);
    const docSnap = await getDoc(unitRef);
    const url = docSnap.data()[dayNumber].lessonplan;

    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${dayNumber} Lesson Plan.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  // Anytime documentHasChanged changes, it will update the state

  useEffect(() => {
    if (documentHasChanged) {
      createPDF(dayNumber);
    }
  }, [documentHasChanged]);

  // GET UNIT DETAILS & RENDER BUTTONS
  async function getUnitDetails() {
    const unitRef = doc(db, 'units', unitID);
    const docSnap = await getDoc(unitRef);

    if (await docSnap.data()[dayNumber].lessonplan) {
      setButtonJSX(
        <button
          className="btn-primary"
          type="submit"
          onClick={handleDownload}
          style={{
            border: '1px dashed #1e90ff',
            marginBottom: '1.5rem',
            backgroundColor: '#1e90ff',
            color: 'white',
          }}
        >
          Lesson Plan
        </button>
      );
    } else {
      setButtonJSX(
        <button
          className="btn-primary"
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          {isLoading ? (
            <div className="buttonelements">
              <div className="buttonicon"></div>
              <div className="buttontext">Loading...</div>
            </div>
          ) : (
            <div className="buttonelements">
              <div className="buttonicon">+</div>
              <div className="buttontext">Lesson Plan</div>
            </div>
          )}
        </button>
      );
    }
  }

  useEffect(() => {
    getUnitDetails();
  }, [isLoading]);

  const [buttonJSX, setButtonJSX] = useState(null);

  useEffect(() => {
    if (fileUrl) {
      setButtonJSX(
        <button
          className="btn-primary"
          type="submit"
          onClick={handleDownload}
          style={{
            border: '1px dashed #1e90ff',
            marginBottom: '1.5rem',
            backgroundColor: '#1e90ff',
            color: 'white',
          }}
        >
          Lesson Plan
        </button>
      );
    }
  }, [fileIsReady]);

  return <div>{buttonJSX}</div>;
};

export default LessonPlanButton;
