import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

import { db } from '../../firebase.config';

export const getDocumentsCount = createAsyncThunk('userDocumentsCountSlice/getDocumentsCount', async (userUid) => {
  const docRef = doc(db, 'users', userUid);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  return data?.documentsCreated || 0;
});

export const updateDocumentsCount = createAsyncThunk(
  'userDocumentsCountSlice/updateDocumentsCount',
  async (userUid) => {
    const docRef = doc(db, 'users', userUid);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const newDocumentsCount = data?.documentsCreated + 1 || 1;
    await updateDoc(docRef, {
      documentsCreated: newDocumentsCount,
    });
    return newDocumentsCount;
  }
);

const userDocumentsCountSlice = createSlice({
  name: 'userDocumentsCountSlice',
  initialState: {
    documentsCount: 0,
    update: {
      status: 'idle',
      error: null,
    },
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getDocumentsCount.pending]: (state) => {
      state.status = 'loading';
    },
    [getDocumentsCount.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.documentsCount = action.payload;
    },
    [getDocumentsCount.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [updateDocumentsCount.pending]: (state) => {
      state.update.status = 'updating';
    },
    [updateDocumentsCount.fulfilled]: (state, action) => {
      state.update.status = 'succeeded';
      state.documentsCount = action.payload;
    },
    [updateDocumentsCount.rejected]: (state, action) => {
      state.update.status = 'failed';
      state.update.error = action.error.message;
    },
  },
});

export default userDocumentsCountSlice.reducer;
