import { Card as CardMui } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Card = styled(CardMui)`
  &.MuiPaper-root {
    height: 100%;
    width: 100%;
    border: 1px solid var(--grey-300);
    border-radius: 8px;
    box-shadow: none;

    &:hover {
      border: 1px solid var(--primary-violet-500);
    }

    .MuiCardContent-root {
      padding: 24px;
    }
  }
`;

export const IconDiv = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  background: ${({ $isDisabled }) => ($isDisabled ? '#EAECEE' : '#E0D9FD')};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  padding: 8px;

  svg {
    color: ${({ $isDisabled }) => ($isDisabled ? '#717D8A' : '#7D5FF5')};
  }
`;
