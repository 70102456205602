import React from 'react';

import { ECTypography } from '../../components/ECTypography';

import successfulPayment from '../../assets/svg/successful-payment.svg';
import { Link, Wrapper } from './styles';

export const SuccessfulPayment = () => {
  return (
    <Wrapper>
      <img src={successfulPayment} alt="Successful Payment" />
      <ECTypography variant="headline2" text={'Payment successful!\nThanks for being with us.'} textPosition="center" />
      <ECTypography
        variant="subtitleM"
        text="You can unsubscribe at any time on your Profile page under the Plans tab"
        textPosition="center"
      />
      <Link to="/" className="link">
        Back home
      </Link>
    </Wrapper>
  );
};
