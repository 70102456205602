import { pdf } from '@react-pdf/renderer';

import PDF from '../../custom/LessonPDF';

export const lessonPlanValidator = async (textResult, inputValues) => {
  const pdfData = {};
  // Aim
  const regexAim = /<aim>(.*?)<aim>/s;
  const matchAim = textResult.match(regexAim);
  pdfData.aim = matchAim[1];

  // Objectives
  const regexObjectives = /<objectives>(.*?)<objectives>/s;
  const matchObjectives = textResult.match(regexObjectives);
  pdfData.objectives = matchObjectives[1];

  // Materials Needed
  const regexMaterials = /<materials>(.*?)<materials>/s;
  const matchMaterials = textResult.match(regexMaterials);
  pdfData.materials = matchMaterials[1];

  // Anticipatory Set
  const regexAnticipatory = /<anticipatory>(.*?)<anticipatory>/s;
  const matchAnticipatory = textResult.match(regexAnticipatory);
  pdfData.anticipatory = matchAnticipatory[1];

  // Modeled Practice
  const regexModeled = /<modeled>(.*?)<modeled>/s;
  const matchModeled = textResult.match(regexModeled);
  pdfData.modeled = matchModeled[1];

  // Guided Practice
  const regexGuided = /<guided>(.*?)<guided>/s;
  const matchGuided = textResult.match(regexGuided);
  pdfData.guided = matchGuided[1];

  // Independent Practice
  const regexIndependent = /<independent>(.*?)<independent>/s;
  const matchIndependent = textResult.match(regexIndependent);
  pdfData.independent = matchIndependent[1];

  // Struggle
  const regexStruggle = /<struggles>(.*?)<struggles>/s;
  const matchStruggle = textResult.match(regexStruggle);
  pdfData.struggle = matchStruggle[1];

  // Closure
  const regexClosure = /<closure>(.*?)<closure>/s;
  const matchClosure = textResult.match(regexClosure);
  pdfData.closure = matchClosure[1];

  const blob = await pdf(
    <PDF
      subject={inputValues.subject}
      gradeLevel={inputValues.gradeLevel}
      aimSection={pdfData.aim}
      objectivesSection={pdfData.objectives}
      materialsSection={pdfData.materials}
      anticipatorySection={pdfData.anticipatory}
      modeledSection={pdfData.modeled}
      guidedSection={pdfData.guided}
      independentPractice={pdfData.independent}
      struggleSection={pdfData.struggle}
      closureSection={pdfData.closure}
    />
  ).toBlob();

  return blob;
};
