import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Wrapper from '../../assets/wrappers/Navbar';
import { Logo } from '../Logo';

export const Navbar = ({ toggleSidebar }) => {
  const ref = useRef(null);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [top, setTop] = useState(0);
  const { userInfo } = useSelector((state) => state.userDetailsSlice);

  const handleScroll = () => {
    const navHeight = ref.current.offsetHeight;
    const currentScrollPos = window.pageYOffset;

    setTop(prevScrollPos > currentScrollPos || currentScrollPos <= navHeight ? 0 : `-${navHeight}`);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <Wrapper $top={top} ref={ref}>
      <div className="nav-center">
        <NavLink to="/">
          <Logo />
        </NavLink>
        {userInfo && (
          <button type="button" className="toggle-btn" onClick={toggleSidebar}>
            <MenuIcon />
          </button>
        )}
      </div>
    </Wrapper>
  );
};
