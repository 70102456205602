import { getAuth } from 'firebase/auth';
import { collection, getDocs, query } from 'firebase/firestore';

import { db } from '../firebase.config';

class AuthService {
  static async doesUserHaveActiveSubscription() {
    let isSubscribed = false;
    const auth = getAuth();
    const dbQuery = query(collection(db, 'users', auth?.currentUser.uid, 'subscriptions'));
    const subscriptionsSnapshot = await getDocs(dbQuery);
    subscriptionsSnapshot.forEach((snapshot) => {
      const subscription = snapshot.data();

      isSubscribed = subscription.status === 'active' || subscription.status === 'trialing';
    });
    return isSubscribed;
  }

  static async isUserSubscribedToMainProduct() {
    await getAuth().currentUser.getIdToken(true);
    const decodedToken = await getAuth().currentUser.getIdTokenResult();

    return decodedToken.claims.stripeRole === 'premium_1';
  }

  static async getUserSubscriptionDetails() {
    const userId = await getAuth().currentUser.uid;
    const subscriptionsRef = collection(db, `users/${userId}/subscriptions`);

    const querySnapshot = await getDocs(subscriptionsRef);
    let finalSubscriptionData = undefined;
    querySnapshot.forEach((doc) => {
      const subscriptionData = doc.data();

      if (subscriptionData.status === 'active' || subscriptionData.status === 'trialing') {
        finalSubscriptionData = subscriptionData;
      }
    });

    return finalSubscriptionData;
  }

  static async doesUserHaveAnnualSubscription() {
    await getAuth().currentUser.getIdToken(true);
    const decodedToken = await getAuth().currentUser.getIdTokenResult();

    return decodedToken.claims.stripeRole === 'premium_1';
  }
}

export default AuthService;
