import React from 'react';

import { SButton } from './styles';

const BtnType = {
  Primary: 'primary',
  PrimaryOutline: 'primary-outline',
  Outline: 'outline',
  SecondaryOutline: 'secondary-outline',
  NoBorder: 'no-border',
};

export const ECButtonIcon = ({ variant, loading, disabled, icon, onClick, size }) => {
  const classNameBtnType = BtnType[variant];

  return (
    <SButton
      variant={variant}
      disabled={loading || disabled}
      className={classNameBtnType}
      onClick={onClick}
      $size={size}
    >
      {icon}
    </SButton>
  );
};
