import IosShareIcon from '@mui/icons-material/IosShare';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';

import { ECButton } from '../../../components/ECButton';
import { ECButtonIcon } from '../../../components/ECButtonIcon';

import { ShareContent } from './ShareContent';
import { Drawer, Popover } from './styles';

export const ShareButton = ({ inputValues, tool, completion }) => {
  const isMobile = useMediaQuery('(max-width: 500px)');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {isMobile ? (
        <>
          <ECButtonIcon variant="Primary" icon={<IosShareIcon />} onClick={handleClick} />
          <Drawer anchor="bottom" open={open} onClose={handleClose} PaperProps={{ sx: { width: '100%' } }}>
            <ShareContent onClick={handleClose} inputValues={inputValues} {...tool} completion={completion} />
          </Drawer>
        </>
      ) : (
        <>
          <ECButton variant="Primary" isIcon onClick={handleClick}>
            Share <IosShareIcon />
          </ECButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ShareContent onClick={handleClose} inputValues={inputValues} {...tool} completion={completion} />
          </Popover>
        </>
      )}
    </>
  );
};
