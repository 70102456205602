import { getAuth } from '@firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { ImHistory } from 'react-icons/im';
import { IoCalendarSharp, IoEyeSharp, IoTimerOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import Wrapper from '../../../assets/wrappers/WorkshopTools';
import { db } from '../../../firebase.config';
import '../../dashboard/UnitPlanner/UnitHistory.css';
import '../../dashboard/dashboard-pages/creationPages/Creations.css';

const QuizHistory = () => {
  // States
  const [completionsForUser, setCompletionsForUser] = useState([]);
  const [completion] = useState({
    quizName: '',
    id: '',
  });

  const navigate = useNavigate();

  const completionRef = useRef(null);
  completionRef.current = completion;

  async function getFilteredDataFromDBForUser(collectionName, filter) {
    const auth = getAuth();
    const user = auth.currentUser;
    const docsToAdd = [];
    let dbQuery;
    if (filter === 'All Tools') {
      dbQuery = query(collection(db, collectionName), where('userId', '==', user.uid));
    } else if (Array.isArray(filter)) {
      dbQuery = query(
        collection(db, collectionName),
        where('userId', '==', user.uid),
        where('application', 'in', filter)
      );
    }

    const querySnapshot = await getDocs(dbQuery);
    querySnapshot.forEach((doc) => {
      docsToAdd.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    docsToAdd.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    setCompletionsForUser(docsToAdd);
  }

  useEffect(() => getFilteredDataFromDBForUser('quizzes', 'All Tools'), []);

  // eslint-disable
  return (
    <div className="creations">
      <div className="pageheader">
        <div>{<ImHistory className="historyicon" />} Quiz History</div>
        <div>
          <button
            className="btn btn-block newunit-btn"
            type="button"
            onClick={() => navigate('/tool/quiz-builder')}
            style={{
              height: '2.5rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 'none',
              border: '1px solid #E5E5E5',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <IoCalendarSharp className="button-icon" />
              <div className="history-text">New Quiz</div>
            </div>
          </button>
        </div>
      </div>
      <Wrapper>
        <div className="unithistory">
          <div className="unithistory-cards">
            {completionsForUser.map((doc, index) => (
              <p
                className="unithistory-card"
                key={index}
                onClick={() => navigate(`/tool/quiz-builder/${doc.id}`)}
                style={{ cursor: 'pointer' }}
              >
                <div className="unithistory-length">
                  <IoTimerOutline className="unithistory-icon" />
                  Quiz
                </div>
                <div className="unithistory-name">{doc.quizName}</div>
                <button
                  className="btn btn-block newunit-btn"
                  type="button"
                  onClick={() => navigate('/unit-planner')}
                  style={{
                    height: '2rem',
                    width: '150px',
                    display: 'flex',
                    marginTop: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'none',
                    border: '1px solid #E5E5E5',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '1rem',
                    }}
                  >
                    <IoEyeSharp className="button-icon" />
                    <div className="history-text">View</div>
                  </div>
                </button>
              </p>
            ))}
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default QuizHistory;
