import { getAuth } from '@firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Loader } from './components/Loader';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { SharedLayout } from './components/SharedLayout';

import { ProtectedRoute } from './pages';
import AssessmentBuilder from './pages/ai-tools/custom/AssessmentBuilder';
import AssessmentHistory from './pages/ai-tools/custom/AssessmentHistory';
import QuizPreview from './pages/ai-tools/custom/AssessmentPreview';
import { ToolLayout } from './pages/ai-tools/tool-layout';
import { ForgotPassword } from './pages/auth/forgotPassword';
import { SetNewPassword } from './pages/auth/setNewPassword';
import { SignIn } from './pages/auth/signIn';
import { SignUp } from './pages/auth/signUp';
import { DashboardHome, Workshop } from './pages/dashboard';
import UnitHistory from './pages/dashboard/UnitPlanner/UnitHistory.js';
import UnitPlanner1 from './pages/dashboard/UnitPlanner/UnitPlanner1.js';
import UnitPreview from './pages/dashboard/UnitPlanner/UnitPreview.js';
import ContextBuilderHistory from './pages/dashboard/dashboard-pages/creationPages/ContextBuilderHistory';
import Creations from './pages/dashboard/dashboard-pages/creationPages/Creations';
import EducationalHandoutHistory from './pages/dashboard/dashboard-pages/creationPages/EducationalHandoutHistory.js';
import LessonPlanHistory from './pages/dashboard/dashboard-pages/creationPages/LessonPlanHistory';
import PowerpointHistory from './pages/dashboard/dashboard-pages/creationPages/PowerpointHistory';
import WritingPromptHistory from './pages/dashboard/dashboard-pages/creationPages/writingPromptHistory.js';
import { NotFound } from './pages/notFound';
import { PersonalInfo } from './pages/personalInfo';
import Pricing from './pages/pricing/PricingPage.js';
import { Profile } from './pages/profile';
import { SuccessfulPayment } from './pages/successfulPayment';
import { getUserInfo } from './store/reducers/userDetailsSlice';

function App() {
  const dispatch = useDispatch();
  const auth = getAuth();
  const { status } = useSelector((state) => state.userDetailsSlice);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        dispatch(getUserInfo(user));
      } else {
        dispatch(getUserInfo(null));
      }
    });
  }, [auth, dispatch]);

  if (status === 'loading' || status === 'idle')
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader loading={true} size={40} />
      </div>
    );

  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <SharedLayout />
              </ProtectedRoute>
            }
          >
            <Route path="pricing" element={<Pricing />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="unit-planner/:unitID" element={<UnitPreview />} />
              <Route path="quiz-history" element={<AssessmentHistory />} />
              <Route path="/tool/custom/quiz-builder/:quizID" element={<AssessmentBuilder />} />
              <Route path="/profile" element={<Profile />} />
              <Route index element={<DashboardHome />} />
              <Route path="workshop" element={<Workshop />} />
              <Route path="creations" element={<Creations />} />
              <Route path="unit-history" element={<UnitHistory />} />
              <Route path="unit-planner" element={<UnitPlanner1 />} />
              <Route path="creations/educational-handouts" element={<EducationalHandoutHistory />} />
              <Route path="creations/lesson-plans" element={<LessonPlanHistory />} />
              <Route path="creations/writing-prompts" element={<WritingPromptHistory />} />
              <Route path="creations/powerpoints" element={<PowerpointHistory />} />
              <Route path="creations/context-builders" element={<ContextBuilderHistory />} />
              <Route path="quiz-preview/:quizID" element={<QuizPreview />} />
            </Route>

            <Route path="tool/:toolType/:toolId" element={<ToolLayout />} />
          </Route>

          <Route path="/" element={<PrivateRoute />}>
            <Route path="personal-info" element={<PersonalInfo />} />
          </Route>

          <Route path="/" element={<PublicRoute />}>
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="set-new-password" element={<SetNewPassword />} />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-up" element={<SignUp />} />
          </Route>
          <Route path="/successful-payment" element={<SuccessfulPayment />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
