import React from 'react';

import { SLink } from './styles';

export const ECLink = ({ link, text }) => {
  return (
    <SLink to={link} id="link">
      {text}
    </SLink>
  );
};
