import styled from 'styled-components';

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  position: fixed;
  top: ${({ $top }) => $top}px;
  left: 0;
  right: 0;
  z-index: 10;

  .toggle-btn {
    border: none;
    font-size: 24px;
    color: var(--grey-800);
    padding: 0;
    display: flex;
  }

  .nav-center {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    box-shadow: 0px -1px 0px 0px #f1f1f1 inset;

    a {
      display: flex;
    }
  }

  @media (min-width: 749px) {
    display: none !important;
  }
`;
export default Wrapper;
