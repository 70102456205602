import styled from 'styled-components';

import { SHeader as Header } from '../../../../components/Header/styles';

export const SHeader = styled(Header)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  .toggle-btn {
    border: none;
    font-size: 24px;
    color: var(--grey-800);
    padding: 0;
    display: flex;
  }

  .MuiButtonBase-root {
    margin: 0 16px 0 24px;

    @media (max-width: 749px) {
      margin-left: 0;
    }
  }
`;
