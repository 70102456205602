import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import google from '../assets/svg/google.svg';
import { db } from '../firebase.config';
import { getUserInfo } from '../store/reducers/userDetailsSlice';
import { ECButton } from './ECButton';
import './OAuth.css';

function OAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const onGoogleClick = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check for user
      const docRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(docRef);

      // If user, doesn't exist, create user
      if (!docSnap.exists()) {
        await setDoc(doc(db, 'users', user.uid), {
          name: user.displayName,
          email: user.email,
          timestamp: serverTimestamp(),
        });
      }
      localStorage.setItem('isLoggedIn', 'true');
      dispatch(getUserInfo(user));

      navigate('/');
    } catch (error) {
      console.log('Could not authorize with Google');
    }
  };

  return (
    <div className="socialLogin">
      <ECButton fullWidth onClick={onGoogleClick} variant="Outline">
        Sign {location.pathname === '/sign-up' ? 'up' : 'in'} with Google{' '}
        <img className="google-icon" src={google} alt="Google icon" />
      </ECButton>
    </div>
  );
}

export default OAuth;
