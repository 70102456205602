import { Avatar as AvatarMui, Box as BoxMui } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.aside`
  display: block;
  min-width: 240px;

  .sidebar-container {
    box-shadow: -1px 0 0 0 #f1f1f1 inset;
    background: var(--white);
    min-height: 100vh;
    height: 100vh;
    width: 240px;
    transition: var(--transition);
    position: fixed;
    z-index: 2;
  }

  .show-sidebar {
    margin-left: 0;
  }

  header {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    justify-content: space-between;
    margin-bottom: 16px;
    min-height: 68px;

    @media (max-width: 749px) {
      box-shadow: 0px -1px 0px 0px #f1f1f1 inset;
    }
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .nav-link {
    display: flex;
    align-items: center;
    color: var(--grey-600);
    padding: 12px 0 12px 24px;
    text-transform: capitalize;
    transition: var(--transition);
    font-family: DMSansMedium, sans-serif;
  }

  .nav-link:hover {
    background: var(--primary-violet-50);
    //padding-left: 3rem;
    color: var(--primary-violet-500);
  }

  .nav-link:hover .icon {
    color: #7d5ff5;
  }

  .icon {
    font-size: 1.5rem;
    margin-right: 23px;
    display: grid;
    place-items: center;
    transition: var(--transition);
    color: #a8b0b9;
  }

  .active {
    color: var(--primary-violet-500);
    background: var(--primary-violet-50);
    border-right: 4px solid var(--primary-violet-500);
  }

  .active .icon {
    color: var(--primary-violet-500);
  }

  @media (max-width: 749px) {
    .sidebar-container {
      width: 100%;
      justify-content: flex-start;
      position: static;
    }
  }
`;

export const Span = styled.span`
  color: ${({ $limitReached }) => ($limitReached ? '#FF513A' : '#4F5B67')};
  font-family: DMSansRegular, sans-serif;
  font-size: 12px;
  line-height: 150%;
`;

export const Avatar = styled(AvatarMui)`
  svg {
    color: #bdbdbd;
    font-size: 32px;
  }

  margin-right: 15px;
  border: 2px solid #d0c5fb;
  max-width: 32px;
  max-height: 32px;
  background: #fff;
`;

export const Box = styled(BoxMui)`
  padding: 28px 5px 28px 24px;
  box-shadow: 0 1px 0 0 #f1f1f1 inset;
`;

export const P = styled.p`
  font-family: DMSansMedium, sans-serif;
  color: var(--gray-600);
  font-size: 14px;
  margin: 0;
  word-break: break-word;
`;

export const LinkWrapper = styled.div`
  .link {
    display: flex;
    align-items: center;
    color: var(--grey-600);
    padding: 12px 0 12px 24px;
    text-transform: capitalize;
    font-family: DMSansMedium, sans-serif;
    margin-bottom: 16px;
  }

  .icon {
    margin-right: 23px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;
