export const DOCUMENT_LIMIT = 5;
const MAX_LENGTH = 360;

export const UPDATE_DAY = 1695306600000;
export const TOOL_TYPES = { generator: 'generator', builder: 'builder', custom: 'custom' };
export const GENERATOR_TOOLS = [
  // tool to help give people a gameplan for building a new habit
  {
    key: 'habit-builder',
    name: 'Habit Builder 🔨',
    descriptionTitle:
      'This tool generates a personalized plan and provides tips on how to build a new habit day-by-day.',
    description: [],
    message: '📝 Generating your game plan!',
    inputs: [
      {
        name: 'habit',
        label: 'Habit You Want to Build 🔨',
        placeholder: 'e.g. "Reading for 30 minutes a day"',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'additonalDetails',
        label: 'Additional Information',
        placeholder: 'e.g. "I tried to build this habit before but failed. I always stopped after a week or so."',
      },
    ],
    button: 'Generate my Gameplan',
    api: 'habitBuilderCompletion',
    historyLink: 'habit-builder',
  },
  // tool for understanding a mental roadblock and how to overcome it
  {
    key: 'mental-block',
    name: 'Understanding Mental Blocks 🧱',
    descriptionTitle: 'This tool helps you understand a mental roadblock and provides strategies to overcome it.',
    description: [],
    message: '📝 Generating your strategies!',
    inputs: [
      {
        name: 'block',
        label: 'Mental Block You Experience',
        placeholder: 'e.g. "I always procrastinate on my work."',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Strategies',
    api: 'mentalBlockCompletion',
    historyLink: 'mental-block',
  },
  // tool for understanding stressful situations and how to overcome them
  {
    key: 'stressful-situation',
    name: 'Understanding Stressful Situations 😰',
    descriptionTitle: 'This tool helps you understand a stressful situation and provides strategies to overcome it.',
    description: [],
    message: '📝 Generating your strategies!',
    inputs: [
      {
        name: 'situation',
        label: 'Situation You Experience Stress/Anxiety',
        placeholder: 'e.g. "I get anxious when I have to speak in public."',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Strategies',
    api: 'stressfulSituationCompletion',
    historyLink: 'stressful-situation',
  },
  // tool for generating affirmations based on user input
  {
    key: 'affirmation-generator',
    name: 'Affirmation Generator 🌟',
    descriptionTitle:
      'This tool will generate 10 positive affirmations that you can recite and revisit to gain confidence in achieving your goals.',
    description: [],
    message: '📝 Generating your affirmations!',
    inputs: [
      {
        name: 'affirmation',
        label: 'Goal or Affirmation',
        placeholder: 'e.g. "I want to be the best dad I can be."',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Affirmations',
    api: 'affirmationCompletion',
    historyLink: 'affirmation-generator',
  },
  // Meditation Consultant Tool - tool for answering questions about meditation, providing guidance for people struggling with meditation, advice on different techniques, etc.
  {
    key: 'meditation-consultant',
    name: 'Meditation Consultant 🧘',
    descriptionTitle:
      'This tool helps answer questions about meditation and provides guidance for those struggling with meditation.',
    description: [],
    message: '📝 Generating your meditation advice!',
    inputs: [
      {
        name: 'question',
        label: 'Question or Struggle with Meditation',
        placeholder: 'e.g. "I can\'t seem to focus when I meditate."',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Advice',
    api: 'meditationAdviceCompletion',
    historyLink: 'meditation-consultant',
  },
  // Meditation Plan Generator - tool for generating a meditation plan based on user input (e.g. goals, experience level, etc.)
  {
    key: 'meditation-plan',
    name: 'Meditation Planner 📅',
    descriptionTitle: 'This tool generates a personalized meditation plan based on your goals and experience level.',
    description: [],
    message: '📝 Generating your meditation plan!',
    inputs: [
      {
        name: 'goal',
        label: 'Goal for Meditation',
        placeholder: 'e.g. "I want to reduce stress."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'experience',
        label: 'Experience Level',
        placeholder: 'e.g. "Beginner"',
      },
    ],
    button: 'Generate Plan',
    api: 'meditationPlanCompletion',
    historyLink: 'meditation-plan',
  },
  // tool for helping someone understand their phobia, why they might have it, and how to overcome it
  {
    key: 'phobia-explorer',
    name: 'Phobia Explorer 🕷️',
    descriptionTitle:
      'This tool helps you understand your phobia, why you might have it, and provides strategies to overcome it.',
    description: [],
    message: '📝 Generating your strategies!',
    inputs: [
      {
        name: 'phobia',
        label: 'Phobia You Experience',
        placeholder: 'e.g. "Fear of heights"',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'details',
        label: 'Triggers or Additional Details',
        placeholder: 'e.g. "I get anxious when I have to go up a tall building."',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Strategies',
    api: 'phobiaExplorerCompletion',
    historyLink: 'phobia-explorer',
  },
  // tool for generating a strong visualization scenario to future pace a user based on their goals and aspirations.
  {
    key: 'visualize-success',
    name: 'Visualization Generator 🌌',
    descriptionTitle:
      'This tool generates a strong visualization scenario to help you future pace your goals and aspirations.',
    description: [],
    message: '📝 Generating your visualization!',
    inputs: [
      {
        name: 'goal',
        label: 'Goal or Aspiration',
        placeholder: 'e.g. "I want to be a successful entrepreneur."',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Visualization',
    api: 'visualizationCompletion',
    historyLink: 'visualize-success',
  },
  // tool for helping people process, prepare for, and handle tough conversations effectively.
  {
    key: 'tough-conversation',
    name: 'Tough Conversation Prep 💬',
    descriptionTitle: 'This tool helps you process, prepare for, and handle tough conversations effectively.',
    description: [],
    message: '📝 Generating your strategies!',
    inputs: [
      {
        name: 'conversation',
        label: 'Tough Conversation You Need to Have',
        placeholder: 'e.g. "I need to tell my boss I want a raise."',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Strategies',
    api: 'toughConversationCompletion',
    historyLink: 'tough-conversation',
  },
  // tool for helping people understand their goals and what it might mean
  {
    key: 'goal-explorer',
    name: 'Goal Explorer 🎯',
    descriptionTitle:
      'This tool helps you understand your goals and what they might mean for you. Add more details to get more insights.',
    description: [],
    message: '📝 Generating your insights!',
    inputs: [
      {
        name: 'goal',
        label: 'Goal or Aspiration',
        placeholder: 'e.g. "I want to be a successful entrepreneur."',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Insights',
    api: 'goalExplorerCompletion',
    historyLink: 'goal-explorer',
  },
  // You are a tool that helps people achieve their goals. Your job is to take their input and generate 5-7 different detailed ideas (formatted with bullet points and headers) on how they can get started on the goal.
  {
    key: 'goal-kickstart',
    name: 'Goal Kickstart 🚀',
    descriptionTitle: 'This tool generates 5-7 detailed ideas on how you can get started on your goal or aspiration.',
    description: [],
    message: '📝 Generating your ideas!',
    inputs: [
      {
        name: 'goal',
        label: 'Goal or Aspiration',
        placeholder: 'e.g. "I want to be a successful entrepreneur."',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Ideas',
    api: 'goalKickstartCompletion',
    historyLink: 'goal-kickstart',
  },
  {
    key: 'conflict-resolution',
    name: 'Conflict Resolution Advisor ⚖️',
    descriptionTitle:
      'Describe the conflict you are facing, including the key people involved, the main issue, and any attempts you’ve made to resolve it so far. The AI will generate strategies and communication tips tailored to your situation, helping you approach the conflict with empathy, understanding, and effectiveness.',
    description: [],
    message: '📝 Generating your strategies!',
    inputs: [
      {
        name: 'conflict',
        label: 'Conflict You Are Facing',
        placeholder: 'e.g. "I am having a conflict with my coworker over a project."',
        maxLength: MAX_LENGTH,
      },
    ],

    button: 'Generate Strategies',
    api: 'conflictResolutionCompletion',
  },
  {
    key: 'career-finder',
    name: 'Career Finder 🚀',
    descriptionTitle:
      'Share your interests, skills, values, and any previous work experiences. Mention what you’re looking for in a career, such as work-life balance, growth opportunities, or specific industries. The AI will suggest potential career paths that align with your profile and provide actionable steps to help you transition into or advance within those careers.',
    description: [],
    message: '📝 Generating your career suggestions!',
    inputs: [
      {
        name: 'interests',
        label: 'Your Interests',
        placeholder: 'e.g. "I enjoy working with people and solving problems."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'skills',
        label: 'Your Skills',
        placeholder: 'e.g. "I am skilled in project management and data analysis."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'values',
        label: 'Your Core Values',
        placeholder: 'e.g. "I value work-life balance and creativity."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'experience',
        label: 'Previous Work Experience',
        placeholder: 'e.g. "I have experience working in customer service and sales."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'career_goals',
        label: 'Career Goals',
        placeholder: 'e.g. "I am looking for a career that offers growth opportunities."',
        maxLength: MAX_LENGTH,
      },
    ],

    button: 'Generate Career Paths',
    api: 'careerFinderCompletion',
  },
  // Decision-Making Assistant tool - tool for helping people make decisions by providing them with a structured approach to decision-making, weighing pros and cons, etc.
  {
    key: 'decision-making',
    name: 'Decision-Making Assistant 🤔',
    descriptionTitle:
      'Describe the decision you need to make, including the options you are considering, the factors you are weighing, and any concerns you have. The AI will provide a structured approach to decision-making, help you weigh the pros and cons of each option, and offer guidance on how to make the best choice.',
    description: [],
    message: '📝 Generating your decision-making guide!',
    inputs: [
      {
        name: 'decision',
        label: 'Decision You Need to Make',
        placeholder: 'e.g. "I need to decide between two job offers."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'options',
        label: 'Options You Are Considering',
        placeholder: 'e.g. "Option 1: Take the job with Company A. Option 2: Take the job with Company B."',
        maxLength: MAX_LENGTH,
      },
      {
        name: 'important_factors',
        label: 'Important Factors',
        placeholder: 'e.g. "Salary, work-life balance, career growth, company culture."',
        maxLength: MAX_LENGTH,
      },
    ],
    button: 'Generate Decision Guide',
    api: 'decisionMakingCompletion',
  },
];

export const BUILDER_TOOLS = [
  {
    key: 'powerpoints',
    name: 'PowerPoint Generator',
    descriptionTitle:
      'Generate a 15 slide, educational PowerPoint on any topic. Simply provide a few details and let Copilot do the rest!',
    description: [],
    message: '📝 Generating a PowerPoint!',
    inputs: [
      {
        name: 'subject',
        label: 'Topic ',
        placeholder: 'The Cold War',
      },
      {
        name: 'gradeLevel',
        label: 'PowerPoint should cover',
        placeholder: 'How it started, what it was, and how it ended',
      },
    ],
    button: 'Generate PowerPoint',
    api: 'powerPointCompletion',
  },
  {
    key: 'recipe-builder',
    name: 'Recipe Builder',
    descriptionTitle:
      'Combine all of the best tools into one creation. Generate a lesson plan, PowerPoint, and more - all at once.',
    description: [],
    message: '📝 Generating a recipe!',
    inputs: [
      {
        name: 'subject',
        label: 'Topic or Lesson Area',
        placeholder: 'The Cold War',
      },
      {
        name: 'gradeLevel',
        label: 'Class & Grade level',
        placeholder: '7th Grade US History',
      },
    ],
    button: 'Generate Resources',
    api: 'recipeCompletion',
  },
];

export const CONFIG_REPLACEMENT_TAGS = {
  lessonplannerv2: {
    title: 'Lesson Plan:',
    regex: [
      {
        title: 'Aim',
        regex: { text: /<aim>/g },
      },
      {
        title: 'Objectives',
        regex: { text: /<objectives>/g },
      },
      {
        title: 'Possible Materials Needed',
        regex: { text: /<materials>/g },
      },
      {
        title: 'Anticipatory Set',
        regex: { text: /<anticipatory>/g },
      },
      {
        title: 'Modeled Practice',
        regex: { text: /<modeled>/g },
      },
      {
        title: 'Guided Practice',
        regex: { text: /<guided>/g },
      },
      {
        title: 'Independent Practice',
        regex: { text: /<independent>/g },
      },
      {
        title: 'Common Areas of Struggle',
        regex: { text: /<struggles>/g },
      },
      {
        title: 'Closure',
        regex: { text: /<closure>/g },
      },
    ],
  },
  'educational-handout': {
    regex: [
      {
        regex: {
          title: /<title>|<\/title>/g,
        },
      },
      {
        title: 'Handout Covers',
        regex: { text: /<handoutcovers>|<\/handoutcovers>/g },
      },
      {
        regex: {
          title: /<header([1-9]|10)>|<\/header([1-9]|10)>/g,
          text: /<body([1-9]|10)>|<\/body([1-9]|10)>/g,
        },
      },
    ],
  },
  'context-builder': {
    title: 'Context for:',
    regex: [
      {
        regex: {
          text: /<(definition|example|word)([1-9]|10)>/g,
        },
      },
    ],
  },
};
