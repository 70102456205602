import { getAuth } from 'firebase/auth';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const auth = getAuth();
  if (auth?.currentUser?.accessToken === null) {
    return <Navigate to="/sign-in" />;
  }
  return children;
};

export default ProtectedRoute;
