import styled from 'styled-components';

export const LoaderDiv = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);

  .MuiCircularProgress-root {
    color: ${({ $color }) => $color};

    &#circularProgress svg {
      margin: 0;
      font-size: inherit;
    }
  }
`;
