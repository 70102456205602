import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box as BoxMui, Button, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import { DOCUMENT_LIMIT } from '../../constants/constants';
import { getDocumentsCount } from '../../store/reducers/userDocumentsCountSlice';
import { Logo } from '../Logo';
import NavLinks from '../NavLinks';
import { Avatar, Box, IconWrapper, LinkWrapper, P, Span, Wrapper } from './styles';

export const Sidebar = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const { documentsCount } = useSelector((state) => state.userDocumentsCountSlice);
  const { userInfo } = useSelector((state) => state.userDetailsSlice);
  const isMobile = useMediaQuery('(max-width: 749px)');

  useEffect(() => {
    if (userInfo) {
      dispatch(getDocumentsCount(userInfo.userUid));
    }
  }, [userInfo, dispatch]);

  const { user, isHaveSub } = userInfo || {};

  return (
    userInfo && (
      <Wrapper>
        <BoxMui
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          className={'sidebar-container'}
        >
          <div>
            <header>
              <Logo />
              {isMobile && (
                <IconWrapper onClick={toggleSidebar}>
                  <CloseIcon />
                </IconWrapper>
              )}
            </header>
            <NavLinks toggleSidebar={toggleSidebar} />
          </div>
          <div>
            {false && (
              <LinkWrapper toggleSidebar={toggleSidebar}>
                <NavLink to={'/help'} className="link">
                  <span className="icon">
                    <HelpOutlineIcon />
                  </span>
                  Help center
                </NavLink>
              </LinkWrapper>
            )}
            {!isHaveSub && (
              <Link to={'/pricing'} onClick={toggleSidebar}>
                <div>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: '#7d5ff5',
                      color: '#fff',
                      '&:hover': { bgcolor: '#6d4df4' },
                      width: '80%',
                      display: 'block',
                      margin: '0 auto',
                      marginBottom: '20px',
                    }}
                  >
                    Upgrade to Pro
                  </Button>
                </div>
              </Link>
            )}
            <Link to={'/profile'} onClick={toggleSidebar}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt="Avatar" src={user.photoURL} sx={{ bgcolor: 'white' }}>
                  {user.photoURL ? null : <AccountCircleIcon />}
                </Avatar>
                <div>
                  <P>{user.displayName}</P>
                  <Span $limitReached={documentsCount >= DOCUMENT_LIMIT && !isHaveSub}>
                    {isHaveSub ? 'Pro' : 'Free'} plan {!isHaveSub && `(${documentsCount}/${DOCUMENT_LIMIT} documents)`}
                  </Span>
                </div>
              </Box>
            </Link>
          </div>
        </BoxMui>
      </Wrapper>
    )
  );
};
