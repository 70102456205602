import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

export const TOOLS = [
  {
    title: 'Meditation Consultant 🧘',
    link: 'tool/generator/meditation-consultant',
    description: 'Get advice on different meditation techniques and answers to your meditation questions.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Goal Explorer 🏆',
    link: 'tool/generator/goal-explorer',
    description: 'Explore and understand your goals and get strategies to achieve them.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Goal Kickstart 🚀',
    link: 'tool/generator/goal-kickstart',
    description: 'Generate ideas on how to kickstart your goals.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Affirmation Generator 🌟',
    link: 'tool/generator/affirmation-generator',
    description: 'Generate positive affirmations to help you stay motivated and positive.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Mental Blocks 🧱',
    link: 'tool/generator/mental-block',
    description: 'Understand a mental roadblock and get strategies to overcome it.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Stressful Situations 😰',
    link: 'tool/generator/stressful-situation',
    description: 'Understand a stressful situation and get strategies to overcome it.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Habit Builder 🔨',
    link: 'tool/generator/habit-builder',
    description: 'Get tips and generate a personalized plan on how to build a new habit day-by-day.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Meditation Planner 📅',
    link: 'tool/generator/meditation-plan',
    description: 'Generate a personalized meditation plan based on your goals and experience level.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Phobia Explorer 🕷️',
    link: 'tool/generator/phobia-explorer',
    description: 'Explore and understand your phobias and get strategies to overcome them.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Visualize Success 🎯',
    link: 'tool/generator/visualize-success',
    description: 'Visualize your success and get motivated to achieve your goals.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Tough Conversations 💬',
    link: 'tool/generator/tough-conversation',
    description: 'Prepare for a tough conversation and get strategies to handle it effectively.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Conflict Resolution Advisor ⚖️',
    link: 'tool/generator/conflict-resolution',
    description: 'Get strategies and tips for resolving conflicts effectively.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Career Finder 🚀',
    link: 'tool/generator/career-finder',
    description: 'Get career path suggestions that align with your interests and values.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Decision-Making Assistant 🤔',
    link: 'tool/generator/decision-making',
    description: 'Get a structured approach to decision-making and guidance on making the best choice.',
    icon: <CollectionsBookmarkIcon />,
  },
];
