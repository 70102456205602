import { combineReducers } from 'redux';

import authSlice from './authSlice';
import navigationSlice from './navigationSlice';
import plansSlice from './plansSlice';
import userDetailsSlice from './userDetailsSlice';
import userDocumentsCountSlice from './userDocumentsCountSlice';

const rootReducer = combineReducers({
  authSlice: authSlice,
  userDetailsSlice: userDetailsSlice,
  userDocumentsCountSlice: userDocumentsCountSlice,
  plansSlice: plansSlice,
  navigationSlice: navigationSlice,
});

export default rootReducer;
