import { getAuth, signInAnonymously } from '@firebase/auth';
import { CardContent, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import { decode } from 'html-entities';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ECButton } from '../../../components/ECButton';
import { ECInput } from '../../../components/ECInput';
import { Loader } from '../../../components/Loader';

import { CONFIG_REPLACEMENT_TAGS, DOCUMENT_LIMIT, GENERATOR_TOOLS } from '../../../constants/constants';
import { db } from '../../../firebase.config';
import { useUpdateDocumentsCount } from '../../../hooks/useUpdateDocumentsCount';
import { logout } from '../../../store/reducers/authSlice';
import { nl2br } from '../../../utils/nl2br';
import { DocumentEditor } from './DocumentEditor';
import { Header } from './Header';
import { ToolDescription } from './ToolDescription';
import { WritingPromptsText } from './WritingPromptsText';
import { replaceTags } from './replaceTags';
import { Box, Card, Form, InputsWrapper, Wrapper } from './styles';

let copyInputs = {};

export const GeneratorLayout = (props) => {
  const { toolName } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateCount = useUpdateDocumentsCount();

  const [inputRows, setInputRows] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [tool, setTool] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.userDetailsSlice);
  const { documentsCount } = useSelector((state) => state.userDocumentsCountSlice);
  const isMobile = useMediaQuery('(max-width: 500px)');
  const isTab = useMediaQuery('(max-width: 909px)');
  const cardRef = useRef(null);
  const [cardHeight, setCardHeight] = useState(0);
  const [isShowGenerateBlock, setIsShowGenerateBlock] = useState(!isMobile);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { userUid } = userInfo || {};
  const [completion, setCompletion] = useState({
    generatedText: '',
  });

  useEffect(() => {
    const selectedTool = [...GENERATOR_TOOLS].find((tool) => tool.key === toolName);

    if (!selectedTool) {
      navigate('/workshop');
      return;
    }

    setTool(selectedTool);
    setInputRows(selectedTool.inputs);
    copyInputs = selectedTool.inputs.reduce((acc, input) => {
      return { ...acc, [input.name]: '' };
    }, {});

    setInputValues({ ...copyInputs });
  }, [toolName, navigate]);

  const getToolData = async () => {
    const docRef = doc(db, 'completions', id);

    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const result = {
      generatedText: data.generatedText,
    };

    if (data.userId === userUid) {
      result.id = id;
    }

    setCompletion(result);
    setInputValues(data.inputValues);
  };

  useEffect(() => {
    if (id) {
      if (userUid) {
        getToolData();
      } else {
        const auth = getAuth();

        signInAnonymously(auth)
          .then(() => {
            localStorage.setItem('isLoggedIn', 'true');

            getToolData();
          })
          .catch(() => {});
      }
    }
  }, [id]);

  const replaceMarkdownWithHTML = (text) => {
    // Replace **bold** with <strong>bold</strong>
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Replace ### headers with <h3>headers</h3>
    text = text.replace(/^###\s+(.*)$/gm, '<h3>$1</h3>');
    text = text.replace(/^####\s+(.*)$/gm, '<h4>$1</h4>');

    return text;
  };

  useEffect(() => {
    if (isShowGenerateBlock !== !isMobile) {
      setIsShowGenerateBlock(!isMobile);
    }
  }, [isMobile]);

  const handleInputChange = (value, name) => {
    setInputValues((prevInputValues) => ({ ...prevInputValues, [name]: value }));
  };

  useEffect(() => {
    if (isTab && cardRef?.current?.offsetHeight) {
      setCardHeight(cardRef.current.offsetHeight);
    }
  }, [cardRef?.current?.offsetHeight]);

  const notify = () =>
    toast(tool.message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
      closeButton: false,
    });

  async function saveCompletionToDB(collectionName, data) {
    const auth = getAuth();
    const user = auth.currentUser;
    data = {
      ...data,
      userId: user.uid,
      timestamp: Date.now(),
    };

    return await addDoc(collection(db, collectionName), data);
  }

  const replaceTextByToolType = (keys, textResult) => {
    keys.forEach(({ title, regex }) => {
      if (regex.text) {
        textResult = replaceTags({ text: textResult, regexPattern: regex.text, title });
      }
      if (regex.title) {
        textResult = replaceTags({ text: textResult, regexPattern: regex.title, tag: 'h4' });
      }
    });

    return textResult;
  };

  const fetchApi = async (inputValues) => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    notify();

    try {
      const response = await axios.post(`${window.location.origin}/api/v1/completions/${tool.api}`, {
        ...inputValues,
      });
      const data = response.data.choices[0].message.content;
      setIsLoading(false);

      console.log('response', response.data);

      if (isMobile) {
        setIsShowGenerateBlock(true);
      }

      let textResult = decode(data);
      textResult = replaceMarkdownWithHTML(textResult); // Apply bold replacement here

      const tags = CONFIG_REPLACEMENT_TAGS[tool.key];

      if (tool.key === 'lessonplannerv2') {
        textResult = textResult.replaceAll(
          /Possible Materials Needed|Anticipatory Set|Student Objectives & Goals|Modeled Practice Ideas|Guided Practice Ideas|Independent Practice Ideas|Common Areas of Struggle|Closure\/Assessment/g,
          ''
        );
      }

      if (tags?.title) {
        textResult = `<h4 style="font-size: 28px">${tags?.title} ${inputValues.subject}</h4>${textResult}`;
      }

      textResult = tags ? replaceTextByToolType(tags.regex, textResult) : textResult;

      const dataToSave = {
        inputValues,
        application: tool.name,
        generatedText: nl2br(textResult),
      };

      saveCompletionToDB('completions', dataToSave).then((ref) => {
        setCompletion({
          ...dataToSave,
          id: ref.id,
        });

        if (!userInfo.isHaveSub) {
          updateCount();
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!userUid) {
      dispatch(logout());
      navigate('/sign-in');
      return;
    }

    if (!userInfo.isHaveSub && documentsCount >= DOCUMENT_LIMIT) {
      navigate('/pricing');
      return;
    }

    fetchApi(inputValues);
  };

  const handleClear = () => {
    setInputValues({ ...copyInputs });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const isShowPrompts = (isMobile && !isShowGenerateBlock) || !isMobile;

  if (id && !completion.generatedText) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader loading={true} size={40} />
      </div>
    );
  }

  return (
    <>
      {isShowPrompts && (
        <Header
          text={tool.name}
          handleBack={handleBack}
          isShowButton={!isMobile}
          inputValues={inputValues}
          tool={tool}
          completion={completion}
        />
      )}

      <Wrapper
        $height={cardHeight}
        $isShowGenerateBlock={isShowGenerateBlock}
        className={isShowPrompts ? '' : 'text-editor'}
      >
        {isShowPrompts && (
          <Card ref={cardRef}>
            <CardContent>
              <ToolDescription {...tool} />

              <Form onSubmit={handleSubmit}>
                <InputsWrapper>
                  {inputRows
                    ? inputRows.map((inputRow) => (
                        <div key={inputRow.name} className={`input ${inputRow.multiline ? 'multiline' : ''}`}>
                          <ECInput
                            value={inputValues[inputRow.name]}
                            onChange={(e) => handleInputChange(e.target.value, inputRow.name)}
                            {...inputRow}
                          />
                          {toolName === 'writing-prompts' && <WritingPromptsText />}
                        </div>
                      ))
                    : null}
                </InputsWrapper>
                <Box>
                  <ECButton variant="Primary" type="submit" loading={isLoading}>
                    Generate
                  </ECButton>
                  <ECButton variant="Outline" onClick={handleClear}>
                    Clear
                  </ECButton>
                </Box>
              </Form>
            </CardContent>
          </Card>
        )}

        {isShowGenerateBlock && (
          <>
            {isMobile && (
              <Header
                text="Generated text"
                handleBack={() => setIsShowGenerateBlock(false)}
                isShowButton={true}
                inputValues={inputValues}
                tool={tool}
                completion={completion}
              />
            )}
            <DocumentEditor
              completion={completion}
              setIsShowGenerateBlock={setIsShowGenerateBlock}
              setCompletion={setCompletion}
            />
          </>
        )}
      </Wrapper>
    </>
  );
};
