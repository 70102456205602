import { getAuth } from '@firebase/auth';
import { pdf } from '@react-pdf/renderer';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { decode } from 'html-entities';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { db } from '../../../../firebase.config';
import PDF from '../../../ai-tools/custom/ContextPDF';

const ContextBuilderAPI = ({ overview, dayNumber }) => {
  // PDF File States
  const [fileUrl, setFileUrl] = useState(null);

  // Lesson Plan Section States
  const [firstWord, setFirstWord] = useState('');
  const [firstDefinition, setFirstDefinition] = useState('');
  const [firstExample, setFirstExample] = useState('');
  const [secondWord, setSecondWord] = useState('');
  const [secondDefinition, setSecondDefinition] = useState('');
  const [secondExample, setSecondExample] = useState('');
  const [thirdWord, setThirdWord] = useState('');
  const [thirdDefinition, setThirdDefinition] = useState('');
  const [thirdExample, setThirdExample] = useState('');
  const [fourthWord, setFourthWord] = useState('');
  const [fourthDefinition, setFourthDefinition] = useState('');
  const [fourthExample, setFourthExample] = useState('');
  const [fifthWord, setFifthWord] = useState('');
  const [fifthDefinition, setFifthDefinition] = useState('');
  const [fifthExample, setFifthExample] = useState('');
  const [sixthWord, setSixthWord] = useState('');
  const [sixthDefinition, setSixthDefinition] = useState('');
  const [sixthExample, setSixthExample] = useState('');
  const [seventhWord, setSeventhWord] = useState('');
  const [seventhDefinition, setSeventhDefinition] = useState('');
  const [seventhExample, setSeventhExample] = useState('');
  const [eighthWord, setEighthWord] = useState('');
  const [eighthDefinition, setEighthDefinition] = useState('');
  const [eighthExample, setEighthExample] = useState('');
  const [ninthWord, setNinthWord] = useState('');
  const [ninthDefinition, setNinthDefinition] = useState('');
  const [ninthExample, setNinthExample] = useState('');
  const [tenthWord, setTenthWord] = useState('');
  const [tenthDefinition, setTenthDefinition] = useState('');
  const [tenthExample, setTenthExample] = useState('');

  // API Request & Response States

  const [subject] = useState('');
  const [gradeLevel, setGradeLevel] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [documentHasChanged, setDocumentHasChanged] = useState(false);
  const [fileIsReady, setFileIsReady] = useState(false);

  const { unitID } = useParams();
  let overviewText = overview;

  const notify = () =>
    toast('🚀 Generating Context Builder!', {
      position: 'top-left',
      autoClose: 70000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
      toastId: 'contextunit',
    });

  // Async function to save the document to the database

  async function saveCompletionToDB(collectionName, data) {
    const auth = getAuth();
    const user = auth.currentUser;
    data = {
      ...data,
      userId: user.uid,
      timestamp: Date.now(),
    };
    return await addDoc(collection(db, collectionName), data);
  }

  async function checkIfLessonPlan() {
    const docRef = doc(db, 'units', unitID);
    const docSnap = await getDoc(docRef);

    setGradeLevel(docSnap.data()[gradeLevel]);
  }

  useEffect(() => {
    checkIfLessonPlan(dayNumber);
  }, []);

  async function fetchApi(subject, gradeLevel, overviewText) {
    notify();
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      subject,
      gradeLevel,
      overviewText,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${window.location.origin}/api/v1/completions/contextBuilderCompletionUnit`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let textResult = decode(result.choices[0].text);

        const regexDefinition1 = /<definition1>(.*?)<\/definition1>/s;
        const matchFirst = textResult.match(regexDefinition1);

        if (matchFirst) {
          setFirstDefinition(matchFirst[1]);
        }

        // First Example
        const regexExample1 = /<example1>(.*?)<\/example1>/s;
        const matchFirstExample = textResult.match(regexExample1);

        if (matchFirstExample) {
          setFirstExample(matchFirstExample[1]);
        }

        // Second definition
        const regexDefinition2 = /<definition2>(.*?)<\/definition2>/s;
        const matchSecond = textResult.match(regexDefinition2);

        if (matchSecond) {
          setSecondDefinition(matchSecond[1]);
        }

        // Second Example
        const regexExample2 = /<example2>(.*?)<\/example2>/s;
        const matchSecondExample = textResult.match(regexExample2);

        if (matchSecondExample) {
          setSecondExample(matchSecondExample[1]);
        }

        // Third Definition
        const regexDefinition3 = /<definition3>(.*?)<\/definition3>/s;
        const matchThird = textResult.match(regexDefinition3);

        if (matchThird) {
          setThirdDefinition(matchThird[1]);
        }

        // Third Example
        const regexExample3 = /<example3>(.*?)<\/example3>/s;
        const matchThirdExample = textResult.match(regexExample3);

        if (matchThirdExample) {
          setThirdExample(matchThirdExample[1]);
        }

        // Fourth Definition
        const regexDefinition4 = /<definition4>(.*?)<\/definition4>/s;
        const matchFourth = textResult.match(regexDefinition4);

        if (matchFourth) {
          setFourthDefinition(matchFourth[1]);
        }

        // Fourth Example
        const regexExample4 = /<example4>(.*?)<\/example4>/s;
        const matchFourthExample = textResult.match(regexExample4);

        if (matchFourthExample) {
          setFourthExample(matchFourthExample[1]);
        }

        // Fifth Definition
        const regexDefinition5 = /<definition5>(.*?)<\/definition5>/s;
        const matchFifth = textResult.match(regexDefinition5);

        if (matchFifth) {
          setFifthDefinition(matchFifth[1]);
        }

        // Fifth Example
        const regexExample5 = /<example5>(.*?)<\/example5>/s;
        const matchFifthExample = textResult.match(regexExample5);

        if (matchFifthExample) {
          setFifthExample(matchFifthExample[1]);
        }

        // Sixth Definition
        const regexDefinition6 = /<definition6>(.*?)<\/definition6>/s;
        const matchSixth = textResult.match(regexDefinition6);

        if (matchSixth) {
          setSixthDefinition(matchSixth[1]);
        }

        // Sixth Example
        const regexExample6 = /<example6>(.*?)<\/example6>/s;
        const matchSixthExample = textResult.match(regexExample6);

        if (matchSixthExample) {
          setSixthExample(matchSixthExample[1]);
        }

        // Seventh Definition
        const regexDefinition7 = /<definition7>(.*?)<\/definition7>/s;
        const matchSeventh = textResult.match(regexDefinition7);

        if (matchSeventh) {
          setSeventhDefinition(matchSeventh[1]);
        }

        // Seventh Example
        const regexExample7 = /<example7>(.*?)<\/example7>/s;
        const matchSeventhExample = textResult.match(regexExample7);

        if (matchSeventhExample) {
          setSeventhExample(matchSeventhExample[1]);
        }

        // Eighth Definition
        const regexDefinition8 = /<definition8>(.*?)<\/definition8>/s;
        const matcheighth = textResult.match(regexDefinition8);

        if (matcheighth) {
          setEighthDefinition(matcheighth[1]);
        }

        // Eighth Example
        const regexExample8 = /<example8>(.*?)<\/example8>/s;
        const matchEighthExample = textResult.match(regexExample8);

        if (matchEighthExample) {
          setEighthExample(matchEighthExample[1]);
        }

        // Ninth Definition
        const regexDefinition9 = /<definition9>(.*?)<\/definition9>/s;
        const matchNinth = textResult.match(regexDefinition9);

        if (matchNinth) {
          setNinthDefinition(matchNinth[1]);
        }

        // Ninth Example
        const regexExample9 = /<example9>(.*?)<\/example9>/s;
        const matchNinthExample = textResult.match(regexExample9);

        if (matchNinthExample) {
          setNinthExample(matchNinthExample[1]);
        }

        // Tenth Definition
        const regexDefinition10 = /<definition10>(.*?)<\/definition10>/s;
        const matchTenth = textResult.match(regexDefinition10);

        if (matchTenth) {
          setTenthDefinition(matchTenth[1]);
        }

        // Tenth Example
        const regexExample10 = /<example10>(.*?)<\/example10>/s;
        const matchTenthExample = textResult.match(regexExample10);

        if (matchTenthExample) {
          setTenthExample(matchTenthExample[1]);
        }

        // First Word
        const regexWord1 = /<word1>(.*?)<\/word1>/s;
        const matchWord = textResult.match(regexWord1);

        if (matchWord) {
          setFirstWord(matchWord[1]);
        }

        // Second Word
        const regexWord2 = /<word2>(.*?)<\/word2>/s;
        const matchWord2 = textResult.match(regexWord2);

        if (matchWord2) {
          setSecondWord(matchWord2[1]);
        }

        // Third Word
        const regexWord3 = /<word3>(.*?)<\/word3>/s;
        const matchWord3 = textResult.match(regexWord3);

        if (matchWord3) {
          setThirdWord(matchWord3[1]);
        }

        // Fourth Word
        const regexWord4 = /<word4>(.*?)<\/word4>/s;
        const matchWord4 = textResult.match(regexWord4);

        if (matchWord4) {
          setFourthWord(matchWord4[1]);
        }

        // Fifth Word
        const regexWord5 = /<word5>(.*?)<\/word5>/s;
        const matchWord5 = textResult.match(regexWord5);

        if (matchWord5) {
          setFifthWord(matchWord5[1]);
        }

        // Sixth Word
        const regexWord6 = /<word6>(.*?)<\/word6>/s;
        const matchWord6 = textResult.match(regexWord6);

        if (matchWord6) {
          setSixthWord(matchWord6[1]);
        }

        // Seventh Word
        const regexWord7 = /<word7>(.*?)<\/word7>/s;
        const matchWord7 = textResult.match(regexWord7);

        if (matchWord7) {
          setSeventhWord(matchWord7[1]);
        }

        // Eighth Word
        const regexWord8 = /<word8>(.*?)<\/word8>/s;
        const matchWord8 = textResult.match(regexWord8);

        if (matchWord8) {
          setEighthWord(matchWord8[1]);
        }

        // Ninth Word
        const regexWord9 = /<word9>(.*?)<\/word9>/s;
        const matchWord9 = textResult.match(regexWord9);

        if (matchWord9) {
          setNinthWord(matchWord9[1]);
        }

        // Tenth Word
        const regexWord10 = /<word10>(.*?)<\/word10>/s;
        const matchWord10 = textResult.match(regexWord10);

        if (matchWord10) {
          setTenthWord(matchWord10[1]);
        }

        setDocumentHasChanged(true);

        // wait 100 ms before setting the documentHasChanged state to false
        setTimeout(() => {
          setDocumentHasChanged(false);
        }, 100);

        const dataToSave = {
          subject,
          gradeLevel,
          overviewText,
          application: 'Lesson Planner V2',
          generatedText: result.choices[0].text,
        };

        // Save the completion to the database, then set the completion state, then log the ref, then catch any errors
        saveCompletionToDB('completions', dataToSave)
          .then(() => {})
          .catch((err) => console.log('error', err));
      })
      .catch((error) => console.log('error', error));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!overviewText) {
      console.log('Please enter a subject');
      return;
    }

    setFirstWord('');
    setSecondWord('');
    setThirdWord('');
    setFourthWord('');
    setFifthWord('');
    setSixthWord('');
    setSeventhWord('');
    setEighthWord('');
    setNinthWord('');
    setTenthWord('');
    setFirstDefinition('');
    setFirstExample('');
    setSecondDefinition('');
    setSecondExample('');
    setThirdDefinition('');
    setThirdExample('');
    setFourthDefinition('');
    setFourthExample('');
    setFifthDefinition('');
    setFifthExample('');
    setSixthDefinition('');
    setSixthExample('');
    setSeventhDefinition('');
    setSeventhExample('');
    setEighthDefinition('');
    setEighthExample('');
    setNinthDefinition('');
    setNinthExample('');
    setTenthDefinition('');
    setTenthExample('');

    fetchApi(subject, gradeLevel, overviewText);
  };

  // Download PDF Functions

  const createPDF = async (dayNumber) => {
    // Create a blob from the PDF component
    const blob = await pdf(
      <PDF
        subject={subject}
        gradeLevel={gradeLevel}
        firstWord={firstWord}
        secondWord={secondWord}
        thirdWord={thirdWord}
        fourthWord={fourthWord}
        fifthWord={fifthWord}
        sixthWord={sixthWord}
        seventhWord={seventhWord}
        eighthWord={eighthWord}
        ninthWord={ninthWord}
        tenthWord={tenthWord}
        firstDefinition={firstDefinition}
        firstExample={firstExample}
        secondDefinition={secondDefinition}
        secondExample={secondExample}
        thirdDefinition={thirdDefinition}
        thirdExample={thirdExample}
        fourthDefinition={fourthDefinition}
        fourthExample={fourthExample}
        fifthDefinition={fifthDefinition}
        fifthExample={fifthExample}
        sixthDefinition={sixthDefinition}
        sixthExample={sixthExample}
        seventhDefinition={seventhDefinition}
        seventhExample={seventhExample}
        eighthDefinition={eighthDefinition}
        eighthExample={eighthExample}
        ninthDefinition={ninthDefinition}
        ninthExample={ninthExample}
        tenthDefinition={tenthDefinition}
        tenthExample={tenthExample}
      />
    ).toBlob();

    toast.dismiss('contextunit');

    // Get the current user and the storage service and the user id
    const auth = getAuth();
    const user = auth.currentUser;
    const storage = getStorage();
    const userId = user.uid;

    // Creates a reference to the file we want to upload and where we want to upload it
    const storageRef = ref(storage, `users/${userId}/lesson-plans/${subject} Context Builder${Date.now()}.pdf`);

    // Uploads the file to the storage reference
    await uploadBytes(storageRef, blob).then(() => {
      console.log('PDF saved to Firebase');
    });

    // Gets the download URL for the file
    const fileUrl = await getDownloadURL(storageRef);
    setFileUrl(fileUrl);

    async function updateDay(dayNumber) {
      const docRef = doc(db, 'units', unitID);
      const docSnap = await getDoc(docRef);
      const day = docSnap.get(dayNumber);
      const updatedDay = { ...day, contextbuilder: `${fileUrl}` };
      await updateDoc(docRef, { [dayNumber]: updatedDay });
    }

    await updateDay(dayNumber);
    setIsLoading(false);
    setFileIsReady(true);
  };

  const handleDownload = async () => {
    const unitRef = doc(db, 'units', unitID);
    const docSnap = await getDoc(unitRef);
    const url = docSnap.data()[dayNumber].contextbuilder;

    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${dayNumber} Context Builder.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  // Anytime documentHasChanged changes, it will update the state

  useEffect(() => {
    if (documentHasChanged) {
      createPDF(dayNumber);
    }
  }, [documentHasChanged]);

  async function getUnitDetails() {
    const unitRef = doc(db, 'units', unitID);
    const docSnap = await getDoc(unitRef);

    if (await docSnap.data()[dayNumber].contextbuilder) {
      setButtonJSX(
        <button
          className="btn-primary"
          type="submit"
          onClick={handleDownload}
          style={{
            border: '1px dashed #1e90ff',
            marginBottom: '1.5rem',
            backgroundColor: '#1e90ff',
            color: 'white',
          }}
        >
          Context Builder
        </button>
      );
    } else {
      setButtonJSX(
        <button
          className="btn-primary"
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          {isLoading ? (
            <div className="buttonelements">
              <div className="buttonicon"></div>
              <div className="buttontext">Loading...</div>
            </div>
          ) : (
            <div className="buttonelements">
              <div className="buttonicon">+</div>
              <div className="buttontext">Context Builder</div>
            </div>
          )}
        </button>
      );
    }
  }

  useEffect(() => {
    getUnitDetails();
  }, [isLoading]);

  const [buttonJSX, setButtonJSX] = useState(null);

  useEffect(() => {
    if (fileUrl) {
      setButtonJSX(
        <button
          className="btn-primary"
          type="submit"
          onClick={handleDownload}
          style={{
            border: '1px dashed #1e90ff',
            marginBottom: '1.5rem',
            backgroundColor: '#1e90ff',
            color: 'white',
          }}
        >
          Context Builder
        </button>
      );
    }
  }, [fileIsReady]);

  return <div>{buttonJSX}</div>;
};

export default ContextBuilderAPI;
