// Hooks and React Imports
import { getAuth } from '@firebase/auth';
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { decode } from 'html-entities';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RiseLoader from 'react-spinners/RiseLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { db } from '../../../../firebase.config';
import { nl2br } from '../../../../utils/nl2br';
import '../multiform.css';

const UnitPlanner = (props) => {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [unitUpdated, setUnitUpdated] = useState(false);
  const [unitID, setUnitID] = useState('');
  const [completion, setCompletion] = useState({
    unitName: '',
    unitLength: '',
    time: '',
    gradeLevel: '',
    id: '',
  });

  const unitName = props.unitName;
  const unitStandards = props.unitStandards;
  const unitDetails = props.unitDetails;
  const unitLength = props.unitLength;

  useEffect(() => {
    console.log('unitName', unitName);
    console.log('unitStandards', unitStandards);
    console.log('unitDetails', unitDetails);
    console.log('unitLength', unitLength);
  }, [unitName, unitStandards, unitDetails, unitLength]);

  // Match States
  const [matchFirst, setMatchFirst] = useState(null);
  const [matchSecond, setMatchSecond] = useState(null);
  const [matchThird, setMatchThird] = useState(null);
  const [matchFourth, setMatchFourth] = useState(null);
  const [matchFifth, setMatchFifth] = useState(null);
  const [matchSixth, setMatchSixth] = useState(null);
  const [matchSeventh, setMatchSeventh] = useState(null);
  const [matchEighth, setMatchEighth] = useState(null);
  const [matchNinth, setMatchNinth] = useState(null);
  const [matchTenth, setMatchTenth] = useState(null);
  const [matchEleventh, setMatchEleventh] = useState(null);
  const [matchTwelfth, setMatchTwelfth] = useState(null);
  const [matchThirteenth, setMatchThirteenth] = useState(null);
  const [matchFourteenth, setMatchFourteenth] = useState(null);
  const [matchFifteenth, setMatchFifteenth] = useState(null);
  const [titleFirst, setTitleFirst] = useState(null);
  const [titleSecond, setTitleSecond] = useState(null);
  const [titleThird, setTitleThird] = useState(null);
  const [titleFourth, setTitleFourth] = useState(null);
  const [titleFifth, setTitleFifth] = useState(null);
  const [titleSixth, setTitleSixth] = useState(null);
  const [titleSeventh, setTitleSeventh] = useState(null);
  const [titleEighth, setTitleEighth] = useState(null);
  const [titleNinth, setTitleNinth] = useState(null);
  const [titleTenth, setTitleTenth] = useState(null);
  const [titleEleventh, setTitleEleventh] = useState(null);
  const [titleTwelfth, setTitleTwelfth] = useState(null);
  const [titleThirteenth, setTitleThirteenth] = useState(null);
  const [titleFourteenth, setTitleFourteenth] = useState(null);
  const [titleFifteenth, setTitleFifteenth] = useState(null);

  const navigate = useNavigate();

  // Get current user
  const auth = getAuth();
  const user = auth.currentUser;

  const completionRef = useRef(null);
  completionRef.current = completion;

  async function getFilteredDataFromDBForUser(collectionName, filter) {
    const auth = getAuth();
    const user = auth.currentUser;
    const docsToAdd = [];
    let dbQuery;
    if (filter === 'All Tools') {
      dbQuery = query(collection(db, collectionName), where('userId', '==', user.uid));
    } else if (Array.isArray(filter)) {
      dbQuery = query(
        collection(db, collectionName),
        where('userId', '==', user.uid),
        where('application', 'in', filter)
      );
    }

    const querySnapshot = await getDocs(dbQuery);
    querySnapshot.forEach((doc) => {
      docsToAdd.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    docsToAdd.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
  }

  React.useEffect(() => getFilteredDataFromDBForUser('units', 'All Tools'), []);

  // Function to save unit to database
  async function saveUnitToDB(unitName, data) {
    data = {
      ...data,
      userId: user.uid,
      timestamp: Date.now(),
    };
    const ref = await addDoc(collection(db, unitName), data);
    setUnitID(ref.id);
    return ref;
  }

  async function storeUnit(unitName, unitLength, unitStandards, unitDetails) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const dataToSave = {
      unitName,
      unitLength,
      unitStandards,
      unitDetails,
    };

    await saveUnitToDB('units', dataToSave)
      .then((ref) => {
        setCompletion({
          ...dataToSave,
          id: ref.id,
        });

        setUnitID(ref.id);
      })
      .catch((err) => console.log('error', err));
  }

  async function fetchApi(unitName, unitLength, unitStandards, unitDetails) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    notify();

    const raw = JSON.stringify({
      unitName,
      unitLength,
      unitStandards,
      unitDetails,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${window.location.origin}/api/v1/completions/unitOverviewCompletion`, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        let textResult = decode(result.choices[0].text);

        // First Day
        const regexDay1 = /<day1>(.*?)<\/day1>/s;
        if (textResult.match(regexDay1) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const matchFirst = textResult.match(regexDay1);
          setMatchFirst(matchFirst[1]);
        }

        // Second Day
        const regexDay2 = /<day2>(.*?)<\/day2>/s;
        if (textResult.match(regexDay2) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const matchSecond = textResult.match(regexDay2);
          setMatchSecond(matchSecond[1]);
        }

        // Third Day
        const regexDay3 = /<day3>(.*?)<\/day3>/s;
        if (textResult.match(regexDay3) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const matchThird = textResult.match(regexDay3);
          setMatchThird(matchThird[1]);
        }

        // Fourth Day
        const regexDay4 = /<day4>(.*?)<\/day4>/s;
        if (textResult.match(regexDay4) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const matchFourth = textResult.match(regexDay4);
          setMatchFourth(matchFourth[1]);
        }

        // Fifth Day
        const regexDay5 = /<day5>(.*?)<\/day5>/s;
        if (textResult.match(regexDay5) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const matchFifth = textResult.match(regexDay5);
          setMatchFifth(matchFifth[1]);
        }

        // Sixth Day
        const regexDay6 = /<day6>(.*?)<\/day6>/s;
        if (textResult.match(regexDay6) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const matchSixth = textResult.match(regexDay6);
          setMatchSixth(matchSixth[1]);
        }

        // Seventh Day
        const regexDay7 = /<day7>(.*?)<\/day7>/s;
        if (textResult.match(regexDay7) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const matchSeventh = textResult.match(regexDay7);
          setMatchSeventh(matchSeventh[1]);
        }

        // Eighth Day
        const regexDay8 = /<day8>(.*?)<\/day8>/s;
        if (textResult.match(regexDay8) === null) {
          console.log('Day 8 ====', 'No Data');
        } else {
          const matchEighth = textResult.match(regexDay8);
          setMatchEighth(matchEighth[1]);
        }

        // Ninth Day
        const regexDay9 = /<day9>(.*?)<\/day9>/s;
        if (textResult.match(regexDay9) === null) {
          console.log('Day 9 ====', 'No Data');
        } else {
          const matchNinth = textResult.match(regexDay9);
          setMatchNinth(matchNinth[1]);
        }

        // Tenth Day
        const regexDay10 = /<day10>(.*?)<\/day10>/s;
        if (textResult.match(regexDay10) === null) {
          console.log('Day 10 ====', 'No Data');
        } else {
          const matchTenth = textResult.match(regexDay10);
          setMatchTenth(matchTenth[1]);
        }

        // Eleventh Day
        const regexDay11 = /<day11>(.*?)<\/day11>/s;
        if (textResult.match(regexDay11) === null) {
          console.log('Day 11 ====', 'No Data');
        } else {
          const matchEleventh = textResult.match(regexDay11);
          setMatchEleventh(matchEleventh[1]);
        }

        // Twelfth Day
        const regexDay12 = /<day12>(.*?)<\/day12>/s;
        if (textResult.match(regexDay12) === null) {
          console.log('Day 12 ====', 'No Data');
        } else {
          const matchTwelfth = textResult.match(regexDay12);
          setMatchTwelfth(matchTwelfth[1]);
        }

        // Thirteenth Day
        const regexDay13 = /<day13>(.*?)<\/day13>/s;
        if (textResult.match(regexDay13) === null) {
          console.log('Day 13 ====', 'No Data');
        } else {
          const matchThirteenth = textResult.match(regexDay13);
          setMatchThirteenth(matchThirteenth[1]);
        }

        // Fourteenth Day
        const regexDay14 = /<day14>(.*?)<\/day14>/s;
        if (textResult.match(regexDay14) === null) {
          console.log('Day 14 ====', 'No Data');
        } else {
          const matchFourteenth = textResult.match(regexDay14);
          setMatchFourteenth(matchFourteenth[1]);
        }

        // Fifteenth Day
        const regexDay15 = /<day15>(.*?)<\/day15>/s;
        if (textResult.match(regexDay15) === null) {
          console.log('Day 15 ====', 'No Data');
        } else {
          const matchFifteenth = textResult.match(regexDay15);
          setMatchFifteenth(matchFifteenth[1]);
        }

        // Title First Day
        const regexTitle1 = /<title1>(.*?)<\/title1>/s;
        if (textResult.match(regexTitle1) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const titleFirst = textResult.match(regexTitle1);
          setTitleFirst(titleFirst[1]);
        }

        // Title Second Day
        const regexTitle2 = /<title2>(.*?)<\/title2>/s;
        if (textResult.match(regexTitle2) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const titleSecond = textResult.match(regexTitle2);
          setTitleSecond(titleSecond[1]);
        }

        // Title Third Day
        const regexTitle3 = /<title3>(.*?)<\/title3>/s;
        if (textResult.match(regexTitle3) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const titleThird = textResult.match(regexTitle3);
          setTitleThird(titleThird[1]);
        }

        // Title Fourth Day
        const regexTitle4 = /<title4>(.*?)<\/title4>/s;
        if (textResult.match(regexTitle4) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const titleFourth = textResult.match(regexTitle4);
          setTitleFourth(titleFourth[1]);
        }

        // Title Fifth Day
        const regexTitle5 = /<title5>(.*?)<\/title5>/s;
        if (textResult.match(regexTitle5) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const titleFifth = textResult.match(regexTitle5);
          setTitleFifth(titleFifth[1]);
        }

        // Title Sixth Day
        const regexTitle6 = /<title6>(.*?)<\/title6>/s;
        if (textResult.match(regexTitle6) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const titleSixth = textResult.match(regexTitle6);
          setTitleSixth(titleSixth[1]);
        }

        // Title Seventh Day
        const regexTitle7 = /<title7>(.*?)<\/title7>/s;
        if (textResult.match(regexTitle7) === null) {
          console.log('Title 7 ====', 'No Data');
        } else {
          const titleSeventh = textResult.match(regexTitle7);
          setTitleSeventh(titleSeventh[1]);
        }

        // Title Eighth Day
        const regexTitle8 = /<title8>(.*?)<\/title8>/s;
        if (textResult.match(regexTitle8) === null) {
          console.log('Title 8 ====', 'No Data');
        } else {
          const titleEighth = textResult.match(regexTitle8);
          setTitleEighth(titleEighth[1]);
        }

        // Title Ninth Day
        const regexTitle9 = /<title9>(.*?)<\/title9>/s;
        if (textResult.match(regexTitle9) === null) {
          console.log('Title 9 ====', 'No Data');
        } else {
          const titleNinth = textResult.match(regexTitle9);
          setTitleNinth(titleNinth[1]);
        }

        // Title Tenth Day
        const regexTitle10 = /<title10>(.*?)<\/title10>/s;
        if (textResult.match(regexTitle10) === null) {
          console.log('Title 10 ====', 'No Data');
        } else {
          const titleTenth = textResult.match(regexTitle10);
          setTitleTenth(titleTenth[1]);
        }

        // Title Eleventh Day
        const regexTitle11 = /<title11>(.*?)<\/title11>/s;
        if (textResult.match(regexTitle11) === null) {
          console.log('Title 11 ====', 'No Data');
        } else {
          const titleEleventh = textResult.match(regexTitle11);
          setTitleEleventh(titleEleventh[1]);
        }

        // Title Twelfth Day
        const regexTitle12 = /<title12>(.*?)<\/title12>/s;
        if (textResult.match(regexTitle12) === null) {
          console.log('Title 12 ====', 'No Data');
        } else {
          const titleTwelfth = textResult.match(regexTitle12);
          setTitleTwelfth(titleTwelfth[1]);
        }

        // Title Thirteenth Day
        const regexTitle13 = /<title13>(.*?)<\/title13>/s;
        if (textResult.match(regexTitle13) === null) {
          console.log('Title 13 ====', 'No Data');
        } else {
          const titleThirteenth = textResult.match(regexTitle13);
          setTitleThirteenth(titleThirteenth[1]);
        }

        // Title Fourteenth Day
        const regexTitle14 = /<title14>(.*?)<\/title14>/s;
        if (textResult.match(regexTitle14) === null) {
          console.log('Title 14 ====', 'No Data');
        } else {
          const titleFourteenth = textResult.match(regexTitle14);
          setTitleFourteenth(titleFourteenth[1]);
        }

        // Title Fifteenth Day
        const regexTitle15 = /<title15>(.*?)<\/title15>/s;
        if (textResult.match(regexTitle15) === null) {
          console.log('Title 15 ====', 'No Data');
        } else {
          const titleFifteenth = textResult.match(regexTitle15);
          setTitleFifteenth(titleFifteenth[1]);
        }

        setUnitUpdated(true);
      })
      .catch((error) => console.log('error', error));
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await fetchApi(unitName, unitLength, unitStandards, unitDetails);
    await storeUnit(unitName, unitLength, unitStandards, unitDetails);
  };

  useEffect(() => {
    if (unitUpdated) {
      // setIsLoading(false);
      const docRef = doc(db, 'units', unitID);

      updateDoc(docRef, {
        day1: { match: matchFirst ? nl2br(matchFirst) : '' },
        day2: { match: matchSecond ? nl2br(matchSecond) : '' },
        day3: { match: matchThird ? nl2br(matchThird) : '' },
        day4: { match: matchFourth ? nl2br(matchFourth) : '' },
        day5: { match: matchFifth ? nl2br(matchFifth) : '' },
        day6: { match: matchSixth ? nl2br(matchSixth) : '' },
        day7: { match: matchSeventh ? nl2br(matchSeventh) : '' },
        day8: { match: matchEighth ? nl2br(matchEighth) : '' },
        day9: { match: matchNinth ? nl2br(matchNinth) : '' },
        day10: { match: matchTenth ? nl2br(matchTenth) : '' },
        day11: { match: matchEleventh ? nl2br(matchEleventh) : '' },
        day12: { match: matchTwelfth ? nl2br(matchTwelfth) : '' },
        day13: {
          match: matchThirteenth ? nl2br(matchThirteenth) : '',
        },
        day14: {
          match: matchFourteenth ? nl2br(matchFourteenth) : '',
        },
        day15: { match: matchFifteenth ? nl2br(matchFifteenth) : '' },
        // Titles
        title1: titleFirst ? titleFirst : '',
        title2: titleSecond ? titleSecond : '',
        title3: titleThird ? titleThird : '',
        title4: titleFourth ? titleFourth : '',
        title5: titleFifth ? titleFifth : '',
        title6: titleSixth ? titleSixth : '',
        title7: titleSeventh ? titleSeventh : '',
        title8: titleEighth ? titleEighth : '',
        title9: titleNinth ? titleNinth : '',
        title10: titleTenth ? titleTenth : '',
        title11: titleEleventh ? titleEleventh : '',
        title12: titleTwelfth ? titleTwelfth : '',
        title13: titleThirteenth ? titleThirteenth : '',
        title14: titleFourteenth ? titleFourteenth : '',
        title15: titleFifteenth ? titleFifteenth : '',
      }).then(() => {
        setIsLoading(false);
        toast.dismiss();
        navigate(`/unit-planner/${unitID}`);
      });
    }
  }, [unitUpdated]);

  const notify = () =>
    toast('🦄  Now sit back and relax!', {
      position: 'top-right',
      autoClose: unitLength * 4 * 1100,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
      pauseOnFocusLoss: false,
    });

  return (
    <div className="unitpage">
      <form onSubmit={onSubmit}>
        <button className="btn btn-block create" type="submit" disabled={isLoading}>
          {!isLoading && <>Create Unit 🚀</>}
          {isLoading && <RiseLoader color={'white'} loading={isLoading} size={7} />}
        </button>
        {/* {isLoading && <div className="countdown">{countdown()}</div>} */}
      </form>
    </div>
  );
};

export default UnitPlanner;
