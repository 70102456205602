import { useDispatch, useSelector } from 'react-redux';

import { updateDocumentsCount } from '../store/reducers/userDocumentsCountSlice';

export function useUpdateDocumentsCount() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userDetailsSlice);

  return () => {
    if (userInfo && !userInfo?.isHaveSub) {
      dispatch(updateDocumentsCount(userInfo.userUid));
    }
  };
}
