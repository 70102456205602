import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import links from '../utils/links';

const NavLinks = ({ toggleSidebar }) => {
  const { userInfo } = useSelector((state) => state.userDetailsSlice);

  return (
    <div className="nav-links">
      {links.map((link) => {
        const { text, path, id, icon, isSubscriptionRequired } = link;

        return (
          ((isSubscriptionRequired && isSubscriptionRequired === userInfo?.isHaveSub) || !isSubscriptionRequired) && (
            <NavLink
              to={path}
              key={id}
              onClick={toggleSidebar}
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            >
              <span className="icon">{icon}</span>
              {text}
            </NavLink>
          )
        );
      })}
    </div>
  );
};

export default NavLinks;
