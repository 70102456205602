export const DISPLAY_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN';
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS';
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const LOGOUT_USER = 'LOGOUT_USER';

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const HANDLE_CHANGE = 'HANDLE_CHANGE';
export const CLEAR_VALUES = 'CLEAR_VALUES';

export const NEW_COMPLETION_BEGIN = 'NEW_COMPLETION_BEGIN';
export const NEW_COMPLETION_SUCCESS = 'NEW_COMPLETION_SUCCESS';
export const NEW_COMPLETION_ERROR = 'NEW_COMPLETION_ERROR';

export const CREATE_STUDENT_BEGIN = 'CREATE_STUDENT_BEGIN';
export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS';
export const CREATE_STUDENT_ERROR = 'CREATE_STUDENT_ERROR';

export const GET_STUDENTS_BEGIN = 'GET_STUDENTS_BEGIN';
export const GET_STUDENTS_SUCCESS = 'GET_STUDENTS_SUCCESS';

export const SET_EDIT_STUDENT = 'SET_EDIT_STUDENT';

export const DELETE_STUDENT_BEGIN = 'DELETE_STUDENT_BEGIN';

export const EDIT_STUDENT_BEGIN = 'EDIT_STUDENT_BEGIN';
export const EDIT_STUDENT_SUCCESS = 'EDIT_STUDENT_SUCCESS';
export const EDIT_STUDENT_ERROR = 'EDIT_STUDENT_ERROR';

export const SHOW_STATS_BEGIN = 'SHOW_STATS_BEGIN';
export const SHOW_STATS_SUCCESS = 'SHOW_STATS_SUCCESS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CHANGE_PAGE = 'CHANGE_PAGE';

export const SET_USER = 'SET_USER';
export const SET_DOCUMENTS_CREATED = 'SET_DOCUMENTS_CREATED';
