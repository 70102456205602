import { PersonalInfo } from '../PersonalInfo';
import { Plans } from '../Plans';
import { Security } from '../Security';
import { TabPanel } from './styles';

export const TabPanels = ({ value }) => {
  return (
    <>
      <TabPanel value={value} index={0} $maxWidth="100%">
        <PersonalInfo />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Security />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Plans />
      </TabPanel>
    </>
  );
};
