import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import * as React from 'react';
import { ImFolder } from 'react-icons/im';

import pdfIcon from '../assets/svg/noun-pdf.svg';
import pptIcon from '../assets/svg/noun-ppt.svg';
import './FolderCard.css';

// const actionButtons = (
//   <div className="action-buttons">
//     <div className="action-button" onClick={() => console.log('Downloading file...')}>
//       Download
//     </div>
//     <div className="action-button" onClick={() => console.log('Deleting file...')}>
//       Delete
//     </div>
//   </div>
// );

const FileCard = ({ toolName, type }) => {
  return (
    <Card className="folder-card">
      <CardContent>
        <div className="folder-card-content">
          <div className="icon-type">
            {type === 'ppt' && <img src={pptIcon} alt="bed" className="pdfIcon" />}
            {type === 'pdf' && <img src={pdfIcon} alt="bed" className="pdfIcon" />}
            {type === 'folder' && <ImFolder className="folder-icon" />}
          </div>
          <div className="folder-card-title">{toolName}</div>
          <div className="subtext">click to download</div>
        </div>
      </CardContent>
    </Card>
  );
};

export default FileCard;
