import { contextBuilderValidator } from './contextBuilderValidator';
import { educationalHandoutValidator } from './educationalHandoutValidator';
import { lessonPlanValidator } from './lessonPlanValidator';

export const pdfValidators = (api, textResult, inputValues) => {
  switch (api) {
    case 'lessonPlannerV2Completion':
      return lessonPlanValidator(textResult, inputValues);
    case 'educationalHandoutCompletion':
      return educationalHandoutValidator(textResult, inputValues);
    case 'vocabCompletion':
      return contextBuilderValidator(textResult, inputValues);
  }
};
