import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { collection, getDocs, query, where } from 'firebase/firestore';

import { db } from '../../firebase.config';

export const getPlans = createAsyncThunk('plansSlice/getPlans', async () => {
  const dbQuery = query(collection(db, 'products'), where('active', '==', true), where('role', '==', 'premium_1'));
  const querySnapshot = await getDocs(dbQuery);

  const pricePromises = querySnapshot.docs.map(async (doc) => {
    const priceSnap = await getDocs(collection(doc.ref, 'prices'));

    return priceSnap.docs.reduce((accumulator, priceDoc) => {
      if (priceDoc.data().active) {
        accumulator.push({ ...priceDoc.data(), id: priceDoc.id });
      }
      return accumulator;
    }, []);
  });

  return await Promise.all(pricePromises);
});

const plansSlice = createSlice({
  name: 'plansSlice',
  initialState: {
    plans: null,
    update: {
      status: 'idle',
      error: null,
    },
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getPlans.pending]: (state) => {
      state.status = 'loading';
    },
    [getPlans.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.plans = action.payload[0];
    },
    [getPlans.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export default plansSlice.reducer;
