import { pdf } from '@react-pdf/renderer';

import PDF from '../../custom/EducationalPDF';

export const educationalHandoutValidator = async (textResult, inputValues) => {
  const pdfData = {};
  // set the title to everything between <title> and </title>
  const regexTitle = /<title>(.*?)<\/title>/s;
  const matchFirst = textResult.match(regexTitle);
  pdfData.title = matchFirst[1];

  // Set Covers
  const regexHandoutcovers = /<handoutcovers>(.*?)<\/handoutcovers>/s;
  const matchFirstExample = textResult.match(regexHandoutcovers);
  pdfData.handoutcovers = matchFirstExample[1];

  // Set First Header
  const regexHeader1 = /<header1>(.*?)<\/header1>/s;
  const matchFirstHeader = textResult.match(regexHeader1);
  pdfData.header1 = matchFirstHeader[1];

  // Set Body1
  const regexBody1 = /<body1>(.*?)<\/body1>/s;
  const matchFirstBody = textResult.match(regexBody1);
  pdfData.body1 = matchFirstBody[1];

  // Set Header2
  const regexHeader2 = /<header2>(.*?)<\/header2>/s;
  const matchSecondHeader = textResult.match(regexHeader2);
  pdfData.header2 = matchSecondHeader[1];

  // Set Body2
  const regexBody2 = /<body2>(.*?)<\/body2>/s;
  const matchSecondBody = textResult.match(regexBody2);
  pdfData.body2 = matchSecondBody[1];

  // Set Header3
  const regexHeader3 = /<header3>(.*?)<\/header3>/s;
  const matchThirdHeader = textResult.match(regexHeader3);
  pdfData.header3 = matchThirdHeader[1];

  // Set Body3
  const regexBody3 = /<body3>(.*?)<\/body3>/s;
  const matchThirdBody = textResult.match(regexBody3);
  pdfData.body3 = matchThirdBody[1];

  // Set Header4
  const regexHeader4 = /<header4>(.*?)<\/header4>/s;
  const matchFourthHeader = textResult.match(regexHeader4);
  pdfData.header4 = matchFourthHeader[1];

  // Set Body4
  const regexBody4 = /<body4>(.*?)<\/body4>/s;
  const matchFourthBody = textResult.match(regexBody4);
  pdfData.body4 = matchFourthBody[1];

  // Set Header5
  const regexHeader5 = /<header5>(.*?)<\/header5>/s;
  const matchFifthHeader = textResult.match(regexHeader5);
  if (matchFifthHeader) {
    pdfData.header5 = matchFifthHeader[1];
  }

  // Set Body5 Conditionally if it exists, it might not
  const regexBody5 = /<body5>(.*?)<\/body5>/s;
  const matchFifthBody = textResult.match(regexBody5);
  if (matchFifthBody) {
    pdfData.body5 = matchFifthBody[1];
  }

  // Set Header 6 Conditionally if it exists, it might not
  const regexHeader6 = /<header6>(.*?)<\/header6>/s;
  const matchSixthHeader = textResult.match(regexHeader6);
  if (matchSixthHeader) {
    pdfData.header6 = matchSixthHeader[1];
  }

  // Set Body 6 Conditionally if it exists, it might not
  const regexBody6 = /<body6>(.*?)<\/body6>/s;
  const matchSixthBody = textResult.match(regexBody6);
  if (matchSixthBody) {
    pdfData.body6 = matchSixthBody[1];
  }

  // Set Header 7 Conditionally if it exists, it might not
  const regexHeader7 = /<header7>(.*?)<\/header7>/s;
  const matchSeventhHeader = textResult.match(regexHeader7);
  if (matchSeventhHeader) {
    pdfData.header7 = matchSeventhHeader[1];
  }

  // Set Body 7 Conditionally if it exists, it might not
  const regexBody7 = /<body7>(.*?)<\/body7>/s;
  const matchSeventhBody = textResult.match(regexBody7);
  if (matchSeventhBody) {
    pdfData.body7 = matchSeventhBody[1];
  }

  // Set Header 8 Conditionally if it exists, it might not
  const regexHeader8 = /<header8>(.*?)<\/header8>/s;
  const matchEighthHeader = textResult.match(regexHeader8);
  if (matchEighthHeader) {
    pdfData.header8 = matchEighthHeader[1];
  }

  // Set Body 8 Conditionally if it exists, it might not
  const regexBody8 = /<body8>(.*?)<\/body8>/s;
  const matchEighthBody = textResult.match(regexBody8);
  if (matchEighthBody) {
    pdfData.body8 = matchEighthBody[1];
  }

  const blob = await pdf(
    <PDF
      subject={inputValues.subject}
      gradeLevel={inputValues.gradeLevel}
      title={pdfData.title}
      covers={pdfData.handoutcovers}
      header1={pdfData.header1}
      body1={pdfData.body1}
      header2={pdfData.header2}
      body2={pdfData.body2}
      header3={pdfData.header3}
      body3={pdfData.body3}
      header4={pdfData.header4}
      body4={pdfData.body4}
      header5={pdfData.header5}
      body5={pdfData.body5}
      header6={pdfData.header6}
      body6={pdfData.body6}
      header7={pdfData.header7}
      body7={pdfData.body7}
      header8={pdfData.header8}
      body8={pdfData.body8}
    />
  ).toBlob();

  return blob;
};
