import React from 'react';

import { Box } from './styles';

export const Footer = () => {
  return (
    <footer>
      <Box>
        <a target="_blank" rel="noopener noreferrer" href="https://educationcopilot.com/privacy-policy/">
          Privacy Policy
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://educationcopilot.com/terms-of-service/">
          Terms of Service
        </a>
      </Box>
    </footer>
  );
};
