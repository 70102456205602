import { TextField } from '@mui/material';

import { ECTypography } from '../ECTypography';
import { FormLabel, InputAdornment } from './styles';

export const ECInput = ({
  label,
  value,
  onChange,
  name,
  icon,
  type = 'text',
  required,
  iconPosition = 'start',
  onIconClick,
  readOnly,
  errorText,
  isError,
  placeholder,
  multiline,
  rows,
  maxLength,
}) => {
  const onAdornmentClick = (e) => {
    e.preventDefault();
    onIconClick(e);
  };

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <FormLabel $label={label}>
      <div className="text">
        {label}
        {maxLength && (
          <ECTypography
            text={`${value?.length || 0}/${maxLength}`}
            variant="bodyS"
            className="text-length"
            color="#A8B0B9"
          />
        )}
      </div>
      <TextField
        value={value}
        required={required}
        variant="outlined"
        name={name}
        onChange={handleChange}
        type={type}
        error={isError}
        helperText={isError && errorText}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
        inputProps={{ maxLength }}
        InputProps={{
          [`${iconPosition}Adornment`]: icon && (
            <InputAdornment
              position={iconPosition}
              onClick={onIconClick && onAdornmentClick}
              $isPointer={!!onIconClick}
            >
              {icon}
            </InputAdornment>
          ),
          readOnly,
        }}
      />
    </FormLabel>
  );
};
