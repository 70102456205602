import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { browserLocalPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';

export const login = createAsyncThunk('authSlice/login', async ({ email, password }, { rejectWithValue }) => {
  try {
    const auth = getAuth();
    await setPersistence(auth, browserLocalPersistence);
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    localStorage.setItem('isLoggedIn', 'true');

    return user;
  } catch (err) {
    return rejectWithValue(err.code);
  }
});

export const logout = createAsyncThunk('authSlice/logout', async (_, { rejectWithValue }) => {
  try {
    const auth = getAuth();
    await setPersistence(auth, browserLocalPersistence);

    localStorage.removeItem('isLoggedIn');
    await auth.signOut();
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    user: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: {
    [login.pending]: (state) => {
      state.status = 'loading';
    },
    [login.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.user = action.payload.user;
      state.error = null;
    },
    [login.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [logout.pending]: (state) => {
      state.status = 'loading';
    },
    [logout.fulfilled]: (state) => {
      state.status = 'idle';
      state.user = null;
    },
  },
});

export default authSlice.reducer;
