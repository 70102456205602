import { getAuth } from '@firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import PPTXGenJS from 'pptxgenjs';

export const powerpointGenerator = async (textResult, inputValues) => {
  let pptx = new PPTXGenJS();
  const pptxData = {};
  const regexHeader1 = /<header1>(.*?)<header1>/s;
  const matchHeader1 = regexHeader1.exec(textResult);
  if (matchHeader1) {
    pptxData.header1 = matchHeader1[1];
  }

  // Body 1
  const regexBody1 = /<body1>(.*?)<body1>/s;
  const matchBody1 = regexBody1.exec(textResult);
  if (matchBody1) {
    pptxData.body1 = matchBody1[1];
  }

  // Header 2
  const regexHeader2 = /<header2>(.*?)<header2>/s;
  const matchHeader2 = regexHeader2.exec(textResult);
  if (matchHeader2) {
    pptxData.header2 = matchHeader2[1];
  }

  // Body 2
  const regexBody2 = /<body2>(.*?)<body2>/s;
  const matchBody2 = regexBody2.exec(textResult);
  if (matchBody2) {
    pptxData.body2 = matchBody2[1];
  }

  // Header 3
  const regexHeader3 = /<header3>(.*?)<header3>/s;
  const matchHeader3 = regexHeader3.exec(textResult);
  if (matchHeader3) {
    pptxData.header3 = matchHeader3[1];
  }

  // Body 3
  const regexBody3 = /<body3>(.*?)<body3>/s;
  const matchBody3 = regexBody3.exec(textResult);
  if (matchBody3) {
    pptxData.body3 = matchBody3[1];
  }

  // Header 4
  const regexHeader4 = /<header4>(.*?)<header4>/s;
  const matchHeader4 = regexHeader4.exec(textResult);
  if (matchHeader4) {
    pptxData.header4 = matchHeader4[1];
  }

  // Body 4
  const regexBody4 = /<body4>(.*?)<body4>/s;
  const matchBody4 = regexBody4.exec(textResult);
  if (matchBody4) {
    pptxData.body4 = matchBody4[1];
  }

  // Header 5
  const regexHeader5 = /<header5>(.*?)<header5>/s;
  const matchHeader5 = regexHeader5.exec(textResult);
  if (matchHeader5) {
    pptxData.header5 = matchHeader5[1];
  }

  // Body 5
  const regexBody5 = /<body5>(.*?)<body5>/s;
  const matchBody5 = regexBody5.exec(textResult);
  if (matchBody5) {
    pptxData.body5 = matchBody5[1];
  }

  // Header 6
  const regexHeader6 = /<header6>(.*?)<header6>/s;
  const matchHeader6 = regexHeader6.exec(textResult);
  if (matchHeader6) {
    pptxData.header6 = matchHeader6[1];
  }

  // Body 6
  const regexBody6 = /<body6>(.*?)<body6>/s;
  const matchBody6 = regexBody6.exec(textResult);
  if (matchBody6) {
    pptxData.body6 = matchBody6[1];
  }

  // Header 7
  const regexHeader7 = /<header7>(.*?)<header7>/s;
  const matchHeader7 = regexHeader7.exec(textResult);
  if (matchHeader7) {
    pptxData.header7 = matchHeader7[1];
  }

  // Body 7
  const regexBody7 = /<body7>(.*?)<body7>/s;
  const matchBody7 = regexBody7.exec(textResult);
  if (matchBody7) {
    pptxData.body7 = matchBody7[1];
  }

  // Header 8
  const regexHeader8 = /<header8>(.*?)<header8>/s;
  const matchHeader8 = regexHeader8.exec(textResult);
  if (matchHeader8) {
    pptxData.header8 = matchHeader8[1];
  }

  // Body 8
  const regexBody8 = /<body8>(.*?)<body8>/s;
  const matchBody8 = regexBody8.exec(textResult);
  if (matchBody8) {
    pptxData.body8 = matchBody8[1];
  }

  // Header 9
  const regexHeader9 = /<header9>(.*?)<header9>/s;
  const matchHeader9 = regexHeader9.exec(textResult);
  if (matchHeader9) {
    pptxData.header9 = matchHeader9[1];
  }

  // Body 9
  const regexBody9 = /<body9>(.*?)<body9>/s;
  const matchBody9 = regexBody9.exec(textResult);
  if (matchBody9) {
    pptxData.body9 = matchBody9[1];
  }

  // Header 10
  const regexHeader10 = /<header10>(.*?)<header10>/s;
  const matchHeader10 = regexHeader10.exec(textResult);
  if (matchHeader10) {
    pptxData.header10 = matchHeader10[1];
  }

  // Body 10
  const regexBody10 = /<body10>(.*?)<body10>/s;
  const matchBody10 = regexBody10.exec(textResult);
  if (matchBody10) {
    pptxData.body10 = matchBody10[1];
  }

  // Header 11
  const regexHeader11 = /<header11>(.*?)<header11>/s;
  const matchHeader11 = regexHeader11.exec(textResult);
  if (matchHeader11) {
    pptxData.header11 = matchHeader11[1];
  }

  // Body 11
  const regexBody11 = /<body11>(.*?)<body11>/s;
  const matchBody11 = regexBody11.exec(textResult);
  if (matchBody11) {
    pptxData.body11 = matchBody11[1];
  }

  // Header 12
  const regexHeader12 = /<header12>(.*?)<header12>/s;
  const matchHeader12 = regexHeader12.exec(textResult);
  if (matchHeader12) {
    pptxData.header12 = matchHeader12[1];
  }

  // Body 12
  const regexBody12 = /<body12>(.*?)<body12>/s;
  const matchBody12 = regexBody12.exec(textResult);
  if (matchBody12) {
    pptxData.body12 = matchBody12[1];
  }

  // Header 13
  const regexHeader13 = /<header13>(.*?)<header13>/s;
  const matchHeader13 = regexHeader13.exec(textResult);
  if (matchHeader13) {
    pptxData.header13 = matchHeader13[1];
  }

  // Body 13
  const regexBody13 = /<body13>(.*?)<body13>/s;
  const matchBody13 = regexBody13.exec(textResult);
  if (matchBody13) {
    pptxData.body13 = matchBody13[1];
  }

  // Header 14
  const regexHeader14 = /<header14>(.*?)<header14>/s;
  const matchHeader14 = regexHeader14.exec(textResult);
  if (matchHeader14) {
    pptxData.header14 = matchHeader14[1];
  }

  // Body 14
  const regexBody14 = /<body14>(.*?)<body14>/s;
  const matchBody14 = regexBody14.exec(textResult);
  if (matchBody14) {
    pptxData.body14 = matchBody14[1];
  }

  // Header 15
  const regexHeader15 = /<header15>(.*?)<header15>/s;
  const matchHeader15 = regexHeader15.exec(textResult);
  if (matchHeader15) {
    pptxData.header15 = matchHeader15[1];
  }

  // Body 15
  const regexBody15 = /<body15>(.*?)<body15>/s;
  const matchBody15 = regexBody15.exec(textResult);
  if (matchBody15) {
    pptxData.body15 = matchBody15[1];
  }

  const generatePowerpoint = async (pptxData) => {
    // Create slide 1 Header

    if (pptxData.header1 !== '') {
      let slide1 = pptx.addSlide();
      slide1.background = { color: '4285f4' };
      slide1.addText(`${pptxData.header1}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 1 Body
      if (pptxData.body1 !== '') {
        slide1.addText(`${pptxData.body1}`, {
          x: 0.5,
          y: 1.5,
          w: 9,
          h: 2,
          color: 'FFFFFF',
          fontSize: 20,
          align: 'left',
        });
      }
    }

    if (pptxData.header2 !== '') {
      // Create Slide 2 Header
      let slide2 = pptx.addSlide();
      slide2.background = { color: '4285f4' };
      slide2.addText(`${pptxData.header2}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 2 Body
      slide2.addText(`${pptxData.body2}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header3 !== '') {
      // Create Slide 3 Header
      let slide3 = pptx.addSlide();
      slide3.background = { color: '4285f4' };
      slide3.addText(`${pptxData.header3}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 3 Body
      slide3.addText(`${pptxData.body3}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header4 !== '') {
      // Create slide 4 Header
      let slide4 = pptx.addSlide();
      slide4.background = { color: '4285f4' };
      slide4.addText(`${pptxData.header4}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 4 Body
      slide4.addText(`${pptxData.body4}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header5 !== '') {
      // Create slide 5 Header
      let slide5 = pptx.addSlide();
      slide5.background = { color: '4285f4' };
      slide5.addText(`${pptxData.header5}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 5 Body
      slide5.addText(`${pptxData.body5}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header6 !== '') {
      // Create slide 6 Header
      let slide6 = pptx.addSlide();
      slide6.background = { color: '4285f4' };
      slide6.addText(`${pptxData.header6}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 6 Body
      slide6.addText(`${pptxData.body6}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header7 !== '') {
      // Create slide 7 Header
      let slide7 = pptx.addSlide();
      slide7.background = { color: '4285f4' };
      slide7.addText(`${pptxData.header7}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 7 Body
      slide7.addText(`${pptxData.body7}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header8 !== '') {
      // Create Slide 8 Header
      let slide8 = pptx.addSlide();
      slide8.background = { color: '4285f4' };
      slide8.addText(`${pptxData.header8}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 8 Body
      slide8.addText(`${pptxData.body8}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header9 !== '') {
      // Create slide 9 Header
      let slide9 = pptx.addSlide();
      slide9.background = { color: '4285f4' };
      slide9.addText(`${pptxData.header9}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 9 Body
      slide9.addText(`${pptxData.body9}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header10 !== '') {
      // Create slide 10 Header
      let slide10 = pptx.addSlide();
      slide10.background = { color: '4285f4' };
      slide10.addText(`${pptxData.header10}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 10 Body
      slide10.addText(`${pptxData.body10}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header11 !== '') {
      // Create slide 11 Header
      let slide11 = pptx.addSlide();
      slide11.background = { color: '4285f4' };
      slide11.addText(`${pptxData.header11}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 11 Body
      slide11.addText(`${pptxData.body11}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header12 !== '') {
      // Create slide 12 Header
      let slide12 = pptx.addSlide();
      slide12.background = { color: '4285f4' };
      slide12.addText(`${pptxData.header12}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 12 Body
      slide12.addText(`${pptxData.body12}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header13 !== '') {
      // Create Slide 13 Header
      let slide13 = pptx.addSlide();
      slide13.background = { color: '4285f4' };
      slide13.addText(`${pptxData.header13}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 13 Body
      slide13.addText(`${pptxData.body13}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header14 !== '') {
      // Create slide 14 Header
      let slide14 = pptx.addSlide();
      slide14.background = { color: '4285f4' };
      slide14.addText(`${pptxData.header14}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 14 Body
      slide14.addText(`${pptxData.body14}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    if (pptxData.header15 !== '') {
      // Create slide 15 Header
      let slide15 = pptx.addSlide();
      slide15.background = { color: '4285f4' };
      slide15.addText(`${pptxData.header15}`, {
        w: 9,
        h: 1,
        x: 0.5,
        y: 0.25,
        fontSize: 40,
        color: 'FFFFFF',
      });

      // Create Slide 15 Body
      slide15.addText(`${pptxData.body15}`, {
        x: 0.5,
        y: 1.5,
        w: 9,
        h: 2,
        color: 'FFFFFF',
        fontSize: 20,
        align: 'left',
      });
    }

    pptx.writeFile({ fileName: `${inputValues.subject}` }).then(() => {});

    // Get the current user and the storage service and the user id
    const auth = getAuth();
    const user = auth.currentUser;
    const storage = getStorage();
    const userId = user.uid;

    // Creates a reference to the file we want to upload and where we want to upload it
    const storageRef = ref(storage, `users/${userId}/powerpoints/${inputValues.subject} PPTX${Date.now()}.pptx`);

    const powerpoint = pptx.write('blob');

    const blobData = new Blob([powerpoint], {
      type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    });

    await uploadBytes(storageRef, blobData).then(() => {
      console.log('Uploaded a blob or file!');
    });

    const url = await getDownloadURL(storageRef);
    const name = storageRef.name;
    const dataDetails = { url, name };
    return dataDetails;
  };

  return await generatePowerpoint(pptxData);
};
