import './passwordRequirements.css';

export const PasswordRequirements = ({ isError }) => {
  return (
    <div className={`password-hint ${isError && 'error'}`}>
      Passwords should contain at least 8 characters,
      <br /> 1 special character, 1 capital letter and 1 number
    </div>
  );
};
