import { getAuth } from '@firebase/auth';
import { Card, CardContent } from '@mui/material';
import axios from 'axios';
import { addDoc, collection } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { decode } from 'html-entities';
import { useEffect, useState } from 'react';
import { ImArrowLeft2, ImDownload, ImHistory } from 'react-icons/im';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import RiseLoader from 'react-spinners/RiseLoader';
import { toast } from 'react-toastify';

import FileCard from '../../../components/FileCard';

import printIcon from '../../../assets/svg/noun-print.svg';
import Wrapper from '../../../assets/wrappers/InputForm';
import { FormRow } from '../../../components';
import { BUILDER_TOOLS, DOCUMENT_LIMIT } from '../../../constants/constants';
import { db } from '../../../firebase.config';
import { useUpdateDocumentsCount } from '../../../hooks/useUpdateDocumentsCount';
import '../custom/ModalStyling.css';
import '../custom/PDFCSS.css';
import PdfViewer from '../pdf-viewer';
import './BuilderLayout.css';
import { pdfValidators } from './pdf-validators/pdfValidators';
import { powerpointGenerator } from './powerpoint-generator/powerpointGenerator';

export const BuilderLayout = (props) => {
  const { toolName } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tool, setTool] = useState({});
  const [inputRows, setInputRows] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [pdfJSX, setPdfJSX] = useState(null);
  const [pdfConfig, setPdfConfig] = useState({});
  const [pdfBlob, setPdfBlob] = useState(null);
  const [recipeCheckBoxes, setRecipeCheckboxes] = useState([]);
  const [recipeResults, setRecipeResults] = useState([]);
  const [creationCards, setCreationCards] = useState([]);
  const { userInfo } = useSelector((state) => state.userDetailsSlice);
  const { documentsCount } = useSelector((state) => state.userDocumentsCountSlice);
  const navigate = useNavigate();

  const notify = () =>
    toast(tool.message, {
      position: 'top-right',
      autoClose: 30000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      pauseOnFocusLoss: false,
      theme: 'light',
    });

  const updateCount = useUpdateDocumentsCount();

  useEffect(() => {
    const selectedTool = BUILDER_TOOLS.find((tool) => tool.key === toolName);

    if (!selectedTool) {
      navigate('/workshop');
      return;
    }

    setTool(selectedTool);
    setInputRows(selectedTool.inputs);
    setInputValues(
      selectedTool.inputs.reduce((acc, input) => {
        return { ...acc, [input.name]: '' };
      }, {})
    );
  }, [toolName, navigate]);

  async function saveCompletionToDB(collectionName, data) {
    const auth = getAuth();
    const user = auth.currentUser;
    data = {
      ...data,
      userId: user.uid,
      timestamp: Date.now(),
    };

    return await addDoc(collection(db, collectionName), data);
  }

  const createPdf = async (textResult, api, toolName) => {
    const createdPdf = await pdfValidators(api, textResult, inputValues);
    setPdfBlob(createdPdf);
    const auth = getAuth();
    const user = auth.currentUser;
    const storage = getStorage();
    const userId = user.uid;

    const storageRef = ref(
      storage,
      `users/${userId}/${tool.historyLink}/${inputValues.subject} ${toolName} ${Date.now()}.pdf`
    );

    await uploadBytes(storageRef, createdPdf);

    const url = await getDownloadURL(storageRef);
    const name = storageRef.name;
    setPdfConfig((prevPdfConfig) => ({ ...prevPdfConfig, pdfUrl: url }));
    return { url, name };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userInfo.isHaveSub && documentsCount >= DOCUMENT_LIMIT) {
      navigate('/pricing');
      return;
    }

    if (tool.key === 'recipe-builder') {
      const activeCheckBoxes = [];
      Object.keys(recipeCheckBoxes).forEach((key) => {
        if (recipeCheckBoxes[key] === true) {
          activeCheckBoxes.push(key);
        }
      });
      if (activeCheckBoxes.length === 0) return;
      setIsLoading(true);
      setRecipeResults([]);
      const fetchAndDecode = async (inputValues, api) => {
        const response = await fetchApi(inputValues, api);
        if (api === 'powerPointCompletion') {
          const data = await powerpointGenerator(response, inputValues);
          return { api, data };
        }
        const name = BUILDER_TOOLS.find((tool) => tool.api === api).name;
        const data = await createPdf(response, api, name);
        return { api, data };
      };
      const promises = activeCheckBoxes.map((api) => fetchAndDecode(inputValues, api));
      const response = await Promise.all(promises);
      setIsLoading(false);
      setRecipeResults(response);
      return;
    }
    setIsLoading(true);
    notify();
    let textResult = decode(await fetchApi(inputValues, tool.api));
    setIsLoading(false);
    const subject = inputValues.subject;
    const gradeLevel = inputValues.gradeLevel;

    const dataToSave = {
      subject,
      gradeLevel,
      application: tool.name,
      generatedText: textResult,
    };

    saveCompletionToDB('completions', dataToSave)
      .then(() => {
        if (!userInfo.isHaveSub) {
          updateCount();
        }
      })
      .catch((err) => console.log(err));
    if (tool.key === 'powerpoints') {
      powerpointGenerator(textResult, inputValues);
      return;
    }
    createPdf(textResult, tool.api, tool.name);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfConfig((prevPdfConfig) => ({ ...prevPdfConfig, numPages }));
  };

  const fetchApi = async (inputValues, api) => {
    try {
      const response = await axios.post(`${window.location.origin}/api/v1/completions/${api}`, inputValues);
      return response.data.choices[0].text;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (pdfConfig.pdfUrl !== '') {
      setPdfJSX(
        <div className="userActions">
          <div className="printButton">
            <img src={printIcon} className="printIcon" onClick={handlePrint} alt="Print" />
          </div>
          <div className="downloadbutton">
            <button type="button" disabled={pdfConfig.pdfUrl === ''} onClick={handleDownload}>
              Download
            </button>
          </div>
        </div>
      );
    }
  }, [pdfConfig.pdfUrl]);

  const handlePrint = () => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(pdfBlob);
    a.target = '_blank';
    a.click();
  };

  const handleDownload = () => {
    const fileName = `${inputValues.subject} ${tool.name}${Date.now()}.pdf`;
    const a = document.createElement('a');
    a.href = URL.createObjectURL(pdfBlob);
    a.download = fileName;
    a.click();
  };

  useEffect(() => {
    if (recipeResults.length > 0) {
      setCreationCards(
        recipeResults.map((result, i) => (
          <div className="generatedfilecard" key={i}>
            <a href={`${result.data.url}`} target="_blank" rel="noreferrer">
              <FileCard
                key={result.api}
                type={result.data.name.includes('pptx') ? 'ppt' : 'pdf'}
                toolName={result.data.name}
                lastName={
                  <Document file={result.data.url}>
                    <Page pageNumber={1} scale={0.3} />
                  </Document>
                }
              />
            </a>
          </div>
        ))
      );
    }
  }, [recipeResults]);

  return (
    <Wrapper>
      <Card
        sx={{
          backgroundColor: '#f8fafc',
          width: '100%',
          maxWidth: '100%',
          border: 'none',
          borderRadius: '0px',
          paddingTop: '.7rem',
          height: '100%',
          '&:hover': {
            boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.25)',
          },
          boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.2)',
        }}
        className="input-card"
      >
        <CardContent>
          <div className="historylink">
            <Link to={'../workshop'}>{<ImArrowLeft2 className="historyicon" />}Workshop</Link>
          </div>
          <form className="aiForm" onSubmit={handleSubmit}>
            <div className="form-center">
              <div className="titleAndVideo">
                <h4 className="pageTitle">{tool.name}</h4>
              </div>
              {inputRows.map((inputRow) => (
                <FormRow
                  key={inputRow.name}
                  type="text"
                  labelText={inputRow.label}
                  name={inputRow.name}
                  value={inputValues[inputRow.name]}
                  handleChange={(e) =>
                    setInputValues({
                      ...inputValues,
                      [inputRow.name]: e.target.value,
                    })
                  }
                />
              ))}
              <button className="btn btn-block" type="submit" disabled={isLoading}>
                {isLoading ? <RiseLoader color={'white'} loading={isLoading} size={7} /> : tool.button}
              </button>
            </div>
          </form>
          <div className="bodyText">
            <h5 className="subHeadline">{tool.descriptionTitle}</h5>
            {tool.description ? tool.description.map((description, i) => <p key={i}>{description}</p>) : null}
          </div>
          {tool.key === 'recipe-builder' && (
            <div className="form-center">
              <div className="checkbox-group">
                {BUILDER_TOOLS.map((tool) =>
                  tool.key !== 'recipe-builder' ? (
                    <div key={tool.key} className="check">
                      <input
                        disabled={isLoading}
                        key={tool.api}
                        type="checkbox"
                        name={tool.name}
                        value={recipeCheckBoxes[tool.api]}
                        onChange={(e) =>
                          setRecipeCheckboxes((prev) => ({
                            ...prev,
                            [tool.api]: e.target.checked,
                          }))
                        }
                      />
                      <label>{tool.name}</label>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          )}
        </CardContent>
      </Card>
      {tool.key === 'powerpoints' && (
        <div className="downloadInfo">
          {<ImDownload className="downloadicon" />}
          Your PowerPoint will automatically download once it is complete!
        </div>
      )}
      {tool.key !== 'powerpoints' && tool.key !== 'recipe-builder' && (
        <Card
          className="pdfCard"
          sx={{
            borderRadius: '0px',
            backgroundColor: '#f8f8f8',
          }}
        >
          <div className="pageControls">
            <div className="historylink">
              <a href={`${window.location.origin}/creations/${tool.historyLink}`} target="_blank" rel="noreferrer">
                {<ImHistory className="historyicon" />} History
              </a>
            </div>
            <div>{pdfJSX}</div>
          </div>
          <div className="pdfContainer">
            <PdfViewer
              fileUrl={pdfConfig.pdfUrl}
              numPages={pdfConfig.numPages}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
            />
          </div>
        </Card>
      )}
      {tool.key === 'recipe-builder' && (
        <Card
          className="listview"
          sx={{
            borderRadius: '0px',
          }}
        >
          <div className="labels">
            <div className="namelabel">File Name</div>
            <div className="actionslabel">Actions</div>
          </div>
          {creationCards}
        </Card>
      )}
    </Wrapper>
  );
};
