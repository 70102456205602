import styled from 'styled-components';

export const SHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 20px 36px;
  background: var(--white);
  box-shadow: 0px -1px 0px 0px #f1f1f1 inset;
  justify-content: space-between;

  .title {
    font-size: 24px;
    color: var(--grey-800);
    font-family: DMSansBold, sans-serif;
    margin: 0;
  }

  @media screen and (max-width: 750px) {
    box-shadow: none;
    padding: 24px 24px 0;
  }
`;
