import { Box as BoxMui } from '@mui/material';
import styled from 'styled-components';

export const Box = styled(BoxMui)`
  display: flex;
  gap: 24px;
  align-items: center;

  a {
    color: var(--grey-500);
    font-family: DMSansRegular, sans-serif;
    font-size: 14px;
  }
`;
