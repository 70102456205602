import styled from 'styled-components';

export const WrapperDiv = styled.div`
  & {
    .switchWrapper {
      position: relative;
      background-color: var(--white);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      cursor: pointer;
      border: 2px solid var(--primary-violet-500);
      width: 52px;
      height: 32px;

      input {
        display: none;
      }
    }

    .thumbWrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .switchThumb {
      border-radius: 50%;
      box-shadow: none;
      border: none;
      position: absolute;
      background-color: white;
      z-index: 2;
      transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      left: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .switchThumbUnchecked {
      background-color: var(--primary-violet-300);
      height: 16px !important;
      width: 16px !important;
    }

    .switchChecked {
      background-color: var(--primary-violet-500);
    }

    .disabledLabel {
      color: gray;
    }

    .disabledSwitch {
      opacity: 0.5;
      cursor: default;
    }

    .MuiSvgIcon-root {
      font-size: 16px;
      color: var(--grey-800);
    }
  }
`;
