import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ECButton } from '../../../components/ECButton';
import { ECInput } from '../../../components/ECInput';
import { ECLink } from '../../../components/ECLink';
import { ECTypography } from '../../../components/ECTypography';
import OAuth from '../../../components/OAuth';

import { Logo } from '../../../components';
import { login } from '../../../store/reducers/authSlice';
import { errorToast } from '../../../utils/errorToast';
import './signIn.css';

const ERROR_CODES = {
  'auth/user-not-found': 'User not found',
  'auth/wrong-password': 'Bad User Credentials',
};

export const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { user, error, status } = useSelector((state) => state.authSlice);

  const { email, password } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    dispatch(login(formData));
  };

  useEffect(() => {
    if (error !== null && status === 'failed') {
      errorToast(ERROR_CODES[error] || 'Something went wrong');

      setIsLoading(false);
    }
  }, [user, error, status]);

  return (
    <div className="wrapper">
      <div className="formWrapper">
        <Logo />
        <div>
          <ECTypography variant="headline2" text="Sign in" />
        </div>
        <form className="signinForm" onSubmit={onSubmit}>
          <ECInput onChange={onChange} placeholder="Email" value={email} name="email" type="email" />

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '12px' }}>
            <ECInput
              onChange={onChange}
              placeholder="Password"
              value={password}
              required
              name="password"
              icon={<VisibilityIcon />}
              iconPosition="end"
              type={showPassword ? 'text' : 'password'}
              onIconClick={() => setShowPassword(!showPassword)}
            />

            <ECLink link="/forgot-password" text="Forgot my password" />
          </Box>

          <ECButton fullWidth variant="Primary" disabled={!(email && password)} loading={isLoading} type="submit">
            Sign in
          </ECButton>
          <div className="seperatorText">
            <span>or</span>
          </div>

          <OAuth />

          <ECTypography variant="bodyM" textPosition="center" className="link-text">
            Don't have an account yet? <ECLink link="/sign-up" text="Get started here" />
          </ECTypography>
        </form>
      </div>
    </div>
  );
};
