import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { decode } from 'html-entities';
import React, { useEffect, useState } from 'react';
import { IoBulbSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import RingLoader from 'react-spinners/RingLoader';
import RiseLoader from 'react-spinners/RiseLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FormRow from '../../../components/FormRow';

import { DOCUMENT_LIMIT } from '../../../constants/constants';
import { db } from '../../../firebase.config';
import { useUpdateDocumentsCount } from '../../../hooks/useUpdateDocumentsCount';
import './AssessmentBuilder.css';
import PDF from './AssessmentPDF';

const AssessmentBuilder = () => {
  const [checkBox1, setCheckbox1] = useState(true);
  const [checkBox2, setCheckbox2] = useState(false);
  const [checkBox3, setCheckbox3] = useState(false);
  const [checkBox4, setCheckbox4] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [keys, setKeys] = useState([]);

  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');
  const [newKey, setNewKey] = useState('');

  const [subject, setSubject] = useState('');
  const [gradeLevel, setGradeLevel] = useState('');
  const [quizCovers, setQuizCovers] = useState('');

  const [quizName, setQuizName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [documentHasChanged, setDocumentHasChanged] = useState(false);

  const { quizID } = useParams();
  const navigate = useNavigate();

  const updateCount = useUpdateDocumentsCount();
  const { userInfo } = useSelector((state) => state.userDetailsSlice);
  const { documentsCount } = useSelector((state) => state.userDocumentsCountSlice);

  const notify = (msg, autoClose, id) =>
    toast(msg, {
      position: 'top-right',
      autoClose: autoClose,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      pauseOnFocusLoss: false,
      theme: 'light',
      toastId: id,
    });

  const saveCompletionToDB = async (collectionName, data) => {
    const userUid = userInfo.userUid;
    data = { ...data, userUid, timestamp: Date.now() };
    return await addDoc(collection(db, collectionName), data);
  };

  // API Request Function to get the generated text and set the states for the lesson plan sections
  async function fetchApi(subject, gradeLevel, quizCovers) {
    setIsLoading(true);
    notify('📝 Generating quiz template!', 22000, 'quizTemplate');

    const response = await fetch(`${window.location.origin}/api/v1/completions/quizGeneratorCompletion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subject,
        gradeLevel,
        quizCovers,
      }),
    });

    const result = await response();
    console.log(result);
    setIsLoading(false);

    let textResult = decode(result);

    const questions = extractValues(textResult, 'question');
    const answers = extractValues(textResult, 'answer');
    const keys = extractValues(textResult, 'key');

    setQuestions(questions);
    setAnswers(answers);
    setKeys(keys);
    setDocumentHasChanged(true);

    const dataToSave = {
      subject,
      gradeLevel,
      quizCovers,
      application: 'Quiz Generator',
      generatedText: result,
    };

    saveCompletionToDB('completions', dataToSave)
      .then((ref) => {
        updateCount();
        console.log('Saved successfully, ref: ', ref);
      })
      .catch((err) => console.log('error', err));
  }

  const extractValues = (text, type) => {
    /* eslint-disable */
    const regex = new RegExp(`<${type}>(.*?)<\/${type}>`, 'gs');
    return [...text.matchAll(regex)].map((match) => match[1]);
  };

  const submitQuiz = () => {
    if (!userInfo.isHaveSub && documentsCount >= DOCUMENT_LIMIT) {
      navigate('/pricing');
      return;
    }

    if (subject && gradeLevel && quizCovers) {
      fetchApi(subject, gradeLevel, quizCovers);
      storeQuiz(subject, gradeLevel, quizCovers);
    } else {
      alert('Please fill out all fields');
    }
  };

  const fetchUnitStandards = async (subject, gradeLevel) => {
    setIsLoading(true);
    notify('🛸 Contacting the mothership...', 8500, 'quizCovers');

    try {
      const response = await fetch(`${window.location.origin}/api/v1/completions/quizCoversCompletion`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subject, gradeLevel }),
      });

      const result = await response.json();
      setIsLoading(false);
      console.log(result);
      const data = result.choices[0].message.content;
      setQuizCovers(data);
      toast.dismiss('quizCovers');
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching unit standards:', error);
      toast.error('Failed to fetch unit standards. Please try again.');
    }
  };

  const handleStandardGeneration = (event) => {
    event.preventDefault();
    if (!subject || !gradeLevel) return;
    fetchUnitStandards(subject, gradeLevel);
  };

  async function fetchNewQuestion(subject, gradeLevel, quizCovers, questions) {
    setIsLoading(true);
    notify('🔮 Generating new question!', 5000, 'newQuestion');
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      subject,
      gradeLevel,
      quizCovers,
      questions,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${window.location.origin}/api/v1/completions/quizQuestionCompletion`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        const choice = result.choices[0];
        const textResult = decode(choice.message.content);
        console.log('textResult New Question:', textResult);

        // Extracting question, answer, and key
        const questionMatch = textResult.match(/Question:\s*(.*?)(?=\n|$)/);
        const answerMatch = textResult.match(/Answer:\s*(.*?)(?=\n|$)/);
        const optionsMatch = textResult.match(/(A\).*?\nB\).*?\nC\).*?\nD\).*?)(?=\n|$)/s);

        if (questionMatch && answerMatch && optionsMatch) {
          const newQuestion = questionMatch[1].trim() + '\n' + optionsMatch[0].trim();
          const newAnswer = answerMatch[1].trim();
          const newKey = newAnswer[0]; // Assuming answer format "A) answer text"

          setNewQuestion(newQuestion);
          setNewAnswer(newAnswer);
          setNewKey(newKey);
        } else {
          console.error('Error parsing question or answer from response:', textResult);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('error', error);
      });
  }

  const handleNewQuestion = () => {
    fetchNewQuestion(subject, gradeLevel, quizCovers, questions);
  };

  useEffect(() => {
    if (newAnswer !== '') {
      const newQuestions = [...questions];
      const newAnswers = [...answers];
      const newKeys = [...keys];
      newQuestions.push(newQuestion);
      console.log('newQuestions === ', newQuestions);
      newAnswers.push(newAnswer);
      console.log('newAnswers === ', newAnswers);
      newKeys.push(newKey);
      setQuestions(newQuestions);
      setAnswers(newAnswers);
      setKeys(newKeys);
      setNewQuestion('');
      setNewAnswer('');
      setNewKey('');
      toast.dismiss('newQuestion');
    }
  }, [newKey]);

  useEffect(() => {
    if (documentHasChanged) {
      setDocumentHasChanged(false);
    }
  }, [documentHasChanged]);

  const deleteQuestionAnswers = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
    setAnswers(answers.filter((_, i) => i !== index));
    setKeys(keys.filter((_, i) => i !== index));
  };

  const getQuizName = async () => {
    const docRef = doc(db, 'quizzes', quizID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setQuizName(data.quizName || '');
      setSubject(data.subject || '');
      setQuizCovers(data.quizCovers || '');
      setGradeLevel(data.gradeLevel || '');
      setQuestions(data.questions || []);
      setAnswers(data.answers || []);
      setKeys(data.keys || []);
    } else {
      console.log('No such document!');
    }
  };

  useEffect(() => {
    getQuizName();
  }, []);

  const saveQuizToDB = async (data) => {
    const userUid = userInfo.userUid;
    data = { ...data, userUid, timestamp: Date.now() };
    return await addDoc(collection(db, 'quizzes'), data);
  };

  const storeQuiz = (subject, gradeLevel, quizCovers) => {
    saveQuizToDB({ subject, gradeLevel, quizCovers }).catch((err) => console.log('error', err));
  };

  useEffect(() => {
    if (subject) {
      const docRef = doc(db, 'quizzes', quizID);
      updateDoc(docRef, {
        quizCovers,
        subject,
        gradeLevel,
        questions,
        answers,
        keys,
      }).then(() => console.log('quizCovers updated'));
    }
  }, [quizCovers, subject, gradeLevel, questions, answers, keys]);

  const createPDF = async () => {
    const pdfAnswers = answers.map((answer) => answer.replace(/\\n/g, '\n'));
    const blob = await pdf(
      <PDF
        subject={subject}
        quizName={quizName}
        gradeLevel={gradeLevel}
        quizCovers={quizCovers}
        questions={questions}
        answers={pdfAnswers}
        keys={keys}
      />
    ).toBlob();
    await saveAs(blob, `${quizName}.pdf`);
  };

  const handlePDFDownload = async () => {
    await createPDF();
  };

  const handleShare = () => {
    const docRef = doc(db, 'quizzes', quizID);
    updateDoc(docRef, { shared: true });
    window.open(`/quiz-preview/${quizID}`);
  };

  return (
    <div className="assessment-container" id="page-one">
      <div className="right-container">
        <div className="questions-column">
          <div className="quiz-header-section">
            <div className="quiz-header-text">Quiz: {quizName}</div>
            <div className="quiz-controls">
              <button className="quiz-export-button" onClick={handlePDFDownload}>
                Export to PDF
              </button>
              <button className="quiz-share-button" onClick={handleShare}>
                Click to Share
              </button>
            </div>
          </div>
          {questions.map((question, index) => (
            <div key={index} className="quiz-container">
              <div className="quiz-content">
                <div>
                  <div className="question-number">
                    <strong>Question {index + 1}</strong>
                  </div>
                </div>
                <div>
                  {question.split('\n').map((line, i) => (
                    <div key={i}>{line}</div>
                  ))}
                </div>
                <div className="answers">
                  {answers[index] &&
                    answers[index]
                      .split(/(\n|\\n)/)
                      .filter((item) => item !== '\n' && item !== '\\n')
                      .map((answer, idx) => (
                        <div key={idx} className="answer">
                          {/* {answer} */}
                        </div>
                      ))}
                </div>
                <div className="answer-key">
                  <strong>Answer: {keys[index]}</strong>
                </div>
              </div>

              <div className="card-quiz-controls">
                <button className="btn btn-danger delete-button" onClick={() => deleteQuestionAnswers(index)}>
                  Delete
                </button>
              </div>
            </div>
          ))}
          <button
            className="btn btn-block"
            onClick={handleNewQuestion}
            disabled={subject === '' || gradeLevel === '' || quizCovers === ''}
          >
            {isLoading ? <RiseLoader color={'white'} loading={isLoading} size={7} /> : '+ New Question'}
          </button>
        </div>
      </div>

      <div className="left-container">
        <div className="quiz-form-header">Quiz Builder</div>
        <form className="quiz-form">
          <div className="checkbox-group quiz-checkbox-group">
            <div className="check1">
              <input
                type="checkbox"
                id="lessonplancheckbox"
                checked={checkBox1}
                onChange={(e) => setCheckbox1(e.target.checked)}
              />
              <label htmlFor="checkbox1">Multiple Choice</label>
            </div>
            <div className="check2">
              <input
                type="checkbox"
                id="powerpointcheckbox"
                disabled
                checked={checkBox2}
                onChange={(e) => setCheckbox2(e.target.checked)}
              />
              <label htmlFor="checkbox2">
                Fill in the Blank <span>(Coming soon...)</span>
              </label>
            </div>
            <div className="check3">
              <input
                type="checkbox"
                id="contextbuildercheckbox"
                disabled
                checked={checkBox3}
                onChange={(e) => setCheckbox3(e.target.checked)}
              />
              <label htmlFor="checkbox3">
                True or False <span>(Coming soon...)</span>
              </label>
            </div>
            <div className="check4">
              <input
                type="checkbox"
                id="educationalcheckbox"
                disabled
                checked={checkBox4}
                onChange={(e) => setCheckbox4(e.target.checked)}
              />
              <label htmlFor="checkbox4">
                Written Response <span>(Coming soon...)</span>
              </label>
            </div>
          </div>
          <FormRow
            type="text"
            labelText="Topic + Keywords:"
            name="subject"
            value={subject}
            handleChange={(e) => setSubject(e.target.value)}
            placeHolder="Ecosystems, Biomes, etc."
          />
          <FormRow
            type="text"
            labelText="Grade Level:"
            name="gradeLevel"
            value={gradeLevel}
            handleChange={(e) => setGradeLevel(e.target.value)}
            placeHolder="Year 9, Biology"
          />
          <div className="label-row">
            <label className="form-label">Quiz Should Cover:</label>
            <button className="ai-generate" onClick={handleStandardGeneration} disabled={isLoading}>
              {!isLoading ? (
                <IoBulbSharp style={{ fontSize: '1rem' }} />
              ) : (
                <RingLoader color={'#7d5ff5'} loading={isLoading} size={20} />
              )}
            </button>
          </div>
          <textarea
            className="quiz-textarea"
            placeholder="This quiz should include questions related to..."
            value={quizCovers}
            onChange={(e) => setQuizCovers(e.target.value)}
          />
        </form>
        <button
          className="btn btn-block"
          onClick={submitQuiz}
          disabled={isLoading || subject === '' || gradeLevel === '' || quizCovers === ''}
        >
          {isLoading ? (
            <RiseLoader color={'white'} loading={isLoading} size={7} />
          ) : keys.length === 0 ? (
            'Generate 5 Questions'
          ) : (
            'New Quiz'
          )}
        </button>
      </div>
    </div>
  );
};

export default AssessmentBuilder;
