import { Typography } from '@mui/material';
import styled from 'styled-components';

export const STypography = styled(Typography)`
  &.MuiTypography-root {
    text-transform: none;
    white-space: break-spaces;
    text-align: ${({ $textPosition }) => $textPosition};
    color: ${({ $color }) => $color || '#242D35'};

    &.headline2 {
      font-size: 28px;
      font-family: DMSansBold, sans-serif;
      margin: 36px 0 16px;
      line-height: 1.2;

      @media (max-width: 799px) {
        font-size: 24px;
      }

      @media (max-width: 435px) {
        font-size: 18px;
      }
    }

    &.headlineL {
      line-height: 1.5;
      font-family: DMSansBold, sans-serif;
      font-size: 24px;
    }

    &.headlineM {
      font-size: 18px;
      font-family: DMSansMedium, sans-serif;
      line-height: 1.5;
    }

    &.headlineS {
      font-size: 16px;
      font-family: DMSansMedium, sans-serif;
      line-height: 1;
    }

    &.subtitleM {
      font-size: 14px;
      line-height: 1.5;
      font-family: DMSansRegular, sans-serif;
      margin: 0;

      @media (max-width: 435px) {
        font-size: 12px;
      }
    }

    &.bodyM {
      font-family: DMSansRegular, sans-serif;
      font-size: 16px;
      line-height: 1;
    }

    &.bodyS {
      color: ${({ $color }) => $color || '#4F5B67'};
      font-family: DMSansRegular, sans-serif;
      font-size: 14px;
      line-height: 1.5;
    }
  }
`;
