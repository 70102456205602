import { WorkshopTools } from '../../../components/WorkshopTools';

const Workshop = () => {
  return (
    <>
      <WorkshopTools />
    </>
  );
};

export default Workshop;
