import AddIcon from '@mui/icons-material/Add';
import { useMediaQuery } from '@mui/material';

import { ECButton } from '../ECButton';
import { Header } from '../Header';
import { Tool } from '../tool';
import { Wrapper } from './styles';
import { TOOLS } from './tools';

export const WorkshopTools = () => {
  const isMobile = useMediaQuery('(max-width:749px)');
  const requestNewTool = () => {
    window.open('https://educationcopilot.com/requests/');
  };

  return (
    <>
      <Header title="Workshop Tools 🤖">
        {!isMobile && (
          <ECButton variant="Primary" isIcon onClick={requestNewTool}>
            Request a new tool <AddIcon />
          </ECButton>
        )}
      </Header>
      <Wrapper>
        {TOOLS.map((tool) => (
          <Tool key={tool.title} {...tool} />
        ))}
      </Wrapper>
    </>
  );
};
