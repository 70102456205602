import { FormLabel as FormLabelMui, InputAdornment as InputAdornmentMui } from '@mui/material';
import styled from 'styled-components';

export const FormLabel = styled(FormLabelMui)`
  &.MuiFormLabel-root {
    display: flex;
    flex-direction: column;
    width: 360px;
    justify-content: flex-start;

    .text {
      line-height: 1;
      font-size: 14px;
      font-family: DMSansMedium, sans-serif;
      color: var(--grey-700);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text-length {
        font-family: DMSansMedium, sans-serif;
      }
    }
  }

  input::placeholder {
    color: var(--gray-500);
    font-size: 16px;
    font-family: DMSansRegular, sans-serif;
    opacity: 1;
  }

  .MuiInputBase-root {
    border-radius: 12px;
    border: 1px solid var(--grey-300);
    color: var(--grey-600);
    font-size: 16px;
    font-family: DMSansRegular, sans-serif;
    margin-top: ${({ $label }) => ($label ? '12px' : 0)};
    padding: 0 24px;
    outline: none;
    width: 100%;

    .MuiInputBase-input {
      padding: 15.5px 0;
      color: var(--grey-600);
    }

    &.Mui-focused:not(.MuiInputBase-readOnly) {
      border: 1px solid var(--primary-violet-500);

      .MuiInputBase-input {
        color: var(--grey-800);
      }
    }

    &:hover:not(.MuiInputBase-readOnly) {
      background: var(--grey-50);

      &.Mui-focused {
        background: var(--white);
      }
    }

    &.MuiInputBase-formControl.Mui-error {
      border: 1px solid var(--red-500);
    }
  }

  .MuiFormHelperText-root {
    padding-top: 12px;
    font-size: 14px;
    font-family: DMSansRegular, sans-serif;
    margin: 0;

    &.Mui-error {
      color: var(--red-500);
    }
  }

  input[readonly] {
    cursor: default;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiSvgIcon-root {
    color: var(--grey-400);
  }
`;

export const InputAdornment = styled(InputAdornmentMui)`
  cursor: ${({ $isPointer }) => ($isPointer ? 'pointer' : 'inherit')};
`;
