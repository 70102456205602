export const replaceTags = ({ text, regexPattern, tag, title = '' }) => {
  const replacedTags = new Set(); // To keep track of replaced tags

  return text.replaceAll(regexPattern, (match) => {
    if (!replacedTags.has(match)) {
      replacedTags.add(match);

      if (match.includes('definition')) {
        const w = match.replace('definition', 'word');
        const regexWord = new RegExp(`${w}(.*?)${w}`, 'g');
        const matchWord = text.match(regexWord);
        const word = matchWord[0].replaceAll(w, '');

        return `<h4>${word}</h4><p>Definition: `;
      } else if (tag) {
        return `<${tag}>`;
      }

      return `${title && `<h4>${title}</h4>`}<p>`;
    }

    return `</${tag || 'p'}>`;
  });
};
