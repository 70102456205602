import { useParams } from 'react-router-dom';

import { TOOL_TYPES } from '../../../constants/constants.js';
import { NotFound } from '../../notFound';
import BuilderLayout from '../builder-layout';
import MultiStepForm from '../custom/AssessmentStart';
import { GeneratorLayout } from '../generator-layout';

export const ToolLayout = () => {
  const { toolType, toolId } = useParams();

  if (toolType === TOOL_TYPES.custom) {
    switch (toolId) {
      case 'quiz-builder':
        return <MultiStepForm />;
      default:
        return <NotFound />;
    }
  }

  if (toolType === TOOL_TYPES.generator) {
    return <GeneratorLayout toolName={toolId} />;
  }

  if (toolType === TOOL_TYPES.builder) {
    return <BuilderLayout toolName={toolId} />;
  }

  return <NotFound />;
};
