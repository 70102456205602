// Hooks and React Imports
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { ImArrowLeft2, ImMagicWand } from 'react-icons/im';
import { IoCalendarSharp } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';

import Day from '../../../components/UnitDay';

import Wrapper from '../../../assets/wrappers/WorkshopTools';
import { db } from '../../../firebase.config';
import '../dashboard-pages/creationPages/Creations.css';

const UnitPreview = () => {
  // States
  const [unitDetails, setUnitDetails] = useState({
    unitName: '',
    unitLength: '',
    time: '',
    gradeLevel: '',
  });
  const [dayCards, setDayCards] = useState([]);

  // Destructure unitDetails
  const { unitName } = unitDetails;
  const { unitID } = useParams();

  // Get the unit details from the database
  async function getUnitDetails() {
    const unitRef = doc(db, 'units', unitID);
    const docSnap = await getDoc(unitRef);

    setUnitDetails({
      unitName: docSnap.data().unitName,
      unitLength: Number(docSnap.data().unitLength),
      time: Number(docSnap.data().time),
      gradeLevel: docSnap.data().gradeLevel,
      unitStandards: docSnap.data().unitStandards,
      unitDetails: docSnap.data().unitDetails,
      day1: docSnap.data().day1,
      day2: docSnap.data().day2,
      day3: docSnap.data().day3,
      day4: docSnap.data().day4,
      day5: docSnap.data().day5,
      day6: docSnap.data().day6,
      day7: docSnap.data().day7,
      day8: docSnap.data().day8,
      day9: docSnap.data().day9,
      day10: docSnap.data().day10,
      day11: docSnap.data().day11,
      day12: docSnap.data().day12,
      day13: docSnap.data().day13,
      day14: docSnap.data().day14,
      day15: docSnap.data().day15,
      title1: docSnap.data().title1,
      title2: docSnap.data().title2,
      title3: docSnap.data().title3,
      title4: docSnap.data().title4,
      title5: docSnap.data().title5,
      title6: docSnap.data().title6,
      title7: docSnap.data().title7,
      title8: docSnap.data().title8,
      title9: docSnap.data().title9,
      title10: docSnap.data().title10,
      title11: docSnap.data().title11,
      title12: docSnap.data().title12,
      title13: docSnap.data().title13,
      title14: docSnap.data().title14,
      title15: docSnap.data().title15,
    });
  }

  // Split each line of the overview based on the '-'

  // create a function called truncateOverview that gets all the text after the first colon.
  function truncateOverview(str) {
    return str?.split(':')[1];
  }

  // Function to render a Day component for each number in the unit length
  function renderDays() {
    const days = [];
    for (let i = 1; i <= unitDetails.unitLength; i++) {
      days.push(
        <Day
          unitName={unitName}
          unitDetails={unitDetails}
          dayNumber={i}
          key={`Day ${i}`}
          day={`Day ${i}`}
          title={i >= 1 && i <= 15 ? truncateOverview(unitDetails[`title${i}`]) : ''}
          overview={i >= 1 && i <= 15 ? unitDetails[`day${i}`].match : ''}
        />
      );
    }
    return days;
  }

  // React Hooks & Misc Functions
  useEffect(() => {
    getUnitDetails();
  }, []);

  useEffect(() => {
    setDayCards(renderDays());
  }, [unitDetails]);

  return (
    <div className="creations">
      <Link to={'../unit-planner'}>{<ImArrowLeft2 className="historyicon" />}Back to Unit Planner</Link>
      <div
        className="pageheader"
        style={{
          marginTop: '1rem',
        }}
      >
        <div>
          {<ImMagicWand className="historyicon" style={{ fontSize: '1.2rem' }} />} {unitName}
        </div>
      </div>
      <div
        style={{
          marginBottom: '1.3rem',
          fontFamily: 'inter',
          fontWeight: '500',
          fontSize: '1.2rem',
          transform: 'translateY(-.5rem)',
          color: '#000000',
        }}
      >
        {<IoCalendarSharp className="historyicon" />} {unitDetails.unitLength} days
      </div>
      <Wrapper>
        <div className="jobs">{dayCards}</div>
      </Wrapper>
    </div>
  );
};

export default UnitPreview;
