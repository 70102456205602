import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ECButton } from '../../../components/ECButton';
import { ECInput } from '../../../components/ECInput';
import { ECLink } from '../../../components/ECLink';
import { ECTypography } from '../../../components/ECTypography';

import { Logo } from '../../../components';
import { errorToast } from '../../../utils/errorToast';
import { successToast } from '../../../utils/successToast';

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onChange = (e) => setEmail(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const actionCodeSettings = {
      url: window.location.origin,
    };

    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email, actionCodeSettings);

      successToast('Email was sent. Please check your email');

      const time = setTimeout(() => {
        navigate('/sign-in');
        clearTimeout(time);
      }, 4000);
    } catch (error) {
      errorToast('Could not send reset email');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      <div className="formWrapper">
        <Logo />
        <div>
          <ECTypography variant="headline2" text="Reset Password" />
        </div>
        <form onSubmit={onSubmit} className="signinForm">
          <ECInput onChange={onChange} placeholder="Email" value={email} required name="email" type="email" />

          <ECButton fullWidth variant="Primary" disabled={!email} loading={isLoading} type="submit">
            Send a password reset link
          </ECButton>
          <ECTypography variant="bodyM" textPosition="center" className="link-text">
            Remember your password? <ECLink link="/sign-in" text="Sign in here" />
          </ECTypography>
        </form>
      </div>
    </div>
  );
};
