import React from 'react';

import { ECTypography } from '../../components/ECTypography';

import notFound from '../../assets/svg/404.svg';
import { Link, Wrapper } from './styles';

export const NotFound = () => {
  return (
    <Wrapper>
      <img src={notFound} alt="404 Not Found" />
      <ECTypography variant="headline2" text="Oops! Looks like you’ve got lost..." textPosition="center" />
      <ECTypography
        variant="subtitleM"
        text="The page you’re looking for doesn’t exist or has been moved. "
        textPosition="center"
      />
      <Link to="/" className="link">
        Back home
      </Link>
    </Wrapper>
  );
};
