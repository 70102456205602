import React from 'react';

import { CircularLoader } from '../CircularLoader';
import { SButton } from './styles';

const BtnType = {
  Primary: 'primary',
  PrimaryOutline: 'primary-outline',
  Outline: 'outline',
  Secondary: 'secondary',
};

export const ECButton = ({
  variant,
  loading,
  disabled,
  isIcon,
  children,
  type = 'button',
  onClick,
  fullWidth,
  className,
}) => {
  const classNameBtnType = BtnType[variant];

  return (
    <SButton
      variant={variant}
      disabled={loading || disabled}
      className={`${classNameBtnType} ${fullWidth ? 'fullWidth' : ''} ${className || ''} ${loading ? 'isLoading' : ''}`}
      $isIcon={isIcon}
      type={type}
      onClick={onClick}
      fullWidth={fullWidth}
    >
      {loading && <CircularLoader />} {children}
    </SButton>
  );
};
