import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { doc, getDoc } from 'firebase/firestore';

import { UPDATE_DAY } from '../../constants/constants';
import { db } from '../../firebase.config';
import AuthService from '../../services/Auth.service';

const checkOldUser = async (user) => {
  const getInfo = await axios(`${window.location.origin}/api/v1/user?email=${user.email}`);

  return { user: user, userUid: user.uid, ...getInfo.data };
};

export const getUserInfo = createAsyncThunk('userDetailsSlice/getUserinfo', async (user) => {
  const docRef = doc(db, 'users', user.uid);
  const docSnap = await getDoc(docRef);
  const { occupation, phoneNumber, displayName, photoURL } = docSnap.data();
  const info = {
    occupation,
    phoneNumber,
    displayName: displayName || user.displayName,
    photoURL: photoURL || user.photoURL,
  };

  if (Number(user.metadata.createdAt) < UPDATE_DAY) {
    const oldUser = await checkOldUser(user);
    return { ...oldUser, user: { ...oldUser.user, ...info } };
  } else {
    const hasUserActiveSubscription = await AuthService.doesUserHaveActiveSubscription();
    const subscription = await AuthService.getUserSubscriptionDetails();

    return {
      user: { ...user, ...info },
      isHaveSub: hasUserActiveSubscription,
      subs: subscription,
      userUid: user.uid,
    };
  }
});

const userDetailsSlice = createSlice({
  name: 'userDetailsSlice',
  initialState: {
    userInfo: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    updateUserDetails: (state, { payload }) => {
      state.userInfo = {
        ...state.userInfo,
        user: { ...state.user, ...payload },
      };
    },
  },
  extraReducers: {
    [getUserInfo.pending]: (state) => {
      state.status = 'loading';
    },
    [getUserInfo.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.userInfo = action.payload;
      state.error = null;
    },
    [getUserInfo.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const { updateUserDetails } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
