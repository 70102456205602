import { FormControlLabel as FormControlLabelMui, RadioGroup as RadioGroupMui } from '@mui/material';
import styled from 'styled-components';

export const Label = styled.p`
  font-size: 14px;
  font-family: DMSansMedium, sans-serif;
  color: var(--grey-700);
  margin: 0;
  line-height: 1;
`;

export const FormControlLabel = styled(FormControlLabelMui)`
  border-radius: 12px;
  border: 1px solid var(--grey-300);
  color: var(--grey-600);
  font-size: 16px;
  padding-left: 24px;
  outline: none;
  width: 100%;
  justify-content: space-between;

  &.MuiFormControlLabel-root {
    margin: 12px 0 0;
    padding: 16px 24px;

    &:hover {
      background: var(--grey-50);
    }

    &:active {
      border: 1px solid var(--primary-violet-500);
    }
  }

  .MuiTypography-root {
    font-family: DMSansRegular, sans-serif;
  }

  .MuiRadio-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    color: var(--grey-400);
  }

  .Mui-checked .MuiSvgIcon-root {
    color: var(--primary-violet-500);
  }
`;

export const RadioGroup = styled(RadioGroupMui)`
  gap: 24px;
  width: 360px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 360px;
  height: 360px;

  @media (max-width: 750px) {
    min-width: 280px;
  }
`;
