import VisibilityIcon from '@mui/icons-material/Visibility';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import React, { useState } from 'react';

import { ECButton } from '../../../components/ECButton';
import { ECInput } from '../../../components/ECInput';
import { PasswordRequirements } from '../../../components/PasswordRequirements';

import { errorToast } from '../../../utils/errorToast';
import { successToast } from '../../../utils/successToast';
import { Box, Form } from '../styles';

const initialState = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ERROR_CODES = {
  'auth/wrong-password': 'Incorrect current password',
};

export const Security = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    ...initialState,
  });

  const handleCancel = () => {
    setFormData({ ...initialState });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { oldPassword, newPassword, confirmPassword } = formData;
    if (newPassword === confirmPassword) {
      setLoading(true);

      try {
        const auth = getAuth();
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(user.email, oldPassword);

        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword);

        successToast('Password successfully updated!');

        handleCancel();
      } catch (error) {
        errorToast(ERROR_CODES[error.code]);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const { oldPassword, newPassword, confirmPassword } = formData;
  const isChanged = !(oldPassword && newPassword && confirmPassword);

  return (
    <Form onSubmit={onSubmit} className="form-wrapper">
      <ECInput
        onChange={handleChange}
        label="Current password"
        value={oldPassword}
        required
        name="oldPassword"
        icon={<VisibilityIcon />}
        iconPosition="end"
        type={showPassword ? 'text' : 'password'}
        onIconClick={() => setShowPassword(!showPassword)}
      />
      <ECInput
        onChange={handleChange}
        label="New password"
        value={newPassword}
        required
        name="newPassword"
        type="password"
      />
      <div>
        <ECInput
          onChange={handleChange}
          label="Confirm new password"
          value={confirmPassword}
          required
          type="password"
          name="confirmPassword"
        />

        <PasswordRequirements password={newPassword} />
      </div>

      <Box $gapSize={12}>
        <ECButton
          variant="Primary"
          disabled={isChanged || newPassword !== confirmPassword}
          type="submit"
          loading={loading}
        >
          Save changes
        </ECButton>
        <ECButton variant="Outline" disabled={isChanged} onClick={handleCancel}>
          Cancel
        </ECButton>
      </Box>
    </Form>
  );
};
