import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ECButtonIcon } from '../../components/ECButtonIcon';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';

import { logout } from '../../store/reducers/authSlice';
import { TabPanels } from './TabPanels';
import { Box, Wrapper } from './styles';

const a11yProps = (index) => {
  return {
    className: 'profile-tab',
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

export const Profile = () => {
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout());
    navigate('/sign-in');
  };

  return (
    <Wrapper className="profile">
      <div>
        <Header title="Profile">
          <Box $gapSize={8}>
            {false && <ECButtonIcon icon={<NotificationsNoneIcon />} variant="Outline" />}
            <ECButtonIcon icon={<LogoutIcon />} onClick={onLogout} variant="Outline" />
          </Box>
        </Header>

        <div className="main-container">
          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              scrollButtons="auto"
              variant="scrollable"
              TabIndicatorProps={{ style: { backgroundColor: '#373F47' } }}
              sx={{
                '.Mui-selected': {
                  color: '#373F47',
                },
              }}
            >
              <Tab label="Personal info" {...a11yProps(0)} />
              <Tab label="Security" {...a11yProps(1)} />
              <Tab label="Plans" {...a11yProps(2)} />
            </Tabs>
          </div>
          <TabPanels value={value} />
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
};
