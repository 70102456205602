// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
// import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import TabIcon from '@mui/icons-material/Tab';

const links = [
  { id: 1, text: 'Dashboard', path: '/', icon: <TabIcon /> },
  // {
  //   id: 2,
  //   text: 'daily planner',
  //   path: 'unit-planner',
  //   icon: <CollectionsBookmarkIcon />,
  // },
  { id: 3, text: 'Mindful Workshop', path: 'workshop', icon: <AutoFixHighIcon /> },
  // { id: 4, text: 'History', path: 'creations', icon: <AccessTimeIcon /> },
  { id: 5, text: 'Profile', path: 'Profile', icon: <AccountTreeIcon /> },
];

export default links;
