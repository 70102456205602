import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ECButton } from '../../../components/ECButton';
import { ECInput } from '../../../components/ECInput';

import { ReactComponent as PhoneInTalkOutlinedIcon } from '../../../assets/svg/phoneInTalk.svg';
import { PHONE_PATTERN } from '../../../constants/regex';
import { db } from '../../../firebase.config';
import { updateUserDetails } from '../../../store/reducers/userDetailsSlice';
import { errorToast } from '../../../utils/errorToast';
import { successToast } from '../../../utils/successToast';
import { Box, Form } from '../styles';
import { Avatar, InputFile } from './styles';

export const PersonalInfo = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userDetailsSlice);
  const { user, userUid } = userInfo || {};
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const initialState = {
    displayName: user.displayName || '',
    email: user.email,
    occupation: user.occupation || '',
    phoneNumber: user.phoneNumber || '+',
    photoURL: user.photoURL,
  };

  const [formData, setFormData] = useState({ ...initialState });

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if ((formData.phoneNumber && PHONE_PATTERN.test(formData.phoneNumber)) || formData.phoneNumber.length === 1) {
      setIsPhoneNumberError(false);
      setLoading(true);

      try {
        const userRef = doc(db, 'users', userUid);

        await updateDoc(userRef, {
          ...formData,
        });

        dispatch(updateUserDetails(formData));

        setIsChanged(false);
        successToast('Personal info successfully updated!');

        setIsSubmit(false);
      } catch (error) {
        errorToast('Could not update profile details');
      } finally {
        setLoading(false);
      }
    } else {
      setIsPhoneNumberError(true);
    }
  };

  const handleChangeInput = ({ target }) => {
    let { name, value } = target;

    if (name === 'phoneNumber' && !value) {
      value = `+${value}`;
    }

    handleChange({
      name,
      value,
    });
  };

  const handleChange = ({ value, name }) => {
    const newValues = {
      ...formData,
      [name]: value,
    };

    if (name === 'displayName') {
      formData.name = value;
    } else if (name === 'phoneNumber') {
      if (isSubmit) {
        setIsPhoneNumberError(!PHONE_PATTERN.test(phoneNumber));
      }

      if (!value.includes('+')) {
        newValues.phoneNumber = `+${value}`;
      }
    }

    setFormData(newValues);
    setIsChanged(true);
  };

  const handleUploadClick = () => {
    const file = event.target.files[0];
    const storage = getStorage();
    const metadata = {
      contentType: 'image/jpeg',
    };

    const storageRef = ref(storage, 'images/' + (file?.name || 'avatar'));
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      () => {},
      () => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          handleChange({
            name: 'photoURL',
            value: downloadURL,
          });
        });
      }
    );
  };

  const handleCancel = () => {
    setFormData({ ...initialState });
    setIsSubmit(false);
    setIsPhoneNumberError(false);
  };

  const { displayName, email, occupation, phoneNumber, photoURL } = formData;

  return (
    <Form onSubmit={onSubmit}>
      <Box sx={{ alignItems: 'center' }}>
        <Avatar alt="Avatar" src={photoURL} sx={{ bgcolor: 'white' }}>
          {user.photoURL ? null : <AccountCircleIcon />}
        </Avatar>

        <label htmlFor="contained-button-file">
          <ECButton variant="Outline" isIcon>
            Upload new <FileUploadIcon />
            <InputFile accept="image/*" multiple type="file" onChange={handleUploadClick} />
          </ECButton>
        </label>
      </Box>
      <Box>
        <ECInput
          onChange={handleChangeInput}
          label="Full name"
          value={displayName}
          icon={<PersonOutlineOutlinedIcon />}
          name="displayName"
        />
        <ECInput
          onChange={handleChangeInput}
          label="Email"
          value={email}
          icon={<AlternateEmailOutlinedIcon />}
          name="email"
          type="email"
          readOnly
        />
      </Box>
      <Box>
        <ECInput
          onChange={handleChangeInput}
          label="Mobile phone"
          value={phoneNumber}
          icon={<PhoneInTalkOutlinedIcon />}
          name="phoneNumber"
          type="tel"
          isError={isPhoneNumberError}
          errorText="Invalid phone number"
        />
        <ECInput
          onChange={handleChangeInput}
          label="Occupation"
          value={occupation}
          icon={<BusinessOutlinedIcon />}
          name="occupation"
        />
      </Box>
      <Box $gapSize={12}>
        <ECButton variant="Primary" disabled={!isChanged} type="submit" loading={loading}>
          Save changes
        </ECButton>
        <ECButton variant="Outline" disabled={!isChanged} onClick={handleCancel}>
          Cancel
        </ECButton>
      </Box>
    </Form>
  );
};
