import { CircularProgress } from '@mui/material';
import React from 'react';

import { LoaderDiv } from './styles';

export const CircularLoader = ({ color = '#7D5FF5', size = 25 }) => {
  return (
    <LoaderDiv sx={{ display: 'flex' }} $color={color}>
      <CircularProgress id="circularProgress" variant="indeterminate" size={size} />
    </LoaderDiv>
  );
};
