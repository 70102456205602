import { toast } from 'react-toastify';

export const successToast = (text) => {
  return toast.success(text, {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'light',
    closeButton: false,
  });
};
