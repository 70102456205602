import styled from 'styled-components';

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .MuiButton-Secondary.fullWidth {
    justify-content: flex-start;

    svg {
      margin-right: 12px;
      font-size: 24px;
    }

    &.link-button {
      justify-content: space-between;

      svg {
        margin: 0;
        font-size: 16px;
      }
    }
  }
`;
