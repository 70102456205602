import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { IoCheckmarkSharp } from 'react-icons/io5';

import './pricing.css';

const PricingCard = (props) => {
  const { price, checkOut, subscription, isPurpleOutline } = props;
  const handlePay = () => {
    checkOut(price.id);
  };

  return (
    <Card
      className="pricing-monthly"
      sx={{
        width: '100%',
        maxWidth: '500px',
        border: 'none',
        // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        height: '100%',
        '&:hover': {
          boxShadow: '0px 0px 10px rgba(123, 47, 245, 0.306)',
        },
        outline: isPurpleOutline ? '2px solid #7a2ff5' : 'none',
        boxShadow: isPurpleOutline ? '0px 0px 10px rgba(123, 47, 245, 0.306)' : 'none',
      }}
    >
      <CardContent style={{ margin: '1rem' }}>
        <div>
          <div className="form-center" style={{ paddingBottom: '1rem' }}>
            <h4
              className="type-monthly"
              style={{
                fontSize: '14px',
                fontWeight: '600',
                margin: '0',
                marginBottom: '1rem',
              }}
            >
              {price.interval}
            </h4>
            <a
              style={{
                fontSize: '56px',
                fontWeight: '800',
              }}
            >
              $
              {price.interval === 'year' ? (price.unit_amount / 1200).toFixed(0) : (price.unit_amount / 100).toFixed(0)}
            </a>
            <a
              style={{
                color: 'gray',
              }}
            >
              {price.interval === 'month' ? 'mo' : 'mo'}
            </a>
          </div>
        </div>
        <div className="bodyText">
          <h5
            style={{
              fontSize: '16px',
              color: 'gray',
              fontWeight: '300',
              letterSpacing: '0.3px',
            }}
          >
            {price.description}
          </h5>
          <p>
            {price.interval === 'year' ? (
              <div>
                {' '}
                <IoCheckmarkSharp className="checkmark" style={{ stroke: '#34cd2b', fontSize: '1.3rem' }} />
                30% Savings over Monthly Plan 🚀
                <br />
              </div>
            ) : null}
            <IoCheckmarkSharp className="checkmark" style={{ stroke: '#34cd2b', fontSize: '1.3rem' }} />
            Access to Mindful's Unit Planner
            <br />
            <IoCheckmarkSharp className="checkmark" style={{ stroke: '#34cd2b', fontSize: '1.3rem' }} />
            Unlimited Usage
            <br />
            <IoCheckmarkSharp className="checkmark" style={{ stroke: '#34cd2b', fontSize: '1.3rem' }} />
            Support for over 10+ languages
            <br />
            <IoCheckmarkSharp className="checkmark" style={{ stroke: '#34cd2b', fontSize: '1.3rem' }} />
            Chat Support
            <br />
            <IoCheckmarkSharp className="checkmark" style={{ stroke: '#34cd2b', fontSize: '1.3rem' }} />
            Lock in your price for life!
          </p>
          <Button
            sx={{
              width: '100%',
              maxWidth: '100%',
              textTransform: 'none',
              background: '#7d5ff5',
              '&:hover': {
                background: '#7a2ff5',
              },
            }}
            variant="contained"
            disabled={subscription?.items[0].plan.id === price.id}
            onClick={handlePay}
          >
            {subscription?.items[0].plan.id === price.id ? 'Current Active Plan' : 'Get Started'}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default PricingCard;
