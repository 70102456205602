import { useState } from 'react';

import { ECButton } from '../../../../../components/ECButton';

import { ReactComponent as WordIcon } from '../../../../../assets/svg/word.svg';

export const DownloadWord = ({ subject, name, saveFileToDB }) => {
  const [loadingWord, setLoadingWord] = useState(false);

  const downloadWord = () => {
    setLoadingWord(true);

    const header = `<html lang='en' xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>${name}</title></head><body>`;
    const footer = '</body></html>';
    const html = header + document.querySelector('.ck-content').innerHTML + footer;
    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
    const filename = `${subject} ${name}${Date.now()}.docx`;
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();

      saveFileToDB(blob, name, 'docx');
    }

    setLoadingWord(false);
    document.body.removeChild(downloadLink);
  };

  return (
    <ECButton fullWidth variant="Secondary" onClick={downloadWord} loading={loadingWord}>
      <WordIcon />
      Word
    </ECButton>
  );
};
