import { Drawer as DrawerMui, Popover as PopoverMui } from '@mui/material';
import styled from 'styled-components';

export const Popover = styled(PopoverMui)`
  .MuiPaper-root {
    width: 408px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.06), 4px 5px 6px 0 rgba(0, 0, 0, 0.05), 9px 11px 9px 0 rgba(0, 0, 0, 0.03),
      17px 20px 10px 0 rgba(0, 0, 0, 0.01), 26px 31px 11px 0 rgba(0, 0, 0, 0);
    border-radius: 12px;
    padding: 24px;
    border: 1px solid var(--grey-200);
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const Drawer = styled(DrawerMui)`
  .MuiDrawer-paper {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 24px;
    border: 1px solid var(--grey-200);
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 80%;
  }
`;
