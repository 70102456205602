import { Button as ButtonMui } from '@mui/material';
import styled from 'styled-components';

export const SButton = styled(ButtonMui)`
  &.MuiButtonBase-root {
    display: flex;
    padding: 12px;
    align-items: center;
    border-radius: 32px;
    min-width: 40px;

    svg {
      font-size: ${({ $size }) => $size || 16}px;
    }

    &.primary {
      color: var(--white);
      background: var(--primary-violet-500);

      svg {
        fill: var(--white);
      }

      &:hover {
        background: var(--primary-violet-600);
      }

      &:focus {
        border: 2px solid var(--primary-violet-200);
        background: var(--primary-violet-500);
      }

      &:disabled {
        background: var(--primary-violet-100);
      }
    }

    &.outline {
      border: 1px solid var(--grey-200);
      color: var(--grey-900);

      svg {
        fill: var(--grey-800);
      }

      &:hover {
        background: var(--grey-50);

        svg {
          fill: var(--grey-900);
        }
      }

      &:focus {
        border: 1px solid var(--primary-violet-500);
      }

      &:disabled {
        background: var(--grey-50);
        border: var(--grey-50);
        color: var(--grey-300);
      }
    }

    &.no-border {
      border: none;
      min-width: fit-content;
      padding: 0;

      svg {
        fill: var(--grey-800);
      }
    }
  }
`;
