import { ECTypography } from '../ECTypography';
import { SHeader } from './styles';

export const Header = ({ title, children }) => {
  return (
    <SHeader>
      <ECTypography variant="headlineL" text={title} />
      {children}
    </SHeader>
  );
};
