import { getAuth } from '@firebase/auth';
import { pdf } from '@react-pdf/renderer';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { decode } from 'html-entities';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { db } from '../../../../firebase.config';
import PDF from '../../../ai-tools/custom/EducationalPDF';

const EducationalHandoutAPI = ({ overview, dayNumber }) => {
  // PDF File States
  const [fileUrl, setFileUrl] = useState(null);

  // Educational Handout States

  const [title, setTitle] = useState('');
  const [covers, setCovers] = useState('');
  const [header1, setHeader1] = useState('');
  const [body1, setBody1] = useState('');
  const [header2, setHeader2] = useState('');
  const [body2, setBody2] = useState('');
  const [header3, setHeader3] = useState('');
  const [body3, setBody3] = useState('');
  const [header4, setHeader4] = useState('');
  const [body4, setBody4] = useState('');
  const [header5, setHeader5] = useState('');
  const [body5, setBody5] = useState('');
  const [header6, setHeader6] = useState('');
  const [body6, setBody6] = useState('');
  const [header7, setHeader7] = useState('');
  const [body7, setBody7] = useState('');
  const [header8, setHeader8] = useState('');
  const [body8, setBody8] = useState('');

  // API Request & Response States

  const [subject] = useState('');
  const [gradeLevel, setGradeLevel] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [documentHasChanged, setDocumentHasChanged] = useState(false);
  const [fileIsReady, setFileIsReady] = useState(false);

  const { unitID } = useParams();
  let overviewText = overview;

  const notify = () =>
    toast('🤖 Handout on its way!', {
      position: 'top-left',
      autoClose: 70000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      pauseOnFocusLoss: false,
      theme: 'light',
      toastId: 'educationalhandoutunit',
    });

  // Async function to save the document to the database

  async function saveCompletionToDB(collectionName, data) {
    const auth = getAuth();
    const user = auth.currentUser;
    data = {
      ...data,
      userId: user.uid,
      timestamp: Date.now(),
    };
    return await addDoc(collection(db, collectionName), data);
  }

  async function checkIfLessonPlan() {
    const docRef = doc(db, 'units', unitID);
    const docSnap = await getDoc(docRef);
    setGradeLevel(docSnap.data().gradeLevel);
  }

  useEffect(() => {
    checkIfLessonPlan(dayNumber);
  }, [gradeLevel]);

  async function fetchApi(subject, gradeLevel, overviewText) {
    notify();
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      subject,
      gradeLevel,
      overviewText,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${window.location.origin}/api/v1/completions/educationalHandoutCompletionUnit`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let textResult = decode(result.choices[0].text);

        // set the title to everything between <title> and </title>
        const regexPdftitle = /<pdftitle>(.*?)<\/pdftitle>/s;
        const matchFirst = textResult.match(regexPdftitle);
        setTitle(matchFirst[1]);

        // Set Covers
        const regexHandoutcovers = /<handoutcovers>(.*?)<\/handoutcovers>/s;
        const matchFirstExample = textResult.match(regexHandoutcovers);
        setCovers(matchFirstExample[1]);

        // Set First Header
        const regexHeader1 = /<header1>(.*?)<\/header1>/s;
        const matchFirstHeader = textResult.match(regexHeader1);
        setHeader1(matchFirstHeader[1]);

        // Set Body1
        const regexBody1 = /<body1>(.*?)<\/body1>/s;
        const matchFirstBody = textResult.match(regexBody1);
        setBody1(matchFirstBody[1]);

        // Set Header2
        const regexHeader2 = /<header2>(.*?)<\/header2>/s;
        const matchSecondHeader = textResult.match(regexHeader2);
        setHeader2(matchSecondHeader[1]);

        // Set Body2
        const regexBody2 = /<body2>(.*?)<\/body2>/s;
        const matchSecondBody = textResult.match(regexBody2);
        setBody2(matchSecondBody[1]);

        // Set Header3
        const regexHeader3 = /<header3>(.*?)<\/header3>/s;
        const matchThirdHeader = textResult.match(regexHeader3);
        setHeader3(matchThirdHeader[1]);

        // Set Body3
        const regexBody3 = /<body3>(.*?)<\/body3>/s;
        const matchThirdBody = textResult.match(regexBody3);
        setBody3(matchThirdBody[1]);

        // Set Header4
        const regexHeader4 = /<header4>(.*?)<\/header4>/s;
        const matchFourthHeader = textResult.match(regexHeader4);
        setHeader4(matchFourthHeader[1]);

        // Set Body4
        const regexBody4 = /<body4>(.*?)<\/body4>/s;
        const matchFourthBody = textResult.match(regexBody4);
        setBody4(matchFourthBody[1]);

        // Set Header5
        const regexHeader5 = /<header5>(.*?)<\/header5>/s;
        const matchFifthHeader = textResult.match(regexHeader5);
        if (matchFifthHeader) {
          setHeader5(matchFifthHeader[1]);
        }

        // Set Body5 Conditionally if it exists, it might not
        const regexBody5 = /<body5>(.*?)<\/body5>/s;
        const matchFifthBody = textResult.match(regexBody5);
        if (matchFifthBody) {
          setBody5(matchFifthBody[1]);
        }

        // Set Header 6 Conditionally if it exists, it might not
        const regexHeader6 = /<header6>(.*?)<\/header6>/s;
        const matchSixthHeader = textResult.match(regexHeader6);
        if (matchSixthHeader) {
          setHeader6(matchSixthHeader[1]);
        }

        // Set Body 6 Conditionally if it exists, it might not
        const regexBody6 = /<body6>(.*?)<\/body6>/s;
        const matchSixthBody = textResult.match(regexBody6);
        if (matchSixthBody) {
          setBody6(matchSixthBody[1]);
        }

        // Set Header 7 Conditionally if it exists, it might not
        const regexHeader7 = /<header7>(.*?)<\/header7>/s;
        const matchSeventhHeader = textResult.match(regexHeader7);
        if (matchSeventhHeader) {
          setHeader7(matchSeventhHeader[1]);
        }

        // Set Body 7 Conditionally if it exists, it might not
        const regexBody7 = /<body7>(.*?)<\/body7>/s;
        const matchSeventhBody = textResult.match(regexBody7);
        if (matchSeventhBody) {
          setBody7(matchSeventhBody[1]);
        }

        // Set Header 8 Conditionally if it exists, it might not
        const regexHeader8 = /<header8>(.*?)<\/header8>/s;
        const matchEighthHeader = textResult.match(regexHeader8);
        if (matchEighthHeader) {
          setHeader8(matchEighthHeader[1]);
        }

        // Set Body 8 Conditionally if it exists, it might not
        const regexBody8 = /<body8>(.*?)<\/body8>/s;
        const matchEighthBody = textResult.match(regexBody8);
        if (matchEighthBody) {
          setBody8(matchEighthBody[1]);
        }

        setDocumentHasChanged(true);

        // wait 100 ms before setting the documentHasChanged state to false
        setTimeout(() => {
          setDocumentHasChanged(false);
        }, 100);

        const dataToSave = {
          subject,
          gradeLevel,
          overviewText,
          application: 'Educational Handout',
          generatedText: result.choices[0].text,
        };

        // Save the completion to the database, then set the completion state, then log the ref, then catch any errors
        saveCompletionToDB('completions', dataToSave)
          .then(() => {})
          .catch((err) => console.log('error', err));
      })
      .catch((error) => console.log('error', error));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!overviewText) {
      console.log('Please enter a subject');
      return;
    }

    setCovers('');
    setHeader1('');
    setBody1('');
    setHeader2('');
    setBody2('');
    setHeader3('');
    setBody3('');
    setHeader4('');
    setBody4('');
    setHeader5('');
    setBody5('');
    setHeader6('');
    setBody6('');
    setHeader7('');
    setBody7('');
    setHeader8('');
    setBody8('');

    fetchApi(subject, gradeLevel, overviewText);
  };

  // Download PDF Functions

  const createPDF = async (dayNumber) => {
    // Create a blob from the PDF component
    const blob = await pdf(
      <PDF
        subject={subject}
        gradeLevel={gradeLevel}
        title={title}
        covers={covers}
        header1={header1}
        body1={body1}
        header2={header2}
        body2={body2}
        header3={header3}
        body3={body3}
        header4={header4}
        body4={body4}
        header5={header5}
        body5={body5}
        header6={header6}
        body6={body6}
        header7={header7}
        body7={body7}
        header8={header8}
        body8={body8}
      />
    ).toBlob();

    toast.dismiss('educationalhandoutunit');

    // Get the current user and the storage service and the user id
    const auth = getAuth();
    const user = auth.currentUser;
    const storage = getStorage();
    const userId = user.uid;

    // Creates a reference to the file we want to upload and where we want to upload it
    const storageRef = ref(storage, `users/${userId}/lesson-plans/${subject} Educational Handout${Date.now()}.pdf`);

    // Uploads the file to the storage reference
    await uploadBytes(storageRef, blob).then(() => {
      console.log('PDF saved to Firebase');
    });

    // Gets the download URL for the file
    const fileUrl = await getDownloadURL(storageRef);
    setFileUrl(fileUrl);

    async function updateDay(dayNumber) {
      const docRef = doc(db, 'units', unitID);
      const docSnap = await getDoc(docRef);
      const day = docSnap.get(dayNumber);
      const updatedDay = { ...day, educationalhandout: `${fileUrl}` };
      await updateDoc(docRef, { [dayNumber]: updatedDay });
    }

    await updateDay(dayNumber);
    setIsLoading(false);
    setFileIsReady(true);
  };

  const handleDownload = async () => {
    const unitRef = doc(db, 'units', unitID);
    const docSnap = await getDoc(unitRef);
    const url = docSnap.data()[dayNumber].educationalhandout;

    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${dayNumber} Educational Handout.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  // Anytime documentHasChanged changes, it will update the state

  useEffect(() => {
    if (documentHasChanged) {
      createPDF(dayNumber);
    }
  }, [documentHasChanged]);

  async function getUnitDetails() {
    const unitRef = doc(db, 'units', unitID);
    const docSnap = await getDoc(unitRef);

    if (await docSnap.data()[dayNumber].educationalhandout) {
      setButtonJSX(
        <button
          className="btn-primary"
          type="submit"
          onClick={handleDownload}
          style={{
            border: '1px dashed #1e90ff',
            marginBottom: '1.5rem',
            backgroundColor: '#1e90ff',
            color: 'white',
          }}
        >
          Educational Handout
        </button>
      );
    } else {
      setButtonJSX(
        <button
          className="btn-primary"
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          {isLoading ? (
            <div className="buttonelements">
              <div className="buttonicon"></div>
              <div className="buttontext">Loading...</div>
            </div>
          ) : (
            <div className="buttonelements">
              <div className="buttonicon">+</div>
              <div className="buttontext">Educational Handout</div>
            </div>
          )}
        </button>
      );
    }
  }

  useEffect(() => {
    getUnitDetails();
  }, [isLoading]);

  const [buttonJSX, setButtonJSX] = useState(null);

  useEffect(() => {
    if (fileUrl) {
      setButtonJSX(
        <button
          className="btn-primary"
          type="submit"
          onClick={handleDownload}
          style={{
            border: '1px dashed #1e90ff',
            marginBottom: '1.5rem',
            backgroundColor: '#1e90ff',
            color: 'white',
          }}
        >
          Educational Handout
        </button>
      );
    }
  }, [fileIsReady]);

  return <div>{buttonJSX}</div>;
};

export default EducationalHandoutAPI;
