import { Link as LinkRoute } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 550px;
  margin: auto;

  img {
    width: 100%;
  }

  @media (max-width: 799px) {
    max-width: 400px;
  }

  @media (max-width: 435px) {
    padding: 0 18px;
    justify-content: flex-start;
    margin-top: 120px;

    img {
      padding: 0 18px;
    }
  }

  @media (max-width: 375px) {
    margin-top: 60px;
  }
`;

export const Link = styled(LinkRoute)`
  &.link {
    max-width: 360px;
    width: 100%;
    padding: 19px 0;
    background: var(--primary-violet-500);
    border-radius: 12px;
    color: var(--white);
    margin-top: 24px;
    font-size: 16px;
    font-family: DMSansMedium, sans-serif;
  }
`;
