import { getAuth } from '@firebase/auth';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import Day from '../../../components/UnitDay';

import Wrapper from '../../../assets/wrappers/WorkshopTools';
import { Logo } from '../../../components';
import { db } from '../../../firebase.config';
import '../../dashboard/dashboard-pages/creationPages/Creations.css';
import PDF from './AssessmentPDF';

const QuizPreview = () => {
  // States
  const [quizName, setQuizName] = useState('');
  const [subject, setSubject] = useState('');
  const [gradeLevel, setGradeLevel] = useState('');
  const [quizCovers, setQuizCovers] = useState('');
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [keys, setKeys] = useState([]);
  const [quizDetails] = useState({
    unitName: '',
    unitLength: '',
    time: '',
    gradeLevel: '',
  });
  const [dayCards, setDayCards] = useState([]);

  // Destructure quizDetails
  const { quizID } = useParams();

  // Get the quiz details from the database
  const auth = getAuth();
  const user = auth.currentUser;

  const getQuizName = async () => {
    const docRef = doc(db, 'quizzes', quizID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      setQuizName(docSnap.data().quizName ? docSnap.data().quizName : '');
      setSubject(docSnap.data().subject ? docSnap.data().subject : '');
      setQuizCovers(docSnap.data().quizCovers ? docSnap.data().quizCovers : '');
      setGradeLevel(docSnap.data().gradeLevel ? docSnap.data().gradeLevel : '');
      setQuestions(docSnap.data().questions ? docSnap.data().questions : []);
      setAnswers(docSnap.data().answers ? docSnap.data().answers : []);
      setKeys(docSnap.data().keys ? docSnap.data().keys : []);
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
    }
  };

  // React Hooks & Misc Functions
  useEffect(() => {
    getQuizName();
  }, []);

  // Function to render a Day component for each number in the quiz length
  function renderDays() {
    const days = [];
    for (let i = 1; i <= quizDetails.quizLength; i++) {
      days.push(<Day unitName={quizName} unitDetails={quizDetails} dayNumber={i} key={`Day ${i}`} day={`Day ${i}`} />);
    }
    return days;
  }

  useEffect(() => {
    setDayCards(renderDays());
  }, [quizDetails]);

  const createPDF = async () => {
    // Create a blob from the PDF component

    const pdfAnswers = answers.map((answer) => answer.replace(/\\n/g, '\n'));

    const blob = await pdf(
      <PDF
        subject={subject}
        quizName={quizName}
        gradeLevel={gradeLevel}
        quizCovers={quizCovers}
        questions={questions}
        answers={pdfAnswers}
        keys={keys}
      />
    ).toBlob();

    await saveAs(blob, `${quizName}.pdf`);
  };

  const handlePDFDownload = async () => {
    await createPDF();
  };

  return (
    <>
      <Helmet>
        <title>Quiz Preview - Your Quiz Name</title>
        <meta
          name="description"
          content="Preview your quiz before publishing it on our platform. Make sure everything is perfect!"
        />
        <meta property="og:image" content={<Logo />} />
      </Helmet>
      <div className="preview-page-header">
        <div>
          <Logo
            style={{
              height: 'auto',
              width: '250px',
            }}
          />
        </div>
        <div className="preview-header-button-container">
          {user ? (
            <button className="btn btn-block preview-header-button" onClick={() => window.open('/', '_blank')}>
              Dashboard
            </button>
          ) : (
            <button className="btn btn-block preview-header-button" onClick={() => window.open('/sign-in', '_blank')}>
              Sign In / Sign Up
            </button>
          )}
        </div>
      </div>
      <div className="public-quiz-preview">
        <div className="creations">
          <div
            style={{
              marginBottom: '1.3rem',
              fontFamily: 'inter',
              fontWeight: '500',
              fontSize: '1.7rem',
              transform: 'translateY(-.5rem)',
              color: '#000000',
              paddingTop: '.5rem',
            }}
          >
            Hello,{' '}
            <span
              style={{
                fontFamily: 'inter',
                fontWeight: '700',
                paddingBottom: '1.3rem',
              }}
            >
              someone
            </span>{' '}
            made a quiz using AI and they want to share it with you! 🎉
          </div>
          <div
            style={{
              fontFamily: 'inter',
              paddingBottom: '1.1rem',
              backgroundColor: '#FFF',
              padding: '1.5rem',
              borderRadius: '5px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              border: '1px solid #E5E5E5',
            }}
          >
            <div style={{ paddingBottom: '1rem', fontSize: '1.4rem' }}>
              Here is the prompt they used to generate this quiz 🚀
            </div>
            <div style={{ paddingBottom: '1rem' }}>
              <strong>Subject:</strong> {subject}
            </div>
            <div style={{ paddingBottom: '1rem' }}>
              <strong>Grade Level:</strong> {gradeLevel}
            </div>
            <div>
              <strong>Quiz Covers:</strong> {quizCovers}
            </div>
          </div>
          <Wrapper>
            <div className="jobs">{dayCards}</div>
          </Wrapper>
        </div>
        <div className="assessment-container" id="page-one">
          <div className="right-container">
            <div className="questions-column">
              <div
                className="quiz-header-section"
                style={{
                  background: '#7d5ff5',
                  fontFamily: 'inter',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                  padding: '1rem',
                  margin: '2rem 2rem 0rem 2rem',
                }}
              >
                <div className="quiz-header-text">Questions & Answers</div>
                <div className="quiz-controls">
                  <div>
                    {user ? (
                      <button className="quiz-export-button" onClick={() => handlePDFDownload()}>
                        Export to PDF
                      </button>
                    ) : (
                      <button className="quiz-export-button" onClick={() => window.open('/', '_blank')}>
                        Sign Up to Export
                      </button>
                    )}
                    {/* <button className="quiz-share-button">Edit</button> */}
                  </div>
                </div>
              </div>
              <div>
                {questions.map((question, index) => (
                  <div key={index} className="quiz-container">
                    <div className="quiz-content">
                      <div>
                        <div className="question-number">
                          <strong>Question {index + 1}</strong>
                        </div>
                      </div>
                      <div
                        style={{
                          paddingBottom: '1rem',
                        }}
                      >
                        {question}
                      </div>
                      {answers[index] &&
                        answers[index]
                          .split(/(\n|\\n)/)
                          .filter((item) => item !== '\n' && item !== '\\n')
                          .map((answer, index) => (
                            <div key={index} className="answer" style={{ paddingLeft: '1rem' }}>
                              {answer}
                            </div>
                          ))}
                      <div className="answer-key">
                        <div
                          style={{
                            paddingTop: '1rem',
                          }}
                        >
                          <strong>Answer {keys[index]}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizPreview;
