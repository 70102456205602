import { Button as ButtonMui } from '@mui/material';
import styled from 'styled-components';

export const SButton = styled(ButtonMui)`
  width: fit-content;

  &.MuiButtonBase-root {
    display: flex;
    padding: 13px ${({ $isIcon }) => ($isIcon ? 12 : 20)}px 13px 20px;
    align-items: center;
    border-radius: 32px;
    font-size: 14px;
    font-family: DMSansMedium, sans-serif;
    text-transform: none;
    line-height: 1;
    min-height: 40px;
    border: 2px solid transparent;

    svg {
      margin-left: 8px;
      font-size: 16px;
    }

    &.fullWidth {
      border-radius: 12px;
      font-size: 16px;
      padding: 16px 24px;
      height: 56px;

      svg {
        margin: 0;
      }
    }

    &.primary {
      color: var(--white);
      background: var(--primary-violet-500);

      &:hover {
        background: var(--primary-violet-600);
      }

      &:focus {
        border: 2px solid var(--primary-violet-200);
        background: var(--primary-violet-500);
      }

      &:disabled {
        background: var(--primary-violet-100);
      }
    }

    &.secondary {
      color: var(--primary-violet-500);
      background: var(--primary-violet-10);
      font-family: DMSansRegular, sans-serif;

      &:focus,
      &:hover {
        border: 2px solid var(--primary-violet-500);
      }
    }

    &.outline {
      border: 1px solid var(--grey-200);
      color: var(--grey-900);
      background: var(--white);

      &.fullWidth {
        border: 1px solid var(--grey-300);
        color: var(--grey-800);
      }

      svg {
        fill: var(--grey-400);
      }

      &:hover {
        background: var(--grey-50);

        svg {
          fill: var(--grey-900);
        }
      }

      &:focus {
        border: 1px solid var(--primary-violet-500);
      }

      &:disabled {
        background: var(--grey-50);
        border: 1px solid var(--grey-50);
        color: var(--grey-300);
      }
    }

    &.isLoading {
      color: transparent;
    }
  }
`;
