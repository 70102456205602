import { ImDownload } from 'react-icons/im';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfViewer = (props) => {
  const { fileUrl, numPages, onDocumentLoadSuccess } = props;
  return (
    <Document
      file={fileUrl}
      onLoadSuccess={onDocumentLoadSuccess}
      className="renderedPDF"
      noData={
        <div className="preview">
          <div className="preview-text">Your lesson plan will appear here, once it is available for download.</div>
          <div className="preview-icon">{<ImDownload className="icon" />}</div>
        </div>
      }
    >
      <Page scale={0.8} pageNumber={1} />
      <br />
      {numPages > 1 && <Page scale={0.8} pageNumber={2} />}
      <br />
      {numPages > 2 && <Page scale={0.8} pageNumber={3} />}
      <br />
      {numPages > 3 && <Page scale={0.8} pageNumber={4} />}
    </Document>
  );
};
